$step-font-size: $font-size-7 !default;
$steps-gap: 0.4375em !default;
$steps-size: 0.5em !default;

.steps {
	display: flex;
	gap: $steps-gap;
	flex-wrap: nowrap;
	font-size: $step-font-size;

	.step {
		height: $steps-size;
		aspect-ratio: 1 / 1;
		border-radius: $border-radius-rounded;
		background-color: $secondary;

		&.step-selected {
			aspect-ratio: 2 / 1;
			background-color: $primary;
		}
	}
}

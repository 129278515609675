%line-clamp {
	/*stylelint-disable*/
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

@mixin line-clamp($lines: 1, $important: false) {
	@extend %line-clamp;
	@if $important {
		-webkit-line-clamp: $lines !important;
	} @else {
		-webkit-line-clamp: $lines;
	}
}

$gap-size: 1.5rem;

html {
	font-size: $document-font-size;
}

#header-body {
	padding-block: $layout-2;
}

#main {
	display: grid;
	grid-area: main;
	padding-block: $layout-3;
	padding: $layout-gap;
	background-color: $body-background;
	gap: $layout-3;
	grid-template: auto 1fr / 9fr 1fr;
	grid-template-areas:
		'breadcrumbs actions'
		'article article';

	@include desktop {
		grid-template: auto 1fr / 7fr 3fr;
		grid-template-areas:
			'breadcrumbs actions'
			'article article';
	}
}

#breadcrumbs-holder {
	display: flex;
	align-items: center;
}

#actions-holder {
	display: flex;
	justify-content: flex-end;
}

.h1-inverse-spacer {
	margin-block-end: -2rem;
}

#article {
	grid-area: article;
}

.example {
	padding: $layout-2;

	&.full-width {
		padding-inline: 0;
	}

	[data-is-full-screened='true'] &.full-width {
		margin-inline: -1.5rem;
	}
}

// For debugging layout purposes only

@mixin debug($content, $right-align: false) {
	position: relative;
	&::after {
		inset-block-start: 0;
		@if $right-align {
			inset-inline-end: 0;
		} @else {
			inset-inline-start: 0;
		}
		padding: 4px 8px;
		border-radius: 1px;
		position: absolute;

		background-color: $success;
		color: $success-invert;
		font-family: monospace;
		font-size: 18px;
		content: $content;
	}
}

html.debug {
	.layout-body {
		@include debug('.layout-body', true);
	}

	.layout-body-main {
		@include debug('.layout-body-main');
	}

	.layout-body-menu {
		@include debug('.layout-body-menu');
	}

	.layout-body-aside {
		@include debug('.layout-body-aside');
	}

	.layout-body-header {
		@include debug('.layout-body-header');
	}

	.layout-body-footer {
		@include debug('.layout-body-footer');
	}

	.layout-body-hero {
		@include debug('.layout-body-hero');
	}

	.layout-body-flyout {
		@include debug('.layout-body-flyout');
	}
}

.header-spacer {
	width: 2px;
	height: 100%;
	border-left: 2px solid $text-subtle;
	display: inline-block;
	height: 1.5rem;
	margin-left: 0.5rem;
	margin-right: 1rem;
}

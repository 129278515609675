$banner-background: $info-background !default;
$banner-padding: $spacer-5 !default;

$banner-font-size: $font-size-7 !default;
$banner-color: $info-dark !default;

$banner-border-color: $border-white-high-contrast !default;
$banner-border-width: $border-width !default;

$banner-dismiss-margin: $spacer-2 !default;

.banner {
	@extend %layout-gap;

	display: grid;
	position: relative;
	grid-template: auto / 1fr auto;
	grid-template-areas: 'banner-content dismiss';
	outline-color: $text;
	background-color: $banner-background;
	color: $banner-color;
	font-size: $banner-font-size;
	line-height: $line-height-normal;
	padding-block: $banner-padding;
	word-wrap: break-word;
	word-break: break-word;
	border-block: $banner-border-width solid $banner-border-color;

	&.is-loading {
		color: transparent;

		> :first-child {
			margin-inline-start: calc($banner-padding + 0.375em);
		}

		&::before {
			@include loader;

			position: absolute;
			inset-block-start: $banner-padding;
			inset-inline-start: $layout-gap;
			border-color: transparent transparent $banner-color $banner-color;

			@include widescreen {
				inset-inline-start: $layout-widescreen-gap;
			}
		}
	}

	.banner-content {
		grid-area: banner-content;

		a:not(.button) {
			color: currentColor;
			font-weight: $weight-semibold;
			text-decoration: underline;

			.theme-high-contrast & {
				color: $hyperlink;
			}

			&:hover {
				text-decoration-thickness: 0.15em;
			}
		}
	}

	.banner-dismiss {
		@include dismiss-square;

		grid-area: dismiss;
		margin-inline-start: $banner-dismiss-margin;
	}
}

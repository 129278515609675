.border {
	border: $border-width solid $border !important;
}

.border-md {
	border: $border-width-md solid $border !important;
}

.border-lg {
	border: $border-width-lg solid $border !important;
}

.border-none {
	border: none !important;
}

// Rules for each side

@each $border-key, $direction in $logical-directions {
	.border-#{$border-key} {
		border-#{$direction}: $border-width solid $border !important;
	}

	.border-#{$border-key}-md {
		border-#{$direction}: $border-width-md solid $border !important;
	}

	.border-#{$border-key}-lg {
		border-#{$direction}: $border-width-lg solid $border !important;
	}

	.border-#{$border-key}-none {
		border-#{$direction}: 0 !important;
	}
}

@include tablet {
	.border-tablet {
		border: $border-width solid $border !important;
	}

	.border-md-tablet {
		border: $border-width-md solid $border !important;
	}

	.border-lg-tablet {
		border: $border-width-lg solid $border !important;
	}

	.border-none-tablet {
		border: none !important;
	}

	// Rules for each side

	@each $border-key, $direction in $logical-directions {
		.border-#{$border-key}-tablet {
			border-#{$direction}: $border-width solid $border !important;
		}

		.border-#{$border-key}-md-tablet {
			border-#{$direction}: $border-width-md solid $border !important;
		}

		.border-#{$border-key}-lg-tablet {
			border-#{$direction}: $border-width-lg solid $border !important;
		}

		.border-#{$border-key}-none-tablet {
			border-#{$direction}: 0 !important;
		}
	}
}

// Radius

.border-radius-sm {
	border-radius: $border-radius-sm !important;
}

.border-radius {
	border-radius: $border-radius !important;
}

.border-radius-lg {
	border-radius: $border-radius-lg !important;
}

.border-radius-rounded {
	border-radius: $border-radius-rounded !important;
}

.border-radius-none {
	border-radius: 0 !important;
}

// Colors

@each $name, $color-set in $colors {
	$base: nth($color-set, $color-index-base);

	.border-color-#{$name} {
		border-color: $base !important;
	}
}

.border-color-accent {
	border-color: $border-accent !important;
}

.border-high-contrast {
	border: $border-width solid $border-white-high-contrast !important;

	&-hover:hover {
		border: $border-width solid $border-yellow-high-contrast !important;
	}
}

/* stylelint-disable selector-max-compound-selectors */

$markdown-paragraph-size: 1rem !default;
$include-bare-code-styles: true !default;
$list-top-spacing: 1rem !default;

@if $include-bare-code-styles == true {
	/*
	* Within projects that desire bare-code styling, you may set the above variable to true and <pre><code>
	* Elements within .markdown will be styled.
	*/

	.markdown {
		@include code-block;
	}
}

.markdown {
	// @include responsive-font-size($markdown-paragraph-size, 0.875rem); // experiment only
	font-size: $markdown-paragraph-size;
	line-height: 1.6;

	// Inline code

	:not(a):not(pre) > code {
		padding: 0.1em 0.2em;
		border-radius: 3px;
		background-color: $inline-code;
		word-wrap: break-word;
		font-smooth: auto;
		font-size: 0.85em;
	}

	img,
	video {
		max-width: 100%;
		height: auto;
		border: 0;
	}

	svg:not(:root) {
		overflow: hidden;
	}

	li {
		margin-block-start: 0.5em;
	}

	ul,
	ol {
		margin-inline-start: 2.375rem;
	}

	ul {
		list-style-type: disc;

		ul {
			list-style-type: circle;
			margin: 0;
			margin-inline-start: 1.25rem;

			ul {
				list-style-type: square;
			}
		}

		&:not(:first-child) {
			margin: 1rem 0;
		}
	}

	ol {
		list-style-type: decimal;

		ol {
			list-style-type: lower-alpha;
			margin: 0;
			margin-inline-start: 1.25rem;

			ol {
				list-style-type: lower-roman;
			}
		}

		&:not(:first-child) {
			margin: 1rem 0;
		}
	}

	h1 {
		@include responsive-font-size($font-size-1);

		margin-block-start: none;
		margin-block-end: 0.75rem;
		word-wrap: break-word; // browser support fallback, because FF and IE
		word-break: break-word;

		&:first-of-type {
			margin-block-start: -0.625rem;
			margin-block-end: none;
		}
	}

	h2 {
		@include responsive-font-size($font-size-2);

		margin-block-start: 2rem;
	}

	h3 {
		@include responsive-font-size($font-size-3);

		margin-block-start: 1.875rem;
		margin-block-end: 1.125rem;
	}

	h4 {
		@include responsive-font-size($font-size-4);

		margin-block-start: 2.25rem;
		margin-block-end: 0.375rem;
	}

	h5 {
		@include responsive-font-size($font-size-5, false, 1.1rem);

		margin-block-start: 2.25rem;
		margin-block-end: 0.375rem;
		letter-spacing: 1px;
	}

	h6 {
		@include responsive-font-size($font-size-6, false, 1.05rem);

		margin-block-start: 2.25rem;
		margin-block-end: 0.375rem;
		letter-spacing: 1px;
	}

	p {
		margin-block-start: $markdown-paragraph-size;
		margin-block-end: none;
		word-wrap: break-word;
	}

	blockquote {
		margin: 1.2em 0 2em;
		padding: 0 0.8em;
		border-width: 0 0 0 3px;
		border-style: solid;
		border-color: $border;

		footer {
			color: $text-subtle;
			font-size: $font-size-8;
		}
	}
}

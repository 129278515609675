.site-header .site-header-button, .pagination-previous, .pagination-next, .pagination-link, .pagination-ellipsis, .select select, .textarea, .input, .button {
  appearance: none;
  box-shadow: none;
  vertical-align: top;
  border: 1px solid #0000;
  border-radius: .25rem;
  justify-content: flex-start;
  align-items: center;
  min-height: 2.25em;
  padding-block: calc(.375em - 1px);
  padding-inline: calc(.625em - 1px);
  font-size: 1rem;
  line-height: 1.5;
  display: inline-flex;
  position: relative;
}

.site-header [disabled].site-header-button, [disabled].pagination-previous, [disabled].pagination-next, [disabled].pagination-link, [disabled].pagination-ellipsis, .select select[disabled], [disabled].textarea, [disabled].input, [disabled].button {
  cursor: not-allowed;
}

.select:not(.select-multiple):after, .accordion.accordion-icon-end:dir(rtl) summary:after, .accordion.accordion-icon-end summary:after, .accordion:not(.accordion-icon-end):dir(rtl) summary:before, .accordion:not(.accordion-icon-end) summary:before {
  transform-origin: center;
  border: 1px solid var(--theme-text);
  pointer-events: none;
  content: " ";
  z-index: 1;
  border-block-start: 0;
  border-inline-end: 0;
  width: .525em;
  height: .525em;
  inset-inline-end: calc(.625em - 1px);
}

.toggle input[type="checkbox"]:focus-visible + label, .radio input:focus-visible, .radio input.is-focused, .select select:focus-visible, .textarea:focus-visible, .input:focus-visible, .checkbox input[type="checkbox"]:focus-visible + .checkbox-check, :where(.is-focused), .has-default-focus :focus-visible, .default-focus :focus-visible {
  outline-color: inherit;
  outline-offset: .25rem;
  outline-width: .125rem;
  outline-style: dashed;
}

.notification.is-loading:before, .button.button-filled.button-danger.is-loading:after, .button.button-filled.button-warning.is-loading:after, .button.button-filled.button-info.is-loading:after, .button.button-filled.button-success.is-loading:after, .button.button-filled.button-tertiary.is-loading:after, .button.button-filled.button-secondary.is-loading:after, .button.button-filled.button-primary.is-loading:after, .button.is-loading[data-set-layout][aria-pressed="true"]:after, .button.button-filled.is-loading:after, .button.is-loading:after, .banner.is-loading:before {
  border: 2px solid var(--theme-border);
  content: "";
  border-block-start-color: #0000;
  border-inline-end-color: #0000;
  border-radius: 290486px;
  width: 1em;
  height: 1em;
  animation: .5s linear infinite spinAround;
  display: block;
  position: relative;
}

.line-clamp-4, .line-clamp-3, .line-clamp-2, .line-clamp-1, .card .card-content-description, .card .card-title, .card .card-supertitle {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.hero, .banner {
  padding-inline: 24px;
}

@media screen and (width >= 1800px) {
  .hero, .banner {
    padding-inline: max(24px, 50% - 876px);
  }
}

.card .card-header .card-header-image img, .card .card-header .card-header-image svg {
  position: absolute;
  inset: 0;
}

.pagination-previous, .pagination-next, .pagination-link, .pagination-ellipsis, .link-button, .button-reset, .notification .notification-dismiss, .banner .banner-dismiss {
  outline-color: inherit;
  appearance: none;
  background-color: #0000;
}

.pagination-previous:not(:hover), .pagination-next:not(:hover), .pagination-link:not(:hover), .pagination-ellipsis:not(:hover), .link-button:not(:hover), .button-reset:not(:hover), .notification .notification-dismiss:not(:hover), .banner .banner-dismiss:not(:hover), .pagination-previous:not(.focus-visible), .pagination-next:not(.focus-visible), .pagination-link:not(.focus-visible), .pagination-ellipsis:not(.focus-visible), .link-button:not(.focus-visible), .button-reset:not(.focus-visible), .notification .notification-dismiss:not(.focus-visible), .banner .banner-dismiss:not(.focus-visible), .pagination-previous:not(:focus-visible), .pagination-next:not(:focus-visible), .pagination-link:not(:focus-visible), .pagination-ellipsis:not(:focus-visible), .link-button:not(:focus-visible), .button-reset:not(:focus-visible), .notification .notification-dismiss:not(:focus-visible), .banner .banner-dismiss:not(:focus-visible) {
  background-color: #0000;
}

.notification .notification-dismiss, .banner .banner-dismiss {
  color: currentColor;
  cursor: pointer;
  font-size: 1rem;
  display: inline-block;
}

.site-header .site-header-button, .pagination-previous, .pagination-next, .pagination-link, .pagination-ellipsis, .button {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

html, body, p, ol, ul, li, dl, dt, dd, blockquote, figure, fieldset, legend, textarea, pre, iframe, hr, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

ul {
  list-style: none;
}

button, input, select {
  margin: 0;
}

html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

img, video {
  max-width: 100%;
  height: auto;
}

iframe {
  border: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
}

:root, .theme-light {
  --theme-text: #161616;
  --theme-text-subtle: #505050;
  --theme-text-invert: #fff;
  --theme-text-glow-high-contrast: #171717;
  --theme-box-shadow-light: #0000001c;
  --theme-box-shadow-medium: #00000021;
  --theme-box-shadow-heavy: #0000002e;
  --theme-box-shadow-extra-heavy: #00000038;
  --theme-overlay: #000000b3;
  --theme-overlay-invert: #fff;
  --theme-body-background: #fff;
  --theme-body-background-accent: #e8e6df;
  --theme-body-background-medium: #f2f2f2;
  --theme-alternate-background: #171717;
  --theme-alternate-background-medium: #2f2f2f;
  --theme-card-background: #fff;
  --theme-border: #e6e6e6;
  --theme-border-accent: #e8e6df;
  --theme-border-white-high-contrast: #fff0;
  --theme-border-yellow-high-contrast: #0000;
  --theme-table-header: #e6e6e6;
  --theme-table-row: #f0f0f0;
  --theme-table-row-header: #f5f5f5;
  --theme-table-border-dark: #d1d1d1;
  --theme-hover-base: #c7c7c7;
  --theme-hover-invert: #f5f5f5;
  --theme-code-header: #e6e6e6;
  --theme-code-block: #f0f0f0;
  --theme-control-border: #d1d1d1;
  --theme-control-border-bottom: #616161;
  --theme-inline-code: #e6e6e6;
  --theme-code-highlight-background: #fef7b2;
  --theme-visited: #624991;
  --theme-score-low-off: #a800004d;
  --theme-score-low: #b62626;
  --theme-score-medium-off: #ffbb004d;
  --theme-score-medium: #ffb900;
  --theme-score-high-off: #489d484d;
  --theme-score-high: #2a8b2a;
  --theme-hyperlink: #0065b3;
  --theme-primary-base: #0f6cbd;
  --theme-primary-background: #ebf3fc;
  --theme-primary-background-hover: #cfe4fa;
  --theme-primary-background-glow-high-contrast: #ebf3fc;
  --theme-primary-dark: #115ea3;
  --theme-primary-dark-hover: #115ea3;
  --theme-primary-hover: #115ea3;
  --theme-primary-active: #0c3b5e;
  --theme-primary-box-shadow: #0065b34d;
  --theme-primary-invert: #fff;
  --theme-secondary-base: #ebebeb;
  --theme-secondary-background: #f5f5f5;
  --theme-secondary-background-hover: #ebebeb;
  --theme-secondary-background-glow-high-contrast: #f5f5f5;
  --theme-secondary-dark: #424242;
  --theme-secondary-dark-hover: #d6d6d6;
  --theme-secondary-hover: #c7c7c7;
  --theme-secondary-active: #b3b3b3;
  --theme-secondary-box-shadow: #0000004d;
  --theme-secondary-invert: #000;
  --theme-tertiary-base: #243a5e;
  --theme-tertiary-background: #061329;
  --theme-tertiary-background-hover: #4a5d7e;
  --theme-tertiary-background-glow-high-contrast: #061329;
  --theme-tertiary-dark: #a4b2c9;
  --theme-tertiary-dark-hover: #70819f;
  --theme-tertiary-hover: #14294c;
  --theme-tertiary-active: #70819f;
  --theme-tertiary-box-shadow: #26173f4d;
  --theme-tertiary-invert: #fff;
  --theme-success-base: #107c10;
  --theme-success-background: #f1faf1;
  --theme-success-background-hover: #9fd89f;
  --theme-success-background-glow-high-contrast: #f1faf1;
  --theme-success-dark: #0e700e;
  --theme-success-dark-hover: #13a10e;
  --theme-success-hover: #0b5a08;
  --theme-success-active: #9fd89f;
  --theme-success-box-shadow: #489d484d;
  --theme-success-invert: #fff;
  --theme-info-base: #8661c5;
  --theme-info-background: #efd9fd;
  --theme-info-background-hover: #d59dff;
  --theme-info-background-glow-high-contrast: #efd9fd;
  --theme-info-dark: #3b2e58;
  --theme-info-dark-hover: #8661c5;
  --theme-info-hover: #8661c5;
  --theme-info-active: #624991;
  --theme-info-box-shadow: #2018434d;
  --theme-info-invert: #fff;
  --theme-warning-base: #f7630c;
  --theme-warning-background: #fff9f5;
  --theme-warning-background-hover: #fdcfb4;
  --theme-warning-background-glow-high-contrast: #fff9f5;
  --theme-warning-dark: #8a3707;
  --theme-warning-dark-hover: #da3b01;
  --theme-warning-hover: #ca5010;
  --theme-warning-active: #c43501;
  --theme-warning-box-shadow: #ff66004d;
  --theme-warning-invert: #000;
  --theme-danger-base: #bc2f32;
  --theme-danger-background: #fdf3f4;
  --theme-danger-background-hover: #eeacb2;
  --theme-danger-background-glow-high-contrast: #fdf3f4;
  --theme-danger-dark: #b10e1c;
  --theme-danger-dark-hover: #751d1f;
  --theme-danger-hover: #b10e1c;
  --theme-danger-active: #bc2f32;
  --theme-danger-box-shadow: #a800004d;
  --theme-danger-invert: #fff;
  --theme-facepile-red: #b10e1c;
  --theme-facepile-teal: #00666d;
  --theme-facepile-blue: #0078d4;
  --theme-gradient-text-purple: #702573;
  --theme-gradient-text-blue: #0f548c;
  --theme-gradient-vivid-start: #b84dc6;
  --theme-gradient-vivid-end: #0078d4;
}

@media not print {
  .theme-dark {
    --theme-text: #e6e6e6;
    --theme-text-subtle: #d2d2d2;
    --theme-text-invert: #2f2f2f;
    --theme-text-glow-high-contrast: #e6e6e6;
    --theme-box-shadow-light: #0000001c;
    --theme-box-shadow-medium: #00000021;
    --theme-box-shadow-heavy: #0000002e;
    --theme-box-shadow-extra-heavy: #00000038;
    --theme-overlay: #000000b3;
    --theme-overlay-invert: #fff;
    --theme-body-background: #171717;
    --theme-body-background-accent: #091f2c;
    --theme-body-background-medium: #2f2f2f;
    --theme-alternate-background: #f2f2f2;
    --theme-alternate-background-medium: #fafafa;
    --theme-border: #404040;
    --theme-border-accent: #505050;
    --theme-border-white-high-contrast: #fff0;
    --theme-border-yellow-high-contrast: #0000;
    --theme-card-background: #091f2c;
    --theme-table-header: #404040;
    --theme-table-row: #2f2f2f;
    --theme-table-row-header: #2f2f2f;
    --theme-table-border-dark: #505050;
    --theme-hover-base: #d2d2d2;
    --theme-hover-invert: #2f2f2f;
    --theme-code-header: #404040;
    --theme-code-block: #2f2f2f;
    --theme-control-border: #d2d2d2;
    --theme-control-border-bottom: #e6e6e6;
    --theme-inline-code: #404040;
    --theme-code-highlight-background: #294903;
    --theme-visited: #ac7ee1;
    --theme-score-low-off: #a80000b3;
    --theme-score-low: #b62626;
    --theme-score-medium-off: #ffbb004d;
    --theme-score-medium: #ffcb3f;
    --theme-score-high-off: #489d48b3;
    --theme-score-high: #489d48;
    --theme-hyperlink: #75b6e7;
    --theme-primary-base: #75b6e7;
    --theme-primary-background: #004173;
    --theme-primary-background-hover: #082338;
    --theme-primary-background-glow-high-contrast: #004173;
    --theme-primary-dark: #9ccbee;
    --theme-primary-dark-hover: #9ccbee;
    --theme-primary-hover: #278cda;
    --theme-primary-active: #2886de;
    --theme-primary-box-shadow: #0065b34d;
    --theme-primary-invert: #000;
    --theme-secondary-base: #757575;
    --theme-secondary-background: #404040;
    --theme-secondary-background-hover: #292929;
    --theme-secondary-background-glow-high-contrast: #404040;
    --theme-secondary-dark: #e6e6e6;
    --theme-secondary-dark-hover: #8e8e8e;
    --theme-secondary-hover: #8e8e8e;
    --theme-secondary-active: #a2a2a2;
    --theme-secondary-box-shadow: #000000b3;
    --theme-secondary-invert: #fff;
    --theme-tertiary-base: #404040;
    --theme-tertiary-background: #171717;
    --theme-tertiary-background-hover: #505050;
    --theme-tertiary-background-glow-high-contrast: #171717;
    --theme-tertiary-dark: #e6e6e6;
    --theme-tertiary-dark-hover: #8e8e8e;
    --theme-tertiary-hover: #505050;
    --theme-tertiary-active: #757575;
    --theme-tertiary-box-shadow: #0065b34d;
    --theme-tertiary-invert: #fff;
    --theme-success-base: #7cbb7b;
    --theme-success-background: #054b16;
    --theme-success-background-hover: #107c10;
    --theme-success-background-glow-high-contrast: #054b16;
    --theme-success-dark: #dff6dd;
    --theme-success-dark-hover: #7cbb7b;
    --theme-success-hover: #489d48;
    --theme-success-active: #7cbb7b;
    --theme-success-box-shadow: #489d48b3;
    --theme-success-invert: #000;
    --theme-info-base: #9970d3;
    --theme-info-background: #3b2e58;
    --theme-info-background-hover: #8661c5;
    --theme-info-background-glow-high-contrast: #3b2e58;
    --theme-info-dark: #efd9fd;
    --theme-info-dark-hover: #d59dff;
    --theme-info-hover: #ac7ee1;
    --theme-info-active: #d59dff;
    --theme-info-box-shadow: #201843b3;
    --theme-info-invert: #000;
    --theme-warning-base: #f7630c;
    --theme-warning-background: #4a1e04;
    --theme-warning-background-hover: #7a2101;
    --theme-warning-background-glow-high-contrast: #7a2101;
    --theme-warning-dark: #fdcfb4;
    --theme-warning-dark-hover: #e9835e;
    --theme-warning-hover: #ff8c00;
    --theme-warning-active: #fdcfb4;
    --theme-warning-box-shadow: #ff6600b3;
    --theme-warning-invert: #000;
    --theme-danger-base: #e5a7a8;
    --theme-danger-background: #630001;
    --theme-danger-background-hover: #b62626;
    --theme-danger-background-glow-high-contrast: #630001;
    --theme-danger-dark: #fde7e9;
    --theme-danger-dark-hover: #d4797a;
    --theme-danger-hover: #c54f4f;
    --theme-danger-active: #d4797a;
    --theme-danger-box-shadow: #a80000b3;
    --theme-danger-invert: #000;
    --theme-facepile-red: #c54f4f;
    --theme-facepile-teal: #50e6ff;
    --theme-facepile-blue: #278cda;
    --theme-gradient-text-purple: #cd9bcf;
    --theme-gradient-text-blue: #9ccbee;
    --theme-gradient-vivid-start: #b84dc6;
    --theme-gradient-vivid-end: #75b6e7;
  }

  .theme-high-contrast {
    --theme-text: #fff;
    --theme-text-subtle: #fff;
    --theme-text-invert: #000;
    --theme-text-glow-high-contrast: #ff0;
    --theme-box-shadow-light: #0000001c;
    --theme-box-shadow-medium: #00000021;
    --theme-box-shadow-heavy: #0000002e;
    --theme-box-shadow-extra-heavy: #00000038;
    --theme-overlay: #404040;
    --theme-overlay-invert: #fff;
    --theme-body-background: #000;
    --theme-body-background-accent: #000;
    --theme-body-background-medium: #000;
    --theme-alternate-background: #fff;
    --theme-alternate-background-medium: #fff;
    --theme-border: #fff;
    --theme-border-accent: #fff;
    --theme-border-white-high-contrast: #fff;
    --theme-border-yellow-high-contrast: #ff0;
    --theme-card-background: #000;
    --theme-table-header: #505050;
    --theme-table-row: #000;
    --theme-table-row-header: #171717;
    --theme-table-border-dark: #fff;
    --theme-hover-base: #ff0;
    --theme-hover-invert: #000;
    --theme-code-header: #000;
    --theme-code-block: #000;
    --theme-control-border: #fff;
    --theme-control-border-bottom: #fff;
    --theme-inline-code: #2f2f2f;
    --theme-code-highlight-background: #0b6413;
    --theme-visited: #3cff00;
    --theme-score-low-off: #a80000;
    --theme-score-low: #fde7e9;
    --theme-score-medium-off: #ffbb00b3;
    --theme-score-medium: #ffe79f;
    --theme-score-high-off: #107c10;
    --theme-score-high: #dff6dd;
    --theme-hyperlink: #ff0;
    --theme-primary-base: #ff0;
    --theme-primary-background: #000;
    --theme-primary-background-hover: #ff3;
    --theme-primary-background-glow-high-contrast: #ff0;
    --theme-primary-dark: #ff0;
    --theme-primary-dark-hover: #000;
    --theme-primary-hover: #ff3;
    --theme-primary-active: #ff3;
    --theme-primary-box-shadow: #fff;
    --theme-primary-invert: #000;
    --theme-secondary-base: #e6e6e6;
    --theme-secondary-background: #000;
    --theme-secondary-background-hover: #ff3;
    --theme-secondary-background-glow-high-contrast: #505050;
    --theme-secondary-dark: #e6e6e6;
    --theme-secondary-dark-hover: #000;
    --theme-secondary-hover: #d2d2d2;
    --theme-secondary-active: #d2d2d2;
    --theme-secondary-box-shadow: #e6e6e6;
    --theme-secondary-invert: #000;
    --theme-tertiary-base: #fff;
    --theme-tertiary-background: #000;
    --theme-tertiary-background-hover: #bdbdbd;
    --theme-tertiary-background-glow-high-contrast: #fff;
    --theme-tertiary-dark: #fff;
    --theme-tertiary-dark-hover: #e6e6e6;
    --theme-tertiary-hover: #e6e6e6;
    --theme-tertiary-active: #e6e6e6;
    --theme-tertiary-box-shadow: #fff;
    --theme-tertiary-invert: #000;
    --theme-success-base: #7cbb7b;
    --theme-success-background: #000;
    --theme-success-background-hover: #acd7aa;
    --theme-success-background-glow-high-contrast: #7cbb7b;
    --theme-success-dark: #acd7aa;
    --theme-success-dark-hover: #7cbb7b;
    --theme-success-hover: #acd7aa;
    --theme-success-active: #acd7aa;
    --theme-success-box-shadow: #fff;
    --theme-success-invert: #000;
    --theme-info-base: #e0b7fe;
    --theme-info-background: #000;
    --theme-info-background-hover: #e0b7fe;
    --theme-info-background-glow-high-contrast: #e0b7fe;
    --theme-info-dark: #efd9fd;
    --theme-info-dark-hover: #d59dff;
    --theme-info-hover: #efd9fd;
    --theme-info-active: #efd9fd;
    --theme-info-box-shadow: #fff;
    --theme-info-invert: #000;
    --theme-warning-base: #faa06b;
    --theme-warning-background: #000;
    --theme-warning-background-hover: #fdf6f3;
    --theme-warning-background-glow-high-contrast: #ffddb3;
    --theme-warning-dark: #faa06b;
    --theme-warning-dark-hover: #fdcfb4;
    --theme-warning-hover: #efc4ad;
    --theme-warning-active: #fff9f5;
    --theme-warning-box-shadow: #fff;
    --theme-warning-invert: #000;
    --theme-danger-base: #e5a7a8;
    --theme-danger-background: #000;
    --theme-danger-background-hover: #e5a7a8;
    --theme-danger-background-glow-high-contrast: #e5a7a8;
    --theme-danger-dark: #fde7e9;
    --theme-danger-dark-hover: #d4797a;
    --theme-danger-hover: #fde7e9;
    --theme-danger-active: #fde7e9;
    --theme-danger-box-shadow: #fff;
    --theme-danger-invert: #000;
    --theme-facepile-red: #d4797a;
    --theme-facepile-teal: #7becff;
    --theme-facepile-blue: #75b6e7;
    --theme-gradient-text-purple: #fff;
    --theme-gradient-text-blue: #fff;
    --theme-gradient-vivid-start: #fff;
    --theme-gradient-vivid-end: #fff;
  }
}

.theme-high-contrast, .theme-dark, .theme-light {
  outline-color: var(--theme-text);
  background-color: var(--theme-body-background);
  color: var(--theme-text);
}

.theme-light {
  --lightningcss-light: initial;
  --lightningcss-dark: ;
  color-scheme: light;
}

.theme-dark, .theme-high-contrast {
  --lightningcss-light: ;
  --lightningcss-dark: initial;
  color-scheme: dark;
}

html, body {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI Variable Text, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  font-weight: 400;
}

kbd, pre, samp {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: auto;
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace !important;
}

code {
  direction: ltr;
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace;
}

a > code {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI Variable Text, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}

@keyframes spinAround {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(359deg);
  }
}

@keyframes boop {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes slide-up-fade-out {
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}

@keyframes fade-out {
  100% {
    opacity: 0;
  }
}

@keyframes slide-background {
  0% {
    background-position: 200% 0;
  }

  100% {
    background-position: -200% 0;
  }
}

.animation-fade {
  animation: .4s ease-in forwards fade-out;
}

@media (prefers-reduced-motion: reduce) {
  .animation-fade {
    animation-duration: 0s;
  }
}

.animation-slide-up {
  animation: .4s ease-in forwards slide-up-fade-out;
}

@media (prefers-reduced-motion: reduce) {
  .animation-slide-up {
    animation-duration: 0s;
  }
}

.has-default-focus :focus-visible.has-inner-focus, .has-default-focus :focus-visible.inner-focus, .default-focus :focus-visible.has-inner-focus, .default-focus :focus-visible.inner-focus, :where(.is-focused).has-inner-focus, :where(.is-focused).inner-focus {
  outline-offset: -.125rem;
  outline-color: currentColor;
}

details, details > * {
  box-sizing: border-box !important;
}

:where(html *), :where(html a), :where(html li) {
  outline-color: inherit;
}

:where(a) {
  color: var(--theme-hyperlink);
  cursor: pointer;
  word-wrap: break-word;
  text-decoration: none;
}

:where(a:hover) {
  color: var(--theme-primary-hover);
  text-decoration: underline;
}

:where(a:visited) {
  color: var(--theme-visited);
}

fieldset {
  border: none;
  min-width: 0;
}

::target-text {
  background-color: var(--theme-code-highlight-background);
}

.accordion summary {
  font-size: .875rem;
}

.accordion:not(.accordion-icon-end) summary:before {
  margin-block-start: .5rem;
}

.accordion.accordion-icon-end summary:after {
  margin-block-start: .375rem;
}

.accordion summary {
  cursor: pointer;
  gap: 1rem;
  padding-block: .75rem;
  line-height: 1.5;
  list-style: none;
  display: flex;
}

.accordion summary::-webkit-details-marker {
  display: none;
}

.accordion summary:before, .accordion summary:after {
  flex-shrink: 0;
}

.accordion summary:hover {
  color: var(--theme-primary-hover);
}

.accordion summary:hover:before, .accordion summary:hover:after {
  border-color: var(--theme-primary-hover);
}

.accordion summary .accordion-header {
  flex-grow: 1;
}

.accordion:not(.accordion-icon-end) summary:before {
  transition: transform .15s;
  transform: rotate(-135deg);
}

.accordion:not(.accordion-icon-end):dir(rtl) summary:before {
  transform: rotate(-225deg);
}

.accordion.accordion-icon-end summary:after {
  transition: transform .15s;
  transform: rotate(-45deg);
}

.accordion.accordion-icon-end:dir(rtl) summary:after {
  transform: rotate(-315deg);
}

.accordion[open]:not(.accordion-icon-end) summary:before {
  transform: rotate(-45deg)translate(.2625em);
}

.accordion[open]:not(.accordion-icon-end):dir(rtl) summary:before {
  transform: rotate(-315deg)translate(-.2625em);
}

.accordion[open].accordion-icon-end summary:after {
  transform: rotate(-45deg)scaleY(-1)scaleX(-1);
}

.accordion[open].accordion-icon-end:dir(rtl) summary:after {
  transform: rotate(-315deg)scaleY(-1)scaleX(-1);
}

.accordion .accordion-content {
  font-size: .875rem;
}

.accordion.accordion-sm summary {
  font-size: .75rem;
}

.accordion.accordion-sm:not(.accordion-icon-end) summary:before {
  margin-block-start: .375rem;
}

.accordion.accordion-sm.accordion-icon-end summary:after {
  margin-block-start: .25rem;
}

.accordion.accordion-lg summary {
  font-size: 1rem;
}

.accordion.accordion-lg:not(.accordion-icon-end) summary:before {
  margin-block-start: .625rem;
}

.accordion.accordion-lg.accordion-icon-end summary:after {
  margin-block-start: .5rem;
}

.accordion.accordion-xl summary {
  font-size: 1.125rem;
}

.accordion.accordion-xl:not(.accordion-icon-end) summary:before {
  margin-block-start: .75rem;
}

.accordion.accordion-xl.accordion-icon-end summary:after {
  margin-block-start: .625rem;
}

.accordion.accordion-xxl summary {
  font-size: 1.25rem;
}

.accordion.accordion-xxl:not(.accordion-icon-end) summary:before {
  margin-block-start: .875rem;
}

.accordion.accordion-xxl.accordion-icon-end summary:after {
  margin-block-start: .75rem;
}

.badge {
  border: 1px solid var(--theme-border-white-high-contrast);
  white-space: nowrap;
  border-color: var(--theme-secondary-dark);
  background-color: var(--theme-secondary-background);
  color: var(--theme-secondary-dark);
  border-radius: 290486px;
  justify-content: center;
  align-items: center;
  gap: .375em;
  padding: .25em .5em;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1.3;
  display: inline-flex;
}

.badge.badge-primary {
  border-color: var(--theme-primary-dark);
  background-color: var(--theme-primary-background);
  color: var(--theme-primary-dark);
}

.badge.badge-tertiary {
  border-color: var(--theme-tertiary-dark);
  background-color: var(--theme-tertiary-background);
  color: var(--theme-tertiary-dark);
}

.badge.badge-success {
  border-color: var(--theme-success-dark);
  background-color: var(--theme-success-background);
  color: var(--theme-success-dark);
}

.badge.badge-info {
  border-color: var(--theme-info-dark);
  background-color: var(--theme-info-background);
  color: var(--theme-info-dark);
}

.badge.badge-warning {
  border-color: var(--theme-warning-dark);
  background-color: var(--theme-warning-background);
  color: var(--theme-warning-dark);
}

.badge.badge-danger {
  border-color: var(--theme-danger-dark);
  background-color: var(--theme-danger-background);
  color: var(--theme-danger-dark);
}

.badge.badge-clear {
  background-color: #0000;
  border-color: #0000;
}

.badge.badge-filled {
  border-color: var(--theme-secondary-base);
  background-color: var(--theme-secondary-base);
  color: var(--theme-secondary-invert);
}

.badge.badge-filled.badge-primary {
  border-color: var(--theme-primary-base);
  background-color: var(--theme-primary-base);
  color: var(--theme-primary-invert);
}

.badge.badge-filled.badge-tertiary {
  border-color: var(--theme-tertiary-base);
  background-color: var(--theme-tertiary-base);
  color: var(--theme-tertiary-invert);
}

.badge.badge-filled.badge-success {
  border-color: var(--theme-success-base);
  background-color: var(--theme-success-base);
  color: var(--theme-success-invert);
}

.badge.badge-filled.badge-info {
  border-color: var(--theme-info-base);
  background-color: var(--theme-info-base);
  color: var(--theme-info-invert);
}

.badge.badge-filled.badge-warning {
  border-color: var(--theme-warning-base);
  background-color: var(--theme-warning-base);
  color: var(--theme-warning-invert);
}

.badge.badge-filled.badge-danger {
  border-color: var(--theme-danger-base);
  background-color: var(--theme-danger-base);
  color: var(--theme-danger-invert);
}

.badge .icon {
  font-size: 1.3em;
}

.badge:has(.icon:only-child) {
  padding: .5em;
}

.badge.badge-sm {
  font-size: .75rem;
}

.badge.badge-lg {
  font-size: 1rem;
}

.badge.badge-xl {
  font-size: 1.125rem;
}

.banner {
  outline-color: var(--theme-text);
  background-color: var(--theme-info-background);
  color: var(--theme-info-dark);
  word-wrap: break-word;
  word-break: break-word;
  border-block: 1px solid var(--theme-border-white-high-contrast);
  grid-template: "banner-content dismiss"
  / 1fr auto;
  padding-block: 1rem;
  font-size: 1rem;
  line-height: 1.3;
  display: grid;
  position: relative;
}

.banner.is-loading {
  color: #0000;
}

.banner.is-loading > :first-child {
  margin-inline-start: calc(1rem + .375em);
}

.banner.is-loading:before {
  border-color: transparent transparent var(--theme-info-dark) var(--theme-info-dark);
  position: absolute;
  inset-block-start: 1rem;
  inset-inline-start: 24px;
}

@media screen and (width >= 1800px) {
  .banner.is-loading:before {
    inset-inline-start: max(24px, 50% - 876px);
  }
}

.banner .banner-content {
  grid-area: banner-content;
}

.banner .banner-content a:not(.button) {
  color: currentColor;
  font-weight: 600;
  text-decoration: underline;
}

.theme-high-contrast .banner .banner-content a:not(.button) {
  color: var(--theme-hyperlink);
}

.banner .banner-content a:not(.button):hover {
  text-decoration-thickness: .15em;
}

.banner .banner-dismiss {
  border: 0;
  border-radius: 290486px;
  grid-area: dismiss;
  width: 1em;
  height: 1em;
  margin-inline-start: .25rem;
  padding: 0;
}

.breadcrumbs.breadcrumbs-initial-slash .breadcrumbs-item:first-child:before, .breadcrumbs.breadcrumbs-initial-slash .breadcrumbs-item[hidden] + .breadcrumbs-item:before, .breadcrumbs .breadcrumbs-item:not(.breadcrumbs-item-slashless):after {
  color: var(--theme-text-subtle);
  content: "​/";
  display: inline-block;
}

.breadcrumbs {
  font-size: .875rem;
}

.breadcrumbs .breadcrumbs-item {
  padding-block: .375em;
  display: inline-block;
}

.breadcrumbs .breadcrumbs-item:not(.breadcrumbs-item-slashless):after {
  margin-inline: .25em;
}

.breadcrumbs.breadcrumbs-initial-slash .breadcrumbs-item:first-child:before, .breadcrumbs.breadcrumbs-initial-slash .breadcrumbs-item[hidden] + .breadcrumbs-item:before {
  margin-inline: .0625em .25em;
}

.button {
  border-width: 1px;
  border-color: var(--theme-control-border);
  background-color: var(--theme-body-background);
  color: var(--theme-text);
  text-align: center;
  cursor: pointer;
  border-radius: .25rem;
  justify-content: center;
  padding-block: calc(.375em - 1px);
  padding-inline: .75em;
  font-weight: 600;
  text-decoration: none;
}

.button strong {
  color: inherit;
}

.button .icon {
  font-size: .875em;
}

.button .icon:only-child {
  margin: 0;
}

.button .icon:first-child:not(:only-child) {
  margin-inline-end: .375em;
}

.button .icon:last-child:not(:only-child) {
  margin-inline-start: .375em;
}

.button:hover, .button.is-hovered {
  border-color: var(--theme-hover-base);
  background-color: var(--theme-hover-invert);
  color: var(--theme-text);
}

.button:visited {
  color: var(--theme-text);
}

.button.button-sm {
  font-size: .875rem;
}

.button.button-lg {
  font-size: 1.125rem;
}

.button.is-disabled, .button[disabled] {
  background-color: var(--theme-body-background-medium);
  opacity: .5;
  box-shadow: none;
  border-color: currentColor;
}

.button.button-block {
  width: 100%;
  display: flex;
}

.button.is-loading {
  pointer-events: none;
  color: #0000 !important;
}

.button.is-loading:after {
  border-block-end-color: var(--theme-text);
  border-inline-start-color: var(--theme-text);
  position: absolute;
  inset-block-start: calc(50% - .5em);
  inset-inline-start: calc(50% - .5em);
  position: absolute !important;
}

.button.button-primary, .button[data-set-layout][aria-pressed="true"] {
  border-color: var(--theme-primary-base);
  color: var(--theme-primary-base);
}

.button.button-primary:hover, .button[data-set-layout][aria-pressed="true"]:hover, .button.button-primary.is-hovered, .button.is-hovered[data-set-layout][aria-pressed="true"] {
  background-color: var(--theme-primary-background);
  color: var(--theme-primary-dark);
}

.button.button-primary.is-loading:after, .button.is-loading[data-set-layout][aria-pressed="true"]:after {
  border-color: transparent transparent var(--theme-primary-base) var(--theme-primary-base) !important;
}

.button.button-secondary {
  border-color: var(--theme-secondary-base);
  color: var(--theme-secondary-base);
}

.button.button-secondary:hover, .button.button-secondary.is-hovered {
  background-color: var(--theme-secondary-background);
  color: var(--theme-secondary-dark);
}

.button.button-secondary.is-loading:after {
  border-color: transparent transparent var(--theme-secondary-base) var(--theme-secondary-base) !important;
}

.button.button-tertiary {
  border-color: var(--theme-tertiary-base);
  color: var(--theme-tertiary-base);
}

.button.button-tertiary:hover, .button.button-tertiary.is-hovered {
  background-color: var(--theme-tertiary-background);
  color: var(--theme-tertiary-dark);
}

.button.button-tertiary.is-loading:after {
  border-color: transparent transparent var(--theme-tertiary-base) var(--theme-tertiary-base) !important;
}

.button.button-success {
  border-color: var(--theme-success-base);
  color: var(--theme-success-base);
}

.button.button-success:hover, .button.button-success.is-hovered {
  background-color: var(--theme-success-background);
  color: var(--theme-success-dark);
}

.button.button-success.is-loading:after {
  border-color: transparent transparent var(--theme-success-base) var(--theme-success-base) !important;
}

.button.button-info {
  border-color: var(--theme-info-base);
  color: var(--theme-info-base);
}

.button.button-info:hover, .button.button-info.is-hovered {
  background-color: var(--theme-info-background);
  color: var(--theme-info-dark);
}

.button.button-info.is-loading:after {
  border-color: transparent transparent var(--theme-info-base) var(--theme-info-base) !important;
}

.button.button-warning {
  border-color: var(--theme-warning-dark);
  color: var(--theme-warning-dark);
}

.button.button-warning:hover, .button.button-warning.is-hovered {
  background-color: var(--theme-warning-background);
  color: var(--theme-warning-dark);
}

.button.button-warning.is-loading:after {
  border-color: transparent transparent var(--theme-warning-base) var(--theme-warning-base) !important;
}

.button.button-danger {
  border-color: var(--theme-danger-base);
  color: var(--theme-danger-base);
}

.button.button-danger:hover, .button.button-danger.is-hovered {
  background-color: var(--theme-danger-background);
  color: var(--theme-danger-dark);
}

.button.button-danger.is-loading:after {
  border-color: transparent transparent var(--theme-danger-base) var(--theme-danger-base) !important;
}

.button.button-clear {
  background-color: #0000;
  border-color: #0000;
}

.button.button-clear.border {
  border-color: currentColor !important;
}

.button.button-clear, .button.button-clear:visited {
  color: currentColor;
}

.button.button-clear.is-hovered, .button.button-clear:hover, .button.button-clear:active {
  background-color: #8e8e8e0d;
}

.button.button-clear.button-primary, .button.button-clear[data-set-layout][aria-pressed="true"] {
  color: var(--theme-primary-base);
}

.button.button-clear.button-primary:hover, .button.button-clear[data-set-layout][aria-pressed="true"]:hover, .button.button-clear.button-primary.is-hovered, .button.button-clear.is-hovered[data-set-layout][aria-pressed="true"] {
  background-color: var(--theme-primary-background);
  color: var(--theme-primary-dark);
}

.button.button-clear.button-primary.is-disabled, .button.button-clear.is-disabled[data-set-layout][aria-pressed="true"], .button.button-clear.button-primary[disabled], .button.button-clear[disabled][data-set-layout][aria-pressed="true"] {
  color: var(--theme-primary-base);
  box-shadow: none;
  background-color: #0000;
}

.button.button-clear.button-secondary {
  color: var(--theme-secondary-base);
}

.button.button-clear.button-secondary:hover, .button.button-clear.button-secondary.is-hovered {
  background-color: var(--theme-secondary-background);
  color: var(--theme-secondary-dark);
}

.button.button-clear.button-secondary.is-disabled, .button.button-clear.button-secondary[disabled] {
  color: var(--theme-secondary-base);
  box-shadow: none;
  background-color: #0000;
}

.button.button-clear.button-tertiary {
  color: var(--theme-tertiary-base);
}

.button.button-clear.button-tertiary:hover, .button.button-clear.button-tertiary.is-hovered {
  background-color: var(--theme-tertiary-background);
  color: var(--theme-tertiary-dark);
}

.button.button-clear.button-tertiary.is-disabled, .button.button-clear.button-tertiary[disabled] {
  color: var(--theme-tertiary-base);
  box-shadow: none;
  background-color: #0000;
}

.button.button-clear.button-success {
  color: var(--theme-success-base);
}

.button.button-clear.button-success:hover, .button.button-clear.button-success.is-hovered {
  background-color: var(--theme-success-background);
  color: var(--theme-success-dark);
}

.button.button-clear.button-success.is-disabled, .button.button-clear.button-success[disabled] {
  color: var(--theme-success-base);
  box-shadow: none;
  background-color: #0000;
}

.button.button-clear.button-info {
  color: var(--theme-info-base);
}

.button.button-clear.button-info:hover, .button.button-clear.button-info.is-hovered {
  background-color: var(--theme-info-background);
  color: var(--theme-info-dark);
}

.button.button-clear.button-info.is-disabled, .button.button-clear.button-info[disabled] {
  color: var(--theme-info-base);
  box-shadow: none;
  background-color: #0000;
}

.button.button-clear.button-warning {
  color: var(--theme-warning-dark);
}

.button.button-clear.button-warning:hover, .button.button-clear.button-warning.is-hovered {
  background-color: var(--theme-warning-background);
  color: var(--theme-warning-dark);
}

.button.button-clear.button-warning.is-disabled, .button.button-clear.button-warning[disabled] {
  color: var(--theme-warning-base);
  box-shadow: none;
  background-color: #0000;
}

.button.button-clear.button-danger {
  color: var(--theme-danger-base);
}

.button.button-clear.button-danger:hover, .button.button-clear.button-danger.is-hovered {
  background-color: var(--theme-danger-background);
  color: var(--theme-danger-dark);
}

.button.button-clear.button-danger.is-disabled, .button.button-clear.button-danger[disabled] {
  color: var(--theme-danger-base);
  box-shadow: none;
  background-color: #0000;
}

.button.button-filled, .button[data-set-layout][aria-pressed="true"] {
  border-color: var(--theme-alternate-background-medium);
  background-color: var(--theme-alternate-background-medium);
  color: var(--theme-text-invert);
}

.button.button-filled:hover, .button[data-set-layout][aria-pressed="true"]:hover, .button.button-filled.is-hovered, .button.is-hovered[data-set-layout][aria-pressed="true"] {
  background-color: var(--theme-alternate-background);
}

.button.button-filled.is-loading:after, .button.is-loading[data-set-layout][aria-pressed="true"]:after {
  border-color: transparent transparent var(--theme-text-invert) var(--theme-text-invert) !important;
}

.button.button-filled.button-primary, .button.button-primary[data-set-layout][aria-pressed="true"], .button.button-filled[data-set-layout][aria-pressed="true"], .button[data-set-layout][aria-pressed="true"] {
  border-color: var(--theme-primary-base);
  background-color: var(--theme-primary-base);
  color: var(--theme-primary-invert);
}

.button.button-filled.button-primary:hover, .button.button-primary[data-set-layout][aria-pressed="true"]:hover, .button[data-set-layout][aria-pressed="true"]:hover, .button.button-filled.button-primary.is-hovered, .button.button-primary.is-hovered[data-set-layout][aria-pressed="true"], .button.is-hovered[data-set-layout][aria-pressed="true"] {
  border-color: var(--theme-primary-hover);
  background-color: var(--theme-primary-hover);
  color: var(--theme-primary-invert);
}

.button.button-filled.button-primary.is-loading:after, .button.is-loading[data-set-layout][aria-pressed="true"]:after {
  border-color: transparent transparent var(--theme-primary-invert) var(--theme-primary-invert) !important;
}

.button.button-filled.button-secondary, .button.button-secondary[data-set-layout][aria-pressed="true"] {
  border-color: var(--theme-secondary-base);
  background-color: var(--theme-secondary-base);
  color: var(--theme-secondary-invert);
}

.button.button-filled.button-secondary:hover, .button.button-secondary[data-set-layout][aria-pressed="true"]:hover, .button.button-filled.button-secondary.is-hovered, .button.button-secondary.is-hovered[data-set-layout][aria-pressed="true"] {
  border-color: var(--theme-secondary-hover);
  background-color: var(--theme-secondary-hover);
  color: var(--theme-secondary-invert);
}

.button.button-filled.button-secondary.is-loading:after, .button.button-secondary.is-loading[data-set-layout][aria-pressed="true"]:after {
  border-color: transparent transparent var(--theme-secondary-invert) var(--theme-secondary-invert) !important;
}

.button.button-filled.button-tertiary, .button.button-tertiary[data-set-layout][aria-pressed="true"] {
  border-color: var(--theme-tertiary-base);
  background-color: var(--theme-tertiary-base);
  color: var(--theme-tertiary-invert);
}

.button.button-filled.button-tertiary:hover, .button.button-tertiary[data-set-layout][aria-pressed="true"]:hover, .button.button-filled.button-tertiary.is-hovered, .button.button-tertiary.is-hovered[data-set-layout][aria-pressed="true"] {
  border-color: var(--theme-tertiary-hover);
  background-color: var(--theme-tertiary-hover);
  color: var(--theme-tertiary-invert);
}

.button.button-filled.button-tertiary.is-loading:after, .button.button-tertiary.is-loading[data-set-layout][aria-pressed="true"]:after {
  border-color: transparent transparent var(--theme-tertiary-invert) var(--theme-tertiary-invert) !important;
}

.button.button-filled.button-success, .button.button-success[data-set-layout][aria-pressed="true"] {
  border-color: var(--theme-success-base);
  background-color: var(--theme-success-base);
  color: var(--theme-success-invert);
}

.button.button-filled.button-success:hover, .button.button-success[data-set-layout][aria-pressed="true"]:hover, .button.button-filled.button-success.is-hovered, .button.button-success.is-hovered[data-set-layout][aria-pressed="true"] {
  border-color: var(--theme-success-hover);
  background-color: var(--theme-success-hover);
  color: var(--theme-success-invert);
}

.button.button-filled.button-success.is-loading:after, .button.button-success.is-loading[data-set-layout][aria-pressed="true"]:after {
  border-color: transparent transparent var(--theme-success-invert) var(--theme-success-invert) !important;
}

.button.button-filled.button-info, .button.button-info[data-set-layout][aria-pressed="true"] {
  border-color: var(--theme-info-base);
  background-color: var(--theme-info-base);
  color: var(--theme-info-invert);
}

.button.button-filled.button-info:hover, .button.button-info[data-set-layout][aria-pressed="true"]:hover, .button.button-filled.button-info.is-hovered, .button.button-info.is-hovered[data-set-layout][aria-pressed="true"] {
  border-color: var(--theme-info-hover);
  background-color: var(--theme-info-hover);
  color: var(--theme-info-invert);
}

.button.button-filled.button-info.is-loading:after, .button.button-info.is-loading[data-set-layout][aria-pressed="true"]:after {
  border-color: transparent transparent var(--theme-info-invert) var(--theme-info-invert) !important;
}

.button.button-filled.button-warning, .button.button-warning[data-set-layout][aria-pressed="true"] {
  border-color: var(--theme-warning-base);
  background-color: var(--theme-warning-base);
  color: var(--theme-warning-invert);
}

.button.button-filled.button-warning:hover, .button.button-warning[data-set-layout][aria-pressed="true"]:hover, .button.button-filled.button-warning.is-hovered, .button.button-warning.is-hovered[data-set-layout][aria-pressed="true"] {
  border-color: var(--theme-warning-hover);
  background-color: var(--theme-warning-hover);
  color: var(--theme-warning-invert);
}

.button.button-filled.button-warning.is-loading:after, .button.button-warning.is-loading[data-set-layout][aria-pressed="true"]:after {
  border-color: transparent transparent var(--theme-warning-invert) var(--theme-warning-invert) !important;
}

.button.button-filled.button-danger, .button.button-danger[data-set-layout][aria-pressed="true"] {
  border-color: var(--theme-danger-base);
  background-color: var(--theme-danger-base);
  color: var(--theme-danger-invert);
}

.button.button-filled.button-danger:hover, .button.button-danger[data-set-layout][aria-pressed="true"]:hover, .button.button-filled.button-danger.is-hovered, .button.button-danger.is-hovered[data-set-layout][aria-pressed="true"] {
  border-color: var(--theme-danger-hover);
  background-color: var(--theme-danger-hover);
  color: var(--theme-danger-invert);
}

.button.button-filled.button-danger.is-loading:after, .button.button-danger.is-loading[data-set-layout][aria-pressed="true"]:after {
  border-color: transparent transparent var(--theme-danger-invert) var(--theme-danger-invert) !important;
}

.buttons {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.buttons.buttons-centered {
  justify-content: center;
}

.buttons.buttons-right {
  justify-content: flex-end;
}

.buttons .button {
  margin-block-end: .5rem;
  margin-inline-end: .5rem;
}

.buttons .button:only-child, .buttons .button.is-fullwidth, .buttons .button.is-full-width, .buttons .button.is-full-width-mobile, .buttons .button.is-fullwidth-mobile {
  margin-inline-end: 0;
}

@media screen and (width >= 768px), print {
  .buttons .button.is-full-width-mobile, .buttons .button.is-fullwidth-mobile {
    margin-inline-end: .5rem;
  }
}

.buttons:last-child {
  margin-block-end: -.5rem;
}

.buttons:not(:last-child) {
  margin-block-end: 1rem;
}

.buttons.buttons-addons .button:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.buttons.buttons-addons .button:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-inline-end: -1px;
}

.buttons.buttons-addons .button:last-child {
  margin-inline-end: 0;
}

.buttons.buttons-addons .button:hover, .buttons.buttons-addons .button.is-hovered {
  z-index: 2;
}

.buttons.buttons-addons .button.is-focused, .buttons.buttons-addons .button:active, .buttons.buttons-addons .button.is-active, .buttons.buttons-addons .button.is-selected {
  z-index: 3;
}

.buttons.buttons-addons .button.is-focused:hover, .buttons.buttons-addons .button:active:hover, .buttons.buttons-addons .button.is-active:hover, .buttons.buttons-addons .button.is-selected:hover {
  z-index: 4;
}

.buttons.buttons-addons .button:focus-visible {
  z-index: 3;
}

.buttons.buttons-addons .button:focus-visible:hover {
  z-index: 4;
}

.buttons.buttons-addons .button.is-expanded {
  flex-grow: 1;
}

.card {
  border: 1px solid var(--theme-border-white-high-contrast);
  outline-color: var(--theme-text);
  background-color: var(--theme-card-background);
  color: var(--theme-text);
  box-shadow: 0 1.6px 3.6px 0 var(--theme-box-shadow-medium), 0 .3px .9px 0 var(--theme-box-shadow-light);
  border-radius: .125rem;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 100%;
  padding: 1rem 0 0;
  display: flex;
  position: relative;
}

.card > :first-child {
  border-start-start-radius: .125rem;
  border-start-end-radius: .125rem;
}

.card > :last-child {
  border-end-end-radius: .125rem;
  border-end-start-radius: .125rem;
}

.card .card-template {
  flex-grow: 1;
  grid-template: "supertitle supertitle"
                 "title title"
                 "detail icon"
                 / 1fr 3rem;
  gap: .5rem 1rem;
  padding: 1rem;
  display: grid;
}

.card .card-template .card-title {
  margin-block-end: 1rem;
}

.card .card-template-icon {
  grid-area: icon;
  width: 3rem;
  height: 3rem;
  margin-block-start: auto;
}

.card .card-template-detail {
  flex-direction: column;
  grid-area: detail;
  justify-content: flex-end;
  align-self: stretch;
  font-size: .75rem;
  line-height: 1.25;
  display: flex;
}

.card .card-header {
  margin-block-end: 1.125rem;
  position: relative;
}

.card .card-header .card-header-image {
  width: 64px;
  height: 64px;
  position: relative;
}

.card .card-header .card-header-image img, .card .card-header .card-header-image svg {
  width: 100%;
  height: 100%;
  display: block;
}

.card .card-header, .card .card-content, .card .card-template {
  padding-inline: 1rem;
}

.card .card-header:first-child, .card .card-content:first-child, .card .card-template:first-child {
  margin-block-start: -1rem;
  padding-block-start: 1rem;
}

.card .card-supertitle {
  -webkit-line-clamp: 1;
  letter-spacing: .225rem;
  text-transform: uppercase;
  grid-area: supertitle;
  font-size: .75rem;
  line-height: 1;
}

.card .card-title {
  -webkit-line-clamp: 3;
  color: var(--theme-primary-base);
  text-align: start;
  appearance: none;
  border: none;
  flex-grow: 1;
  grid-area: title;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.25;
  text-decoration: none;
}

.card .card-title:first-child {
  margin-block-start: none;
}

.card .card-title:only-child {
  margin: 0;
}

.card .card-title:visited {
  color: var(--theme-visited);
}

.card a.card-title:hover, .card button.card-title:hover {
  cursor: pointer;
  text-decoration: underline;
}

.card .card-content {
  flex-grow: 1;
  margin-block-end: 1.125rem;
}

.card .card-content > :not(:first-child) {
  margin-block-start: .5rem;
}

.card .card-content-description {
  -webkit-line-clamp: 4;
  max-height: inherit;
  padding-block-end: 0;
  font-size: .875rem;
  line-height: 1.25;
}

.card .card-footer {
  border-block-start: 1px solid var(--theme-border);
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-inline: 1rem;
  display: flex;
}

.card .card-footer.card-footer-left .card-footer-item, .card .card-footer.card-footer-right .card-footer-item {
  flex-grow: 0;
}

.card .card-footer.card-footer-left {
  justify-content: flex-start;
}

.card .card-footer.card-footer-right {
  justify-content: flex-end;
}

.card .card-footer .card-footer-item {
  flex-grow: 1;
  flex-shrink: 1;
  align-items: center;
  padding-block: .375rem;
  display: flex;
}

.card .card-footer .card-footer-item:first-child, .card .card-footer .card-footer-item:only-child {
  justify-content: flex-start;
}

.card .card-footer .card-footer-item:last-child {
  justify-content: flex-end;
}

.card .card-footer .card-footer-item:not(:last-child):not(:first-child):not(:only-child) {
  justify-content: center;
}

.card .card-footer .card-footer-item .buttons {
  margin-block: 0;
}

.card .card-footer .card-footer-item .button {
  margin-block-end: 0;
}

.card .card-footer .card-footer-item .button:last-child {
  margin-inline-end: 0;
}

@media screen and (width >= 768px), print {
  .card.card-horizontal {
    flex-flow: wrap;
  }

  .card.card-horizontal .card-content {
    max-width: calc(100% - 7rem);
    padding-inline: 0 4rem;
  }

  .card.card-horizontal .card-footer {
    flex-grow: 1;
    flex-shrink: 1;
    width: 100%;
  }
}

.button-reset {
  cursor: pointer;
  border: 0;
  padding: 0;
  display: inline-block;
}

.label {
  color: var(--theme-text);
  font-size: 1rem;
  font-weight: 600;
  display: block;
}

.help {
  color: var(--theme-text);
  font-size: .875rem;
  display: block;
}

.help.help-danger {
  color: var(--theme-danger-base);
}

.help.help-success {
  color: var(--theme-success-base);
}

.checkbox {
  cursor: pointer;
  align-items: center;
  line-height: 1.25;
  display: flex;
  position: relative;
}

.checkbox:not(:last-child) {
  margin-block-end: .5rem;
}

.checkbox .checkbox-check {
  border: .0625em solid var(--theme-control-border);
  border-radius: .25rem;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 1.25em;
  height: 1.25em;
  transition-property: background, border, border-color;
  transition-duration: .2s;
  transition-timing-function: cubic-bezier(.4, 0, .23, 1);
  display: flex;
  position: relative;
}

.checkbox .checkbox-check:not(:last-child) {
  margin-inline-end: .5rem;
}

.checkbox .checkbox-check:before {
  content: " ";
}

.checkbox.checkbox-sm {
  font-size: .875rem;
}

.checkbox.checkbox-lg {
  font-size: 1.125rem;
}

.checkbox input[type="checkbox"] {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  word-wrap: normal;
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.checkbox input[type="checkbox"]:checked + .checkbox-check {
  border-color: var(--theme-primary-base);
  background-color: var(--theme-primary-base);
}

.checkbox input[type="checkbox"]:checked + .checkbox-check:before {
  border: solid var(--theme-text-subtle);
  content: "";
  border-width: 0 .125em .125em 0;
  border-color: var(--theme-primary-invert);
  width: .3125em;
  height: .75em;
  display: block;
  position: absolute;
  inset-block-start: .125em;
  transform: rotate(45deg);
}

.checkbox input[type="checkbox"][disabled] + .checkbox-check {
  border-color: var(--theme-control-border);
  opacity: .6;
}

.checkbox input[type="checkbox"][disabled] + .checkbox-check, .checkbox input[type="checkbox"][disabled] ~ .checkbox-text {
  cursor: not-allowed;
}

.checkbox.checkbox-muted input[type="checkbox"]:checked + .checkbox-check {
  border-color: var(--theme-text-subtle);
  background-color: var(--theme-text-subtle);
}

.checkbox.checkbox-muted input[type="checkbox"]:checked + .checkbox-check:before {
  border-color: var(--theme-text-invert);
}

.checkbox .checkbox-check.is-checked {
  border-color: var(--theme-primary-base);
  background-color: var(--theme-primary-base);
}

.checkbox .checkbox-check.is-checked:before {
  border: solid var(--theme-text-subtle);
  content: "";
  border-width: 0 .125em .125em 0;
  border-color: var(--theme-primary-invert);
  width: .3125em;
  height: .75em;
  display: block;
  position: absolute;
  inset-block-start: .125em;
  transform: rotate(45deg);
}

.checkbox.is-invalid input[type="checkbox"]:not(:checked) + .checkbox-check {
  border-color: var(--theme-danger-base);
}

.checkbox:hover input[type="checkbox"]:not(:checked):not([disabled]) + .checkbox-check:before {
  border: solid var(--theme-text-subtle);
  content: "";
  border-width: 0 .125em .125em 0;
  width: .3125em;
  height: .75em;
  display: block;
  position: absolute;
  inset-block-start: .125em;
  transform: rotate(45deg);
}

.input {
  border-color: var(--theme-control-border);
  border-block-end-color: var(--theme-control-border-bottom);
  background-color: var(--theme-body-background);
  width: 100%;
  max-width: 100%;
  color: var(--theme-text) !important;
}

.input::placeholder {
  color: var(--theme-text-subtle);
}

.input:hover {
  border-color: var(--theme-hover-base);
  border-block-end-color: var(--theme-control-border-bottom);
}

.input[disabled] {
  border-color: var(--theme-table-border-dark);
  background-color: var(--theme-body-background-medium);
  color: var(--theme-text-subtle);
}

.input:focus-visible {
  border-block-end-color: var(--theme-primary-base);
  outline-offset: 0;
  box-shadow: 0 .0625rem 0 0 var(--theme-primary-base);
  outline-style: solid;
  outline-color: #0000;
}

.input.input-sm {
  font-size: .875rem;
}

.input.input-lg {
  font-size: 1.125rem;
}

.input.input-danger {
  border-color: var(--theme-danger-base);
}

.input.input-danger:focus-visible {
  border-color: var(--theme-control-border);
  border-block-end-color: var(--theme-danger-base);
  box-shadow: 0 .0625rem 0 0 var(--theme-danger-base);
}

.input.input-success {
  border-color: var(--theme-success-base);
}

.input.input-success:focus-visible {
  border-color: var(--theme-control-border);
  border-block-end-color: var(--theme-success-base);
  box-shadow: 0 .0625rem 0 0 var(--theme-success-base);
}

.input.input-icon-right {
  padding-inline-end: 2.25em;
}

.input.input-icon-right ~ .icon {
  inset-inline-end: 0;
  inset-inline-start: unset !important;
}

.input.input-icon-right .input[type="date"] {
  position: relative;
}

.input.input-icon-right .input[type="date"]::-webkit-calendar-picker-indicator {
  color: #0000;
  z-index: 1000;
  background: none;
  width: 1.5em;
  position: absolute;
  inset-inline-end: 0;
}

.input.input-icon, .input.input-icon-right {
  padding-inline-start: 2.25em;
}

.input.input-icon.input-icon-right, .input.input-icon-right.input-icon-right {
  padding-inline-start: calc(.625em - 1px);
}

.input.input-icon + .icon, .input.input-icon-right + .icon {
  forced-color-adjust: auto;
  color: var(--theme-text-subtle);
  pointer-events: none;
  z-index: 4;
  width: 2.25em;
  height: 100%;
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  fill: currentColor !important;
}

.input.input-icon.input-sm ~ .icon svg, .input.input-icon-right.input-sm ~ .icon svg {
  width: .875em;
  height: .875em;
}

.input.input-icon.input-lg ~ .icon svg, .input.input-icon-right.input-lg ~ .icon svg {
  width: 1.125em;
  height: 1.125em;
}

.textarea {
  resize: vertical;
  border-color: var(--theme-control-border);
  border-block-end-color: var(--theme-control-border-bottom);
  background-color: var(--theme-body-background);
  width: 100%;
  max-width: 100%;
  transition: box-shadow .3s linear;
  display: block;
  color: var(--theme-text) !important;
}

.textarea[rows] {
  height: initial;
}

.textarea:not([rows]) {
  min-height: 100px;
  max-height: 600px;
}

.textarea::placeholder {
  color: var(--theme-text-subtle);
}

.textarea:hover {
  border-color: var(--theme-hover-base);
  border-block-end-color: var(--theme-control-border-bottom);
}

.textarea[disabled] {
  border-color: var(--theme-table-border-dark);
  background-color: var(--theme-body-background-medium);
  color: var(--theme-text-subtle);
}

.textarea:focus-visible {
  border-block-end-color: var(--theme-primary-base);
  outline-offset: 0;
  box-shadow: 0 .125rem 0 0 var(--theme-primary-base);
  outline-style: solid;
  outline-color: #0000;
}

.textarea.textarea-fixed-height {
  resize: none;
}

.textarea.textarea-danger {
  border-color: var(--theme-danger-base);
}

.textarea.textarea-danger:focus-visible {
  border-color: var(--theme-control-border);
  border-block-end-color: var(--theme-danger-base);
  box-shadow: 0 .125rem 0 0 var(--theme-danger-base);
}

.textarea.textarea-success {
  border-color: var(--theme-success-base);
}

.textarea.textarea-success:focus-visible {
  border-color: var(--theme-control-border);
  border-block-end-color: var(--theme-success-base);
  box-shadow: 0 .125rem 0 0 var(--theme-success-base);
}

.textarea-form {
  border-style: solid;
  border-width: 1px;
  border-color: var(--theme-control-border);
  background-color: var(--theme-body-background);
  border-block-end-color: var(--theme-control-border-bottom);
  border-radius: .25rem;
  gap: .25em;
  padding-block: calc(.375em - 1px);
  padding-inline: calc(.625em - 1px);
  font-size: 1rem;
  transition: box-shadow .3s linear;
  display: grid;
}

@media (prefers-reduced-motion: reduce) {
  .textarea-form {
    animation-duration: 0s;
  }
}

.textarea-form:has(.textarea:focus-visible), .textarea-form:has(.textarea:hover) {
  border-block-end-color: var(--theme-primary-base);
  outline-offset: 0;
  box-shadow: 0 .125rem 0 0 var(--theme-primary-base);
  outline-style: solid;
  outline-color: #0000;
}

.textarea-form .textarea {
  resize: none;
  box-shadow: none;
  border: none;
}

.textarea-form .textarea-form-footer {
  outline-color: currentColor;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.textarea-form .textarea-form-footer-divider {
  border: 1px solid var(--theme-control-border);
  border-radius: .5rem;
  height: 70%;
}

.textarea-form .textarea-form-footer-left, .textarea-form .textarea-form-footer-right {
  align-items: center;
  gap: .25em;
  height: 100%;
  display: flex;
}

.select {
  font-size: 1rem;
  position: relative;
}

.select select {
  border-color: var(--theme-control-border);
  border-block-end-color: var(--theme-control-border-bottom);
  background-color: var(--theme-body-background);
  color: var(--theme-text);
  cursor: pointer;
  width: 100%;
  max-width: 100%;
  font-size: 1em;
}

.select select[multiple] {
  padding: 0;
  overflow-y: auto;
}

.select select[multiple] option {
  padding: calc(.375em - 1px) calc(.625em - 1px);
}

.select select:hover {
  border-color: var(--theme-hover-base);
  border-block-end-color: var(--theme-control-border-bottom);
}

.select select[disabled] {
  border-color: var(--theme-table-border-dark);
  background-color: var(--theme-body-background-medium);
  color: var(--theme-text-subtle);
}

.select select:focus-visible {
  border-block-end-color: var(--theme-primary-base);
  outline-offset: 0;
  box-shadow: 0 .0625rem 0 0 var(--theme-primary-base);
  outline-style: solid;
  outline-color: #0000;
}

.select:not(.select-multiple) select {
  padding-inline-end: calc(1.375em - 1px);
}

.select:not(.select-multiple):after {
  display: block;
  position: absolute;
  inset-block-start: calc(50% - .375em);
  transform: rotate(-45deg);
}

.select:not(.select-multiple).select-disabled:after {
  border-color: var(--theme-table-border-dark);
}

.select.select-sm {
  font-size: .875rem;
}

.select.select-lg {
  font-size: 1.125rem;
}

.select.select-danger select {
  border-color: var(--theme-danger-base);
}

.select.select-danger select:focus-visible {
  border-color: var(--theme-control-border);
  border-block-end-color: var(--theme-danger-base);
  box-shadow: 0 .0625rem 0 0 var(--theme-danger-base);
}

.select.select-success select {
  border-color: var(--theme-success-base);
}

.select.select-success select:focus-visible {
  border-color: var(--theme-control-border);
  border-block-end-color: var(--theme-success-base);
  box-shadow: 0 .0625rem 0 0 var(--theme-success-base);
}

.radio {
  cursor: pointer;
  line-height: 1.25;
  display: inline-flex;
}

.radio .radio-dot {
  border: 1px solid var(--theme-control-border);
  background-color: var(--theme-body-background);
  color: var(--theme-text-subtle);
  cursor: pointer;
  appearance: none;
  border-radius: 290486px;
  flex-shrink: 0;
  width: 1.25em;
  height: 1.25em;
  display: inline-block;
  position: relative;
  inset-block-start: .0625em;
}

@media (forced-colors: active) {
  .radio .radio-dot {
    background-color: unset !important;
    border-color: canvastext !important;
  }

  .radio .radio-dot:before {
    content: "";
    border-radius: 290486px;
    width: .625em;
    height: .625em;
    margin: auto;
    position: absolute;
    inset: 0;
    overflow: hidden;
  }
}

.radio .radio-label-text {
  margin: 0 .5em;
}

.radio input.is-checked, .radio input:checked, .radio .radio-dot.is-checked {
  background: var(--theme-primary-base);
  box-shadow: inset 0 0 0 .15em var(--theme-body-background);
  border-color: var(--theme-primary-base);
  animation: .2s cubic-bezier(.4, 0, .23, 1) boop;
}

@media (forced-colors: active) {
  .radio input.is-checked, .radio input:checked, .radio .radio-dot.is-checked {
    background-color: unset !important;
    box-shadow: none !important;
    border-color: canvastext !important;
  }

  .radio input.is-checked:before, .radio input:checked:before, .radio .radio-dot.is-checked:before {
    background-color: canvastext !important;
  }
}

.radio.is-hovered .radio-dot:not(:checked):not([disabled]), .radio:hover .radio-dot:not(:checked):not([disabled]) {
  background: var(--theme-control-border);
  box-shadow: inset 0 0 0 .15em var(--theme-body-background);
}

@media (forced-colors: active) {
  .radio.is-hovered .radio-dot:not(:checked):not([disabled]), .radio:hover .radio-dot:not(:checked):not([disabled]) {
    background-color: unset !important;
    box-shadow: none !important;
  }

  .radio.is-hovered .radio-dot:not(:checked):not([disabled]):before, .radio:hover .radio-dot:not(:checked):not([disabled]):before {
    background-color: canvastext !important;
  }
}

.radio.is-hovered .radio-dot.is-checked, .radio:hover .radio-dot.is-checked {
  background: var(--theme-primary-base);
  box-shadow: inset 0 0 0 .15em var(--theme-body-background);
}

.radio input[disabled], .radio input[disabled] ~ .radio-label-text {
  opacity: .5;
  cursor: not-allowed;
}

.field:not(:last-child) {
  margin-block-end: .75rem;
}

.field .field-description {
  margin-block-end: .5rem;
  font-size: .875rem;
}

.field .field-label {
  color: var(--theme-text);
  margin-block-end: .5rem;
  font-size: 1rem;
  font-weight: 600;
  display: block;
}

.field .field-label.field-label-inline {
  align-self: flex-end;
  margin-block-end: unset;
  display: inline-flex;
}

@media screen and (width >= 768px), print {
  .field .field-label {
    flex: 1 0 0;
  }
}

.field .field-label.field-label-sm {
  font-size: .875rem;
}

.field .field-label.field-label-lg {
  font-size: 1.125rem;
}

.field .field-body > * {
  scroll-margin-top: 3rem;
}

.field .field-body:not(:first-child) {
  margin-block-start: .5rem;
}

.field .field-body:not(:first-child).field-body-inline {
  vertical-align: text-bottom;
  display: inline-flex;
}

.field .field-error {
  color: var(--theme-danger-base);
  font-size: .875rem;
  line-height: 1.5;
}

.required-indicator:after, ::part(required-indicator):after {
  color: var(--theme-danger-base);
  content: "*";
  vertical-align: top;
}

.hero {
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  display: flex;
  position: relative;
}

.hero .hero-content {
  z-index: 1;
  padding-block: 3rem;
}

@media screen and (width >= 1088px) {
  .hero .hero-content {
    width: 45%;
    min-height: 400px;
    padding-block: 4rem;
    padding-inline-end: 3rem;
  }
}

.theme-high-contrast .hero .hero-details-card {
  background: unset !important;
}

.hero .hero-details {
  padding-block: 2rem;
  display: flex;
}

@media screen and (width >= 768px), print {
  .hero .hero-details {
    justify-content: flex-end;
    padding-block: 3rem;
    padding-inline-start: 2rem;
  }
}

@media screen and (width >= 1088px) {
  .hero .hero-details {
    min-height: 400px;
    padding-block: 4rem;
    padding-inline: 3rem;
  }
}

.hero .hero-details .hero-details-card {
  backdrop-filter: blur(20px);
  background-color: var(--theme-body-background);
  align-self: flex-start;
}

@media screen and (width >= 1088px) {
  .hero .hero-details .hero-details-card {
    background: linear-gradient(116deg, var(--theme-body-background) 23.94%, var(--theme-border-white-high-contrast) 200%);
    max-width: 500px;
    margin-inline-start: 2rem;
  }

  .hero:not(.hero-image) .hero-content {
    width: 100%;
    max-width: 50rem;
  }

  .hero.hero-xs .hero-content {
    min-height: 200px;
    padding-block: 2rem;
  }

  .hero.hero-sm .hero-content {
    min-height: 300px;
    padding-block: 3rem;
  }

  .hero.hero-lg .hero-content {
    min-height: 500px;
    padding-block: 6rem;
  }
}

.hero .hero-card {
  z-index: 1;
  background-color: #0000;
  border: none;
  padding: 1rem;
  position: relative;
}

@media screen and (width >= 768px), print {
  .hero .hero-card {
    padding: 3rem;
  }
}

@media screen and (width >= 1088px) {
  .hero .hero-card {
    border-inline: 1px solid var(--theme-border);
    border-block-end: 1px solid var(--theme-border);
    border-end-end-radius: .375rem;
    border-end-start-radius: .375rem;
  }
}

.hero .hero-background {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.hero .hero-background video, .hero .hero-background img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.hero.hero-image:before {
  background-image: var(--hero-background-image);
  content: " ";
  background-repeat: no-repeat;
  background-size: cover;
  width: calc(55% - 26.4px);
  height: 100%;
  display: none;
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 24px;
}

@media screen and (width >= 1088px) {
  .hero.hero-image:before {
    display: block;
  }
}

@media screen and (width >= 1800px) {
  .hero.hero-image:before {
    width: calc(55% + -1.1 * max(24px, 50% - 876px));
    inset-inline-end: max(24px, 50% - 876px);
  }
}

@media (forced-colors: active) {
  .hero.hero-image:before {
    background-image: none !important;
  }
}

.theme-light .hero.hero-image:before {
  --hero-background-image: var(--hero-background-image-light);
}

.theme-dark .hero.hero-image:before {
  --hero-background-image: var(--hero-background-image-dark);
}

.theme-high-contrast .hero.hero-image:before {
  --hero-background-image: none;
}

[dir="rtl"] .hero.hero-image, .hero.hero-image:dir(rtl) {
  direction: ltr;
}

[dir="rtl"] .hero.hero-image .hero-content, [dir="rtl"] .hero.hero-image .hero-details-card, .hero.hero-image:dir(rtl) .hero-content, .hero.hero-image:dir(rtl) .hero-details-card {
  direction: rtl;
}

[dir="rtl"] .hero.hero-image[class^="gradient-border-"], .hero.hero-image:dir(rtl)[class^="gradient-border-"] {
  direction: ltr;
}

.gradient-border-body {
  --border-gradient-end-color: var(--theme-body-background);
}

.gradient-border-body-accent {
  --border-gradient-end-color: var(--theme-body-background-accent);
}

.gradient-border-right {
  position: relative;
}

.gradient-border-right:before {
  content: "";
  border-image: linear-gradient(to right, transparent 0%, transparent 92%, var(--border-gradient-end-color, transparent) 100%);
  border-inline-end-style: solid;
  border-inline-end-width: 320px;
  border-image-slice: 0 128 0 0;
  border-image-outset: 0;
  display: block;
  position: absolute;
  inset-block: 0;
  inset-inline-end: 0;
}

@media (forced-colors: active) {
  .gradient-border-right:before {
    display: none !important;
  }
}

@media screen and (width >= 768px), print {
  .gradient-border-to-right-tablet:before {
    border-image: linear-gradient(to right, transparent 0%, transparent 92%, var(--border-gradient-end-color) 100%);
    border-inline-end-style: solid;
    border-inline-end-width: 320px;
    border-image-slice: 0 128 0 0;
    border-image-outset: 0;
  }
}

.gradient-border-bottom {
  position: relative;
}

.gradient-border-bottom:before {
  content: "";
  border-image: linear-gradient(to bottom, transparent 0%, transparent 92%, var(--border-gradient-end-color, transparent) 100%);
  border-block-end-style: solid;
  border-block-end-width: 320px;
  border-image-slice: 0 0 128;
  border-image-outset: 0;
  display: block;
  position: absolute;
  inset-block-end: 0;
  inset-inline: 0;
}

@media (forced-colors: active) {
  .gradient-border-bottom:before {
    display: none !important;
  }
}

@media screen and (width >= 768px), print {
  .gradient-border-to-bottom-tablet:before {
    border-image: linear-gradient(to bottom, transparent 0%, transparent 92%, var(--border-gradient-end-color) 100%);
    border-block-end-style: solid;
    border-block-end-width: 320px;
    border-image-slice: 0 0 128;
    border-image-outset: 0;
  }
}

.gradient-text-vivid {
  background-clip: text;
  background-color: var(--theme-gradient-vivid-start);
  background-image: linear-gradient(90deg, var(--theme-gradient-vivid-start), var(--theme-gradient-vivid-end));
  color: #0000;
  -webkit-text-fill-color: transparent;
  line-height: 1.4;
}

.gradient-text-purple-blue {
  background-clip: text;
  background-color: var(--theme-gradient-text-purple);
  background-image: linear-gradient(90deg, var(--theme-gradient-text-purple), var(--theme-gradient-text-blue));
  color: #0000;
  -webkit-text-fill-color: transparent;
  line-height: 1.4;
}

.gradient-card {
  background: linear-gradient(90deg, var(--theme-gradient-vivid-start), var(--theme-gradient-vivid-end)) border-box;
  border: .125rem solid #0000;
  border-radius: .375rem;
}

.gradient-card .gradient-card-content {
  border-radius: inherit;
  background-color: var(--theme-body-background);
  padding: 1rem;
}

.link-button {
  color: var(--theme-hyperlink);
  cursor: pointer;
  border: none;
  padding: 0;
  display: inline-block;
}

.link-button:active, .link-button.is-active {
  text-decoration: underline !important;
}

.link-button.is-focused, .link-button:hover, .link-button.is-hovered, .link-button:focus-visible {
  color: var(--theme-primary-hover);
  text-decoration: underline !important;
}

.link-button:visited {
  color: var(--theme-visited);
}

.markdown pre {
  box-sizing: border-box;
  border: 1px solid var(--theme-border);
  background-color: var(--theme-code-block);
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: auto;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  tab-size: 4;
  hyphens: none;
  -webkit-overflow-scrolling: touch;
  max-width: 100%;
  margin: 0;
  padding: 1em;
  font-size: .875rem;
  line-height: 1.3571;
  overflow: auto;
}

.markdown pre > code {
  border: 0;
  padding: 0;
  line-height: 1.3571;
  display: block;
  position: relative;
}

.markdown pre > code mark {
  color: var(--theme-text);
  min-width: calc(100% + 2em);
  min-height: 18.9px;
  margin: 0 -1em;
  padding: 0 1em;
  display: inline-block;
}

.markdown {
  font-size: 1rem;
  line-height: 1.6;
}

.markdown :not(a):not(pre) > code {
  background-color: var(--theme-inline-code);
  word-wrap: break-word;
  font-smooth: auto;
  border-radius: 3px;
  padding: .1em .2em;
  font-size: .85em;
}

.markdown img, .markdown video {
  border: 0;
  max-width: 100%;
  height: auto;
}

.markdown svg:not(:root) {
  overflow: hidden;
}

.markdown li {
  margin-block-start: .5em;
}

.markdown ul, .markdown ol {
  margin-inline-start: 2.375rem;
}

.markdown ul {
  list-style-type: disc;
}

.markdown ul ul {
  margin: 0;
  margin-inline-start: 1.25rem;
  list-style-type: circle;
}

.markdown ul ul ul {
  list-style-type: square;
}

.markdown ul:not(:first-child) {
  margin: 1rem 0;
}

.markdown ol {
  list-style-type: decimal;
}

.markdown ol ol {
  margin: 0;
  margin-inline-start: 1.25rem;
  list-style-type: lower-alpha;
}

.markdown ol ol ol {
  list-style-type: lower-roman;
}

.markdown ol:not(:first-child) {
  margin: 1rem 0;
}

.markdown h1 {
  word-wrap: break-word;
  word-break: break-word;
  margin-block-start: none;
  margin-block-end: .75rem;
  font-size: clamp(1.875rem, 22.1053px + 1.64474vw, 2.5rem);
}

.markdown h1:first-of-type {
  margin-block-start: -.625rem;
  margin-block-end: none;
}

.markdown h2 {
  margin-block-start: 2rem;
  font-size: clamp(1.59375rem, 18.7895px + 1.39803vw, 2.125rem);
}

.markdown h3 {
  margin-block: 1.875rem 1.125rem;
  font-size: clamp(1.3125rem, 15.4737px + 1.15132vw, 1.75rem);
}

.markdown h4 {
  margin-block: 2.25rem .375rem;
  font-size: clamp(1.125rem, 13.2632px + .986842vw, 1.5rem);
}

.markdown h5 {
  letter-spacing: 1px;
  margin-block: 2.25rem .375rem;
  font-size: clamp(1.1rem, 15.7053px + .394737vw, 1.25rem);
}

.markdown h6 {
  letter-spacing: 1px;
  margin-block: 2.25rem .375rem;
  font-size: clamp(1.05rem, 15.8526px + .197368vw, 1.125rem);
}

.markdown p {
  word-wrap: break-word;
  margin-block-start: 1rem;
  margin-block-end: none;
}

.markdown blockquote {
  border-style: solid;
  border-width: 0 0 0 3px;
  border-color: var(--theme-border);
  margin: 1.2em 0 2em;
  padding: 0 .8em;
}

.markdown blockquote footer {
  color: var(--theme-text-subtle);
  font-size: .875rem;
}

.media {
  align-items: flex-start;
  display: flex;
}

.media + .media, .media .media {
  border-block-start: 1px solid var(--theme-border);
  margin-block-start: 1rem;
  padding-block-start: 1rem;
}

.media-left {
  flex: none;
  margin-inline-end: 1rem;
}

.media-content {
  word-break: break-word;
  flex: auto;
  width: 100%;
}

.message {
  word-wrap: break-word;
  grid-template: ". message-time"
                 "message-persona message-content"
                 / max-content minmax(0, 1fr);
  gap: .75rem;
  font-size: 1rem;
  line-height: 1.3;
  display: grid;
}

.message .message-time {
  grid-area: message-time;
  font-size: .75rem;
}

.message .message-persona {
  grid-area: message-persona;
  margin-block-start: 1rem;
}

.message .message-content {
  border: 1px solid var(--theme-border);
  background-color: var(--theme-body-background);
  border-radius: .375rem;
  grid-area: message-content;
  padding: 1rem;
}

.message .message-content .message-content-header {
  grid-template-columns: 1fr auto;
  margin-block-end: .5rem;
  display: grid;
}

.message .message-content .message-content-header .message-content-options {
  justify-self: flex-end;
  margin-inline-end: .5rem;
}

.message.message-sender {
  justify-self: flex-end;
  width: 85%;
}

.message.message-sender .message-content {
  background-color: var(--theme-primary-background);
}

.message.message-sm {
  font-size: .875rem;
}

.message.message-sm .message-content {
  padding-block: .75rem;
  padding-inline: 1rem;
}

.message.message-sm.message-sender .message-content {
  padding-block: .5rem;
}

.notification {
  border: 1px solid var(--theme-control-border);
  outline-color: var(--theme-text);
  background-color: var(--theme-secondary-background);
  color: var(--theme-text);
  word-wrap: break-word;
  word-break: break-word;
  border-radius: .25rem;
  grid-template: "notification-content dismiss"
  / 1fr auto;
  padding: 1rem;
  font-size: 1rem;
  line-height: 1.3;
  display: grid;
  position: relative;
}

.notification.notification-primary {
  border-color: var(--theme-primary-dark);
  background-color: var(--theme-primary-background);
  color: var(--theme-primary-dark);
}

.notification.notification-primary.is-loading:before {
  border-color: transparent transparent var(--theme-primary-dark) var(--theme-primary-dark);
}

.notification.notification-secondary {
  border-color: var(--theme-secondary-dark);
  background-color: var(--theme-secondary-background);
  color: var(--theme-secondary-dark);
}

.notification.notification-secondary.is-loading:before {
  border-color: transparent transparent var(--theme-secondary-dark) var(--theme-secondary-dark);
}

.notification.notification-tertiary {
  border-color: var(--theme-tertiary-dark);
  background-color: var(--theme-tertiary-background);
  color: var(--theme-tertiary-dark);
}

.notification.notification-tertiary.is-loading:before {
  border-color: transparent transparent var(--theme-tertiary-dark) var(--theme-tertiary-dark);
}

.notification.notification-success {
  border-color: var(--theme-success-dark);
  background-color: var(--theme-success-background);
  color: var(--theme-success-dark);
}

.notification.notification-success.is-loading:before {
  border-color: transparent transparent var(--theme-success-dark) var(--theme-success-dark);
}

.notification.notification-info {
  border-color: var(--theme-info-dark);
  background-color: var(--theme-info-background);
  color: var(--theme-info-dark);
}

.notification.notification-info.is-loading:before {
  border-color: transparent transparent var(--theme-info-dark) var(--theme-info-dark);
}

.notification.notification-warning {
  border-color: var(--theme-warning-dark);
  background-color: var(--theme-warning-background);
  color: var(--theme-warning-dark);
}

.notification.notification-warning.is-loading:before {
  border-color: transparent transparent var(--theme-warning-dark) var(--theme-warning-dark);
}

.notification.notification-danger {
  border-color: var(--theme-danger-dark);
  background-color: var(--theme-danger-background);
  color: var(--theme-danger-dark);
}

.notification.notification-danger.is-loading:before {
  border-color: transparent transparent var(--theme-danger-dark) var(--theme-danger-dark);
}

.notification.is-loading {
  color: #0000;
}

.notification.is-loading > :first-child {
  margin-inline-start: calc(1rem + .375em);
}

.notification.is-loading:before {
  border-color: transparent transparent var(--theme-text) var(--theme-text);
  position: absolute;
  inset-block-start: 1rem;
  inset-inline-start: 1rem;
}

.notification .notification-content {
  grid-area: notification-content;
}

.notification .notification-content .notification-title, .notification .notification-content a:not(.button) {
  color: currentColor;
  font-weight: 600;
}

.notification .notification-content a:not(.button) {
  text-decoration: underline;
}

.theme-high-contrast .notification .notification-content a:not(.button) {
  color: var(--theme-hyperlink);
}

.notification .notification-content a:not(.button):hover {
  text-decoration-thickness: .15em;
}

.notification .notification-content .notification-title {
  justify-content: flex-start;
  align-items: center;
  margin-block-end: .5rem;
  display: flex;
}

.notification .notification-content .notification-title .icon {
  flex-shrink: 0;
  align-self: start;
  margin-block-start: .2em;
  margin-inline-end: .375em;
}

.notification .notification-dismiss {
  border: 0;
  border-radius: 290486px;
  grid-area: dismiss;
  width: 1em;
  height: 1em;
  margin-inline-start: .25rem;
  padding: 0;
}

.icon {
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.icon, .icon svg, .icon img {
  width: 1em;
  height: 1em;
}

.icon.icon-rounded {
  border-radius: 50%;
  padding: .6rem;
}

.image {
  display: block;
  position: relative;
}

.image img, .image svg {
  width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
}

.image.image-fullwidth {
  object-fit: cover;
  width: 100%;
  height: auto;
  margin: 0;
}

.image.image-16x16 {
  width: 16px;
  height: 16px;
}

@media screen and (width >= 768px), print {
  .image.image-16x16-tablet {
    width: 16px;
    height: 16px;
  }
}

@media screen and (width >= 1088px) {
  .image.image-16x16-desktop {
    width: 16px;
    height: 16px;
  }
}

.image.image-24x24 {
  width: 24px;
  height: 24px;
}

@media screen and (width >= 768px), print {
  .image.image-24x24-tablet {
    width: 24px;
    height: 24px;
  }
}

@media screen and (width >= 1088px) {
  .image.image-24x24-desktop {
    width: 24px;
    height: 24px;
  }
}

.image.image-32x32 {
  width: 32px;
  height: 32px;
}

@media screen and (width >= 768px), print {
  .image.image-32x32-tablet {
    width: 32px;
    height: 32px;
  }
}

@media screen and (width >= 1088px) {
  .image.image-32x32-desktop {
    width: 32px;
    height: 32px;
  }
}

.image.image-36x36 {
  width: 36px;
  height: 36px;
}

@media screen and (width >= 768px), print {
  .image.image-36x36-tablet {
    width: 36px;
    height: 36px;
  }
}

@media screen and (width >= 1088px) {
  .image.image-36x36-desktop {
    width: 36px;
    height: 36px;
  }
}

.image.image-48x48 {
  width: 48px;
  height: 48px;
}

@media screen and (width >= 768px), print {
  .image.image-48x48-tablet {
    width: 48px;
    height: 48px;
  }
}

@media screen and (width >= 1088px) {
  .image.image-48x48-desktop {
    width: 48px;
    height: 48px;
  }
}

.image.image-64x64 {
  width: 64px;
  height: 64px;
}

@media screen and (width >= 768px), print {
  .image.image-64x64-tablet {
    width: 64px;
    height: 64px;
  }
}

@media screen and (width >= 1088px) {
  .image.image-64x64-desktop {
    width: 64px;
    height: 64px;
  }
}

.image.image-76x76 {
  width: 76px;
  height: 76px;
}

@media screen and (width >= 768px), print {
  .image.image-76x76-tablet {
    width: 76px;
    height: 76px;
  }
}

@media screen and (width >= 1088px) {
  .image.image-76x76-desktop {
    width: 76px;
    height: 76px;
  }
}

.image.image-88x88 {
  width: 88px;
  height: 88px;
}

@media screen and (width >= 768px), print {
  .image.image-88x88-tablet {
    width: 88px;
    height: 88px;
  }
}

@media screen and (width >= 1088px) {
  .image.image-88x88-desktop {
    width: 88px;
    height: 88px;
  }
}

.image.image-96x96 {
  width: 96px;
  height: 96px;
}

@media screen and (width >= 768px), print {
  .image.image-96x96-tablet {
    width: 96px;
    height: 96px;
  }
}

@media screen and (width >= 1088px) {
  .image.image-96x96-desktop {
    width: 96px;
    height: 96px;
  }
}

.image.image-112x112 {
  width: 112px;
  height: 112px;
}

@media screen and (width >= 768px), print {
  .image.image-112x112-tablet {
    width: 112px;
    height: 112px;
  }
}

@media screen and (width >= 1088px) {
  .image.image-112x112-desktop {
    width: 112px;
    height: 112px;
  }
}

.image.image-128x128 {
  width: 128px;
  height: 128px;
}

@media screen and (width >= 768px), print {
  .image.image-128x128-tablet {
    width: 128px;
    height: 128px;
  }
}

@media screen and (width >= 1088px) {
  .image.image-128x128-desktop {
    width: 128px;
    height: 128px;
  }
}

.persona {
  gap: .5rem;
  font-size: .875rem;
  line-height: 1.3;
  display: flex;
  position: relative;
}

.persona .persona-avatar {
  background-color: var(--theme-alternate-background-medium);
  color: var(--theme-text-invert);
  border-radius: 290486px;
  flex-shrink: 0;
  width: 2.6666em;
  height: 2.6666em;
}

.persona .persona-avatar img, .persona .persona-avatar svg {
  aspect-ratio: 1;
  border-radius: 290486px;
  width: 100%;
  height: auto;
}

.persona .persona-details {
  color: var(--theme-text-subtle);
  align-self: center;
}

.persona .persona-details .persona-name {
  color: var(--theme-text);
  font-size: 1.3333em;
  font-weight: 600;
}

.persona.persona-sm {
  font-size: .75rem;
}

.persona.persona-lg {
  font-size: 1rem;
}

.popover {
  display: inline-block;
  position: relative;
}

.popover summary {
  list-style: none;
}

.popover summary::-webkit-details-marker {
  display: none;
}

.popover .popover-content {
  border: 1px solid var(--theme-border);
  background-color: var(--theme-body-background);
  box-shadow: 0 6.4px 14.4px 0 var(--theme-box-shadow-medium), 0 1.2px 3.6px 0 var(--theme-box-shadow-light);
  z-index: 1060;
  border-radius: .25rem;
  width: 224px;
  margin-block-start: .5rem;
  padding: 1rem;
  position: absolute;
}

.popover.popover-right .popover-content {
  inset-inline-end: 0;
}

.popover.popover-center .popover-content {
  inset-inline-start: -25%;
}

.popover.popover-top .popover-content {
  margin-block-end: .5rem;
  inset-block-end: 100%;
}

.pagination {
  flex-wrap: wrap;
  margin: -.25rem;
}

.pagination-list {
  flex-grow: 1;
}

.pagination, .pagination-list {
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: 0;
  display: flex;
}

.pagination > li, .pagination-list > li {
  margin: .25rem;
  list-style: none !important;
}

.pagination-link.is-current {
  border-color: var(--theme-text-invert);
  background-color: var(--theme-primary-base);
  color: var(--theme-text-invert);
}

.pagination-link.is-current:hover {
  background-color: var(--theme-primary-hover);
  color: var(--theme-text-invert);
}

@media (forced-colors: active) {
  .pagination-link:after {
    content: "";
    forced-color-adjust: none;
    background-color: linktext;
    border: 1px solid linktext;
    border-radius: 290486px;
    width: .375em;
    height: .375em;
    display: block;
    position: absolute;
    inset-block-start: .25em;
    inset-inline-end: .25em;
  }
}

.pagination-ellipsis {
  pointer-events: none;
}

.pagination-previous, .pagination-next {
  white-space: nowrap;
  flex-grow: 0;
  flex-shrink: 1;
  padding-inline: .75em;
}

.pagination-previous, .pagination-next, .pagination-link, .pagination-ellipsis {
  text-align: center;
  justify-content: center;
  padding-inline: .5em;
  font-size: 1em;
}

.pagination-previous, .pagination-next, .pagination-link {
  border-color: var(--theme-border);
  color: var(--theme-text);
  min-width: 2.25em;
  text-decoration: none;
}

.pagination-previous:hover, .pagination-next:hover, .pagination-link:hover {
  border-color: var(--theme-primary-hover);
  color: var(--theme-primary-hover);
  text-decoration: none;
}

.pagination-previous[disabled], .pagination-next[disabled], .pagination-link[disabled] {
  border-color: var(--theme-secondary-base);
  background-color: var(--theme-secondary-base);
  opacity: .5;
  color: var(--theme-secondary-dark);
  pointer-events: none;
}

@media screen and (aspect-ratio >= 1), screen and (resolution >= 120dpi) and (aspect-ratio >= 1) {
  .pagination-center .pagination-previous {
    order: 1;
  }

  .pagination-center .pagination-list {
    order: 2;
    justify-content: center;
  }

  .pagination-center .pagination-next {
    order: 3;
  }
}

@media screen and (width >= 768px), print {
  .pagination-list {
    flex-grow: 0;
    flex-shrink: 1;
    order: 1;
    justify-content: flex-start;
  }

  .pagination-previous {
    order: 2;
  }

  .pagination-next {
    order: 3;
  }

  .pagination {
    justify-content: center;
  }

  .pagination.pagination-center .pagination-previous {
    order: 1;
  }

  .pagination.pagination-center .pagination-list {
    order: 2;
    justify-content: center;
  }

  .pagination.pagination-center .pagination-next {
    order: 3;
  }
}

.progress-bar {
  background-color: var(--theme-secondary-background-glow-high-contrast);
  appearance: none;
  border: none;
  border-radius: 290486px;
  width: 100%;
  max-width: 100%;
  height: .25rem;
  padding: 0;
  display: block;
  overflow: hidden;
}

.progress-bar::-webkit-progress-bar {
  background-color: var(--theme-secondary-background-glow-high-contrast);
}

.progress-bar::-webkit-progress-value {
  background-color: var(--theme-primary-base);
}

.progress-bar::-moz-progress-bar {
  background-color: var(--theme-primary-base);
}

.progress-bar.progress-bar-sm {
  height: .125rem;
}

.progress-bar.progress-bar-success::-webkit-progress-value {
  background-color: var(--theme-success-base);
}

.progress-bar.progress-bar-success::-moz-progress-bar {
  background-color: var(--theme-success-base);
}

.progress-bar.progress-bar-danger::-webkit-progress-value {
  background-color: var(--theme-danger-base);
}

.progress-bar.progress-bar-danger::-moz-progress-bar {
  background-color: var(--theme-danger-base);
}

.progress-bar.progress-bar-warning::-webkit-progress-value {
  background-color: var(--theme-warning-base);
}

.progress-bar.progress-bar-warning::-moz-progress-bar {
  background-color: var(--theme-warning-base);
}

.progress-bar:indeterminate {
  background-color: var(--theme-secondary-background-glow-high-contrast);
  background-image: linear-gradient(to right, var(--theme-secondary-background-glow-high-contrast) 0%, var(--theme-primary-base) 20%, var(--theme-secondary-background-glow-high-contrast) 40%);
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 150% 150%;
  animation-name: slide-background;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.progress-bar:indeterminate:dir(rtl) {
  animation-direction: reverse;
}

.progress-bar:indeterminate::-webkit-progress-bar {
  background-color: #0000;
}

.progress-bar:indeterminate::-moz-progress-bar {
  background-color: #0000;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar:indeterminate {
    animation-duration: 0s;
  }
}

@media (forced-colors: active) {
  .progress-bar {
    background-color: canvastext !important;
  }

  .progress-bar::-webkit-progress-bar {
    background-color: canvastext !important;
  }

  .progress-bar::-webkit-progress-value {
    background-color: highlight !important;
  }

  .progress-bar::-moz-progress-bar {
    background-color: highlight !important;
  }

  .progress-bar:indeterminate {
    forced-color-adjust: none !important;
    background-image: linear-gradient(to right, canvastext 0%, highlight 20%, canvastext 40%) !important;
  }

  .progress-bar:indeterminate::-webkit-progress-bar {
    background-color: #0000 !important;
  }
}

.table-wrapper {
  -webkit-overflow-scrolling: touch;
  overflow: auto hidden;
}

.table {
  border-collapse: collapse;
  table-layout: auto;
  width: 100%;
  font-size: .875rem;
}

.table:not(.table-simple) {
  border: 1px solid var(--theme-table-border-dark);
}

.table caption {
  letter-spacing: .125rem;
  text-align: right;
  text-transform: uppercase;
  padding: 1.25rem;
  font-size: .75rem;
  font-weight: 600;
}

.table th, .table td {
  word-wrap: break-word;
  border-block-start: 1px solid var(--theme-table-border-dark);
  vertical-align: top;
  padding: 1rem 1.25rem;
  line-height: 1.5;
  display: table-cell;
}

.table th:not([align]) {
  text-align: left;
}

@media screen and (width <= 767px) {
  .table.table-stacked-mobile th, .table.table-stacked-mobile td {
    width: 100%;
    display: block;
  }

  .table.table-stacked-mobile th:not(:first-child), .table.table-stacked-mobile td:not(:first-child) {
    border-width: 0;
    padding-block-start: 0;
  }

  .table.table-stacked-mobile thead tr:first-child th, .table.table-stacked-mobile tbody tr:first-child td {
    border-block-start: none;
  }

  .table.table-stacked-mobile thead tr > th:last-child {
    border-block-end: 1px solid var(--theme-table-border-dark);
  }
}

.table.table-borderless, .table.table-borderless th, .table.table-borderless td {
  border: none;
}

.table.table-complex thead tr, .table.table-complex tfoot tr {
  background-color: var(--theme-table-header);
}

.table.table-complex th, .table.table-complex td {
  border: 1px solid var(--theme-table-border-dark);
}

.table.table-striped tbody tr:nth-of-type(2n) {
  background-color: var(--theme-table-row);
}

.table.table-sm th, .table.table-sm td {
  padding: .5rem;
}

.table.table-lg th, .table.table-lg td {
  padding: 2rem;
}

.tag {
  border: 1px solid var(--theme-control-border);
  background-color: var(--theme-body-background);
  color: var(--theme-secondary-dark);
  white-space: nowrap;
  border-radius: .25rem;
  justify-content: center;
  align-items: center;
  gap: .375em;
  padding: .375em .675em;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.3;
  display: inline-flex;
}

.tag:where(button) {
  cursor: pointer;
}

.tag .tag-close {
  justify-content: center;
  align-items: center;
  display: inline-flex;
}

.tag:hover .tag-close, .tag.is-hovered .tag-close {
  color: var(--theme-primary-dark);
}

.tag .icon {
  font-size: .875em;
}

.tag.tag-sm {
  font-size: .75rem;
}

.tag.tag-lg {
  font-size: 1rem;
}

.tag.tag-interactive {
  color: var(--theme-text);
  border: none;
  align-items: stretch;
  gap: 0;
  padding: 0;
}

.tag.tag-interactive .tag-popover .tag-summary {
  border: 1px solid var(--theme-control-border);
  outline-offset: -.125rem;
  cursor: pointer;
  border-radius: .25rem;
  outline-color: currentColor;
  padding: .375em .675em;
  overflow: hidden;
}

.tag.tag-interactive .tag-popover .tag-summary:hover, .tag.tag-interactive .tag-popover .tag-summary.is-hovered {
  background-color: var(--theme-secondary-background-glow-high-contrast);
}

.tag.tag-interactive .tag-popover:not(:only-child) .tag-summary {
  border-inline-end: 0;
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.tag.tag-interactive .tag-close {
  border: 0;
  border: 1px solid var(--theme-control-border);
  outline-offset: -.125rem;
  background-color: inherit;
  color: inherit;
  cursor: pointer;
  border-radius: 0 .25rem .25rem 0;
  outline-color: currentColor;
  padding: .375em .675em;
}

.tag.tag-interactive .tag-close:hover, .tag.tag-interactive .tag-close.is-hovered {
  background-color: var(--theme-secondary-background-glow-high-contrast);
  color: var(--theme-primary-dark);
}

.tag.tag-primary {
  border-color: var(--theme-primary-background-glow-high-contrast);
  background-color: var(--theme-primary-background);
  color: var(--theme-primary-dark);
}

.tag.tag-primary:hover .tag-close, .tag.tag-primary.is-hovered .tag-close {
  color: var(--theme-primary-hover);
}

.tag.tag-primary.tag-interactive .tag-popover .tag-summary, .tag.tag-primary.tag-interactive .tag-close {
  border-color: var(--theme-primary-background-glow-high-contrast);
  background-color: var(--theme-primary-background);
  color: var(--theme-primary-dark);
}

.tag.tag-primary.tag-interactive .tag-popover .tag-summary:hover, .tag.tag-primary.tag-interactive .tag-popover .tag-summary.is-hovered, .tag.tag-primary.tag-interactive .tag-close:hover, .tag.tag-primary.tag-interactive .tag-close.is-hovered {
  border-color: var(--theme-primary-background-hover);
  background-color: var(--theme-primary-background-hover);
  color: var(--theme-primary-dark-hover);
}

.tag.tag-primary.tag-interactive .tag-close {
  border-inline-start-color: var(--theme-primary-box-shadow);
}

.tag.tag-filled {
  border-color: var(--theme-secondary-background-glow-high-contrast);
  background-color: var(--theme-secondary-background-glow-high-contrast);
  color: var(--theme-text);
}

.tag.tag-filled:hover .tag-close, .tag.tag-filled.is-hovered .tag-close {
  color: var(--theme-primary-hover);
}

.tag.tag-filled.tag-interactive .tag-popover .tag-summary, .tag.tag-filled.tag-interactive .tag-close {
  border-color: var(--theme-secondary-background-glow-high-contrast);
  background-color: var(--theme-secondary-background-glow-high-contrast);
  color: var(--theme-text);
}

.tag.tag-filled.tag-interactive .tag-popover .tag-summary:hover, .tag.tag-filled.tag-interactive .tag-popover .tag-summary.is-hovered, .tag.tag-filled.tag-interactive .tag-close:hover, .tag.tag-filled.tag-interactive .tag-close.is-hovered {
  border-color: var(--theme-secondary-background-hover);
  background-color: var(--theme-secondary-background-hover);
  color: var(--theme-secondary-invert);
}

.tag.tag-filled.tag-interactive .tag-close {
  border-inline-start-color: var(--theme-control-border);
}

.tag.tag-filled.tag-interactive .tag-close:hover, .tag.tag-filled.tag-interactive .tag-close.is-hovered {
  color: var(--theme-primary-dark-hover);
}

.toggle label {
  border: .065em solid var(--theme-text-subtle);
  background-color: var(--theme-body-background);
  cursor: pointer;
  border-radius: 290486px;
  width: 2.5em;
  height: 1.25em;
  transition: background-color .2s linear, border-color .2s linear;
  display: block;
  position: relative;
}

@media (forced-colors: active) {
  .toggle label {
    background-color: canvas !important;
    border-color: canvastext !important;
  }
}

.toggle label:after {
  background-color: var(--theme-text-subtle);
  content: "";
  border-radius: 290486px;
  width: .85em;
  height: .85em;
  transition: inset-inline-start .2s cubic-bezier(.01, 1.23, .58, .96), border-color .2s cubic-bezier(.01, 1.23, .58, .96), background-color .2s cubic-bezier(.01, 1.23, .58, .96);
  display: block;
  position: absolute;
  inset-block-start: .15em;
  inset-inline-start: .15em;
}

@media (forced-colors: active) {
  .toggle label:after {
    background-color: canvastext !important;
  }
}

.toggle.is-selected label, .toggle input[type="checkbox"]:checked + label {
  border-color: var(--theme-primary-base);
  background-color: var(--theme-primary-base);
}

@media (forced-colors: active) {
  .toggle.is-selected label, .toggle input[type="checkbox"]:checked + label {
    background-color: linktext !important;
    border-color: linktext !important;
  }
}

.toggle.is-selected label:after, .toggle input[type="checkbox"]:checked + label:after {
  background-color: var(--theme-body-background);
  inset-inline-start: calc(100% - 1em);
}

@media (forced-colors: active) {
  .toggle.is-selected label:after, .toggle input[type="checkbox"]:checked + label:after {
    background-color: canvas !important;
  }
}

.toggle input[type="checkbox"] {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  word-wrap: normal;
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.toggle input[type="checkbox"]:disabled + label {
  opacity: .6;
  cursor: not-allowed;
}

.scroll-horizontal {
  -webkit-overflow-scrolling: touch;
  overflow: auto hidden;
}

.scroll-vertical {
  -webkit-overflow-scrolling: touch;
  overflow: hidden auto;
}

.scroll-snap-container {
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  gap: 1rem;
  height: max-content;
  margin-block: 1rem;
}

.scroll-snap-item {
  scroll-snap-align: center;
  flex-basis: 90%;
  min-width: 80%;
  height: auto;
}

.site-header .site-header-brand:active > :not(.icon):not(.image), .site-header .site-header-brand.is-active > :not(.icon):not(.image), .site-header .site-header-brand:hover > :not(.icon):not(.image), .site-header .site-header-brand.is-hovered > :not(.icon):not(.image), .site-header .site-header-brand:focus-visible > :not(.icon):not(.image), .site-header .site-header-button:active:not([aria-expanded="true"]) > :not(.icon):not(.image), .site-header .site-header-button.is-active:not([aria-expanded="true"]) > :not(.icon):not(.image), .site-header .site-header-button:hover:not([aria-expanded="true"]) > :not(.icon):not(.image), .site-header .site-header-button.is-hovered:not([aria-expanded="true"]) > :not(.icon):not(.image), .site-header .site-header-button:focus-visible:not([aria-expanded="true"]) > :not(.icon):not(.image) {
  position: relative;
}

.site-header .site-header-brand:active > :not(.icon):not(.image):after, .site-header .site-header-brand.is-active > :not(.icon):not(.image):after, .site-header .site-header-brand:hover > :not(.icon):not(.image):after, .site-header .site-header-brand.is-hovered > :not(.icon):not(.image):after, .site-header .site-header-brand:focus-visible > :not(.icon):not(.image):after, .site-header .site-header-button:active:not([aria-expanded="true"]) > :not(.icon):not(.image):after, .site-header .site-header-button.is-active:not([aria-expanded="true"]) > :not(.icon):not(.image):after, .site-header .site-header-button:hover:not([aria-expanded="true"]) > :not(.icon):not(.image):after, .site-header .site-header-button.is-hovered:not([aria-expanded="true"]) > :not(.icon):not(.image):after, .site-header .site-header-button:focus-visible:not([aria-expanded="true"]) > :not(.icon):not(.image):after {
  content: "";
  border-block-end: 2px solid var(--theme-text);
  display: block;
  position: absolute;
  inset-block-end: -2px;
  inset-inline: 0;
}

.site-header .site-header-brand, .site-header .site-header-button {
  outline-offset: -.125rem;
  color: var(--theme-text);
  white-space: nowrap;
  cursor: pointer;
  padding-block: calc(.375em - 1px);
  padding-inline: .5rem;
  text-decoration: none;
}

.site-header .site-header-brand:hover, .site-header .site-header-button:hover, .site-header .site-header-brand:visited, .site-header .site-header-button:visited {
  color: var(--theme-text);
}

.site-header {
  border-block-end: 1px solid var(--theme-border);
  align-items: center;
  gap: .5rem;
  height: 3.375em;
  padding-inline: 24px;
  font-size: 1rem;
  display: flex;
  position: relative;
}

@media screen and (width >= 1800px) {
  .site-header {
    padding-inline: max(24px, 50% - 876px);
  }
}

.site-header .site-header-button {
  text-align: center;
  background-color: #0000;
  border: none;
  border-radius: 0;
  justify-content: center;
  height: 100%;
  font-size: .875em;
  font-weight: 400;
}

.site-header .site-header-button[aria-expanded="true"] {
  background-color: var(--theme-body-background-medium);
}

.site-header .site-header-button .icon {
  font-size: .875em;
}

.site-header .site-header-button .icon:only-child {
  margin: 0;
}

.site-header .site-header-button .icon:first-child:not(:only-child) {
  margin-inline-end: .375em;
}

.site-header .site-header-button .icon:last-child:not(:only-child) {
  margin-inline-start: .375em;
}

.site-header .site-header-button:active, .site-header .site-header-button.is-active, .site-header .site-header-button:hover, .site-header .site-header-button.is-hovered, .site-header .site-header-button:focus-visible {
  text-decoration: none;
}

.site-header .site-header-logo > svg, .site-header .site-header-logo > img {
  width: 1.625em;
  height: auto;
  display: block;
}

.site-header .site-header-logo.site-header-logo-centered {
  position: absolute;
  inset-inline-start: calc(50% - .8125em);
}

.site-header .site-header-divider {
  border-inline-start: 2px solid var(--theme-text-subtle);
  height: 1.5em;
  margin-inline-start: .5rem;
  display: inline-block;
}

.site-header .site-header-brand {
  font-size: 1.15em;
  font-weight: 600;
  display: inline-block;
}

.site-header .site-header-brand:active, .site-header .site-header-brand.is-active, .site-header .site-header-brand:hover, .site-header .site-header-brand.is-hovered, .site-header .site-header-brand:focus-visible {
  text-decoration: none;
}

@media screen and (width >= 768px), print {
  .site-header .site-header-brand {
    font-size: 1.25em;
  }
}

.site-header .site-header-nav {
  align-self: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.site-header .site-header-panel {
  grid-gap: 1rem;
  background-color: var(--theme-body-background-medium);
  text-wrap: wrap;
  box-shadow: 0 3.2px 7.2px 0 var(--theme-box-shadow-medium), 0 .6px 1.8px 0 var(--theme-box-shadow-light);
  z-index: 1060;
  border-block-start: 1px solid var(--theme-border-white-high-contrast);
  border-block-end: 1px solid var(--theme-border-white-high-contrast);
  grid-template-areas: "panel-content"
                       "panel-featured-section";
  width: 100%;
  min-height: 350px;
  padding-block: 2rem;
  padding-inline: 24px;
  display: grid;
  position: absolute;
  inset-block-start: calc(3.375em - 1px);
  inset-inline-start: 0;
}

@media screen and (width >= 768px), print {
  .site-header .site-header-panel {
    grid-template-columns: auto 33.333%;
    grid-template-areas: "panel-content panel-featured-section";
  }
}

@media screen and (width >= 1088px) {
  .site-header .site-header-panel {
    grid-template-columns: auto 25%;
  }
}

@media screen and (width >= 1800px) {
  .site-header .site-header-panel {
    padding-inline: max(24px, 50% - 876px);
  }
}

.site-header .site-header-panel .site-header-panel-featured-content {
  border-block-start: 1px solid var(--theme-table-border-dark);
  flex-direction: column;
  grid-area: panel-featured-section;
  gap: 1rem;
  padding-block-start: 1rem;
  display: flex;
}

@media screen and (width >= 768px), print {
  .site-header .site-header-panel .site-header-panel-featured-content {
    border-inline-start: 1px solid var(--theme-table-border-dark);
    border-block-start: 0;
    padding-block-start: 0;
    padding-inline-start: 1rem;
  }
}

.site-header .site-header-panel .site-header-panel-content {
  grid-area: panel-content;
  align-content: space-between;
  display: grid;
}

.site-header .site-header-panel .site-header-panel-links {
  grid-gap: .5rem;
  display: grid;
}

@media screen and (width >= 768px), print {
  .site-header .site-header-panel .site-header-panel-links {
    grid-template-rows: repeat(11, auto);
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: column;
  }
}

@media screen and (width >= 1088px) {
  .site-header .site-header-panel .site-header-panel-links {
    grid-template-rows: repeat(7, auto);
    grid-template-columns: repeat(3, 1fr);
  }
}

.site-header .site-header-panel .site-header-panel-cards {
  grid-gap: .5rem;
  display: grid;
}

@media screen and (width >= 768px), print {
  .site-header .site-header-panel .site-header-panel-cards {
    grid-template-rows: repeat(5, auto);
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: column;
  }
}

@media screen and (width >= 1088px) {
  .site-header .site-header-panel .site-header-panel-cards {
    grid-template-rows: repeat(3, auto);
    grid-template-columns: repeat(3, 1fr);
  }
}

.steps {
  flex-wrap: nowrap;
  gap: .4375em;
  font-size: 1rem;
  display: flex;
}

.steps .step {
  aspect-ratio: 1;
  background-color: var(--theme-secondary-base);
  border-radius: 290486px;
  height: .5em;
}

.steps .step.step-selected {
  aspect-ratio: 2;
  background-color: var(--theme-primary-base);
}

.stretched-link:before {
  content: "";
  z-index: 1;
  position: absolute;
  inset: 0;
}

.segmented-controls {
  grid-gap: .5rem;
  white-space: nowrap;
  grid-template-columns: max-content auto max-content;
  justify-content: center;
  width: 100%;
  font-size: 1rem;
  display: grid;
}

.segmented-controls .segmented-control-previous, .segmented-controls .segmented-control-next {
  border: 1px solid var(--theme-border);
  background-color: var(--theme-primary-base);
  color: var(--theme-text-invert);
  cursor: pointer;
  border-radius: 290486px;
  align-items: center;
  padding: .5em 1em;
  font-weight: 600;
  display: flex;
}

.segmented-controls .segmented-control-previous:hover, .segmented-controls .segmented-control-next:hover {
  background-color: var(--theme-primary-hover);
}

@media (forced-colors: active) {
  .segmented-controls .segmented-control-previous:hover, .segmented-controls .segmented-control-next:hover {
    color: highlighttext;
    forced-color-adjust: none;
    background-color: highlight;
    border-color: highlight;
  }
}

.segmented-controls .segmented-control-previous:focus-visible, .segmented-controls .segmented-control-next:focus-visible {
  outline-color: var(--theme-text-invert);
  outline-offset: -.25rem;
}

.segmented-controls .segmented-control-list {
  border: 1px solid var(--theme-border);
  border-radius: 290486px;
  grid-column: 2;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  overflow-x: hidden;
}

.segmented-controls .segmented-control {
  color: var(--theme-primary-base);
  cursor: pointer;
  appearance: none;
  vertical-align: top;
  background-color: #0000;
  border: none;
  border-radius: 290486px;
  justify-content: center;
  align-items: center;
  padding: .5em 1em;
  font-weight: 600;
  display: flex;
  position: relative;
}

.segmented-controls .segmented-control:focus-visible {
  outline-color: var(--theme-text-invert);
  outline-offset: -.25rem;
}

.segmented-controls .segmented-control:hover {
  background-color: var(--theme-primary-background);
  z-index: 2;
}

@media (forced-colors: active) {
  .segmented-controls .segmented-control:hover {
    forced-color-adjust: none;
    color: highlighttext !important;
    background-color: highlight !important;
  }
}

.segmented-controls .segmented-control[aria-selected="true"] {
  background-color: var(--theme-primary-base);
  color: var(--theme-text-invert);
  z-index: 1;
}

.segmented-controls .segmented-control[aria-selected="true"]:hover {
  background-color: var(--theme-primary-hover);
  z-index: 2;
}

@media (forced-colors: active) {
  .segmented-controls .segmented-control[aria-selected="true"] {
    color: selecteditemtext;
    forced-color-adjust: none;
    background-color: selecteditem;
  }
}

@media screen and (width >= 768px), print {
  .segmented-controls .segmented-control, .segmented-controls .segmented-previous, .segmented-controls .segmented-next {
    padding: .75em 1.25em;
  }
}

@media screen and (width >= 1088px) {
  .segmented-controls .segmented-control, .segmented-controls .segmented-previous, .segmented-controls .segmented-next {
    padding: 1em 1.5em;
  }
}

.timeline {
  font-size: 16px;
  container-type: inline-size;
}

.timeline .timeline-item {
  border-inline-start: 1px solid var(--theme-border);
  padding-block-end: 1.5rem;
  padding-inline-start: 2rem;
  display: flex;
  position: relative;
}

.timeline .timeline-item:last-child {
  border-inline-start: none;
}

.timeline .timeline-item .timeline-item-badge {
  position: absolute;
  transform: translate(calc(-2rem - 50%), -.375rem);
}

.timeline .timeline-item .timeline-item-badge:dir(rtl) {
  transform: translate(calc(2rem + 50%), -.375rem);
}

@container (width >= 480px) {
  .timeline .timeline-item .timeline-item-template {
    grid-template: "title time"
                   "description description"
                   / minmax(0, 1fr) auto;
    gap: 8px;
    width: 100%;
    display: grid;
  }
}

.timeline .timeline-item .timeline-item-template-title {
  grid-area: title;
  font-weight: 600;
  line-height: 1.3;
}

.timeline .timeline-item .timeline-item-template-time {
  grid-area: time;
  align-content: baseline;
  font-size: .75rem;
  font-weight: 200;
  line-height: 1.3;
}

.timeline .timeline-item .timeline-item-template-description {
  grid-area: description;
  padding-block-start: .75rem;
  font-size: .875rem;
  line-height: 1.3;
}

:root {
  --window-inner-height: 100vh;
  --atlas-header-height: 0px;
  --atlas-footer-height: 0px;
  --atlas-contained-height: 1fr;
}

.layout {
  --layout-gap: 24px;
  --layout-gap-scalable: 24px;
  --layout-flyout-width-desktop: 360px;
  --layout-flyout-width-widescreen: 480px;
  --layout-flyout-width: var(--layout-flyout-width-desktop);
  flex-direction: column;
  max-inline-size: 100vw;
  display: flex;
}

@media screen and (width >= 1800px) {
  .layout {
    --layout-gap-scalable: max(24px, 50% - 1752px / 2);
    --layout-flyout-width: var(--layout-flyout-width-widescreen);
  }
}

.layout-padding {
  padding-inline: var(--layout-gap) !important;
}

.layout-margin {
  margin-inline: var(--layout-gap-scalable) !important;
}

@media screen and (width >= 768px), print {
  .layout-padding-tablet {
    padding-inline: var(--layout-gap) !important;
  }

  .layout-margin-tablet {
    margin-inline: var(--layout-gap-scalable) !important;
  }
}

.layout-body {
  width: 100%;
  max-width: 100%;
  display: grid;
}

.layout-body-header {
  grid-area: header;
}

.layout-body-hero {
  grid-area: hero;
}

.layout-body-menu {
  grid-area: menu;
}

.layout-body-main {
  grid-area: main;
}

.layout-body-aside {
  grid-area: aside;
}

.layout-body-footer {
  grid-area: footer;
}

.layout-body-flyout {
  grid-area: flyout;
  display: none;
}

.layout-body-hero:empty, .layout-body-main:empty, .layout-body-footer:empty, .layout-body-aside:empty, .layout-body-menu:empty {
  display: none;
}

.layout .layout-body, .layout.layout-single .layout-body {
  grid-template: "header" minmax(auto, max-content)
                 "hero"
                 "menu"
                 "main" 1fr
                 "aside"
                 "footer"
                 / minmax(0, 1fr);
}

@media screen and (width >= 1088px) {
  .layout.layout-flyout-active .layout-body, .layout.layout-single.layout-flyout-active .layout-body {
    grid-template: minmax(auto, max-content) auto auto 1fr auto auto / minmax(0, 1fr) var(--layout-flyout-width);
    grid-template-areas: "header header"
                         "hero flyout"
                         "menu flyout"
                         "main flyout"
                         "aside flyout"
                         "footer footer";
  }
}

.layout.layout-holy-grail .layout-body {
  grid-template: "header" minmax(auto, max-content)
                 "hero"
                 "menu"
                 "main" 1fr
                 "aside"
                 "footer"
                 / minmax(0, 1fr);
}

@media screen and (width >= 768px), print {
  .layout.layout-holy-grail .layout-body {
    grid-template: minmax(auto, max-content) auto var(--atlas-contained-height) auto auto / minmax(0, 1fr) minmax(0, 2fr);
    grid-template-areas: "header header"
                         "hero hero"
                         "menu main"
                         "menu aside"
                         "footer footer";
  }
}

@media screen and (width >= 1088px) {
  .layout.layout-holy-grail .layout-body {
    grid-template: minmax(auto, max-content) auto var(--atlas-contained-height) auto / minmax(0, 1fr) minmax(0, 2fr) minmax(0, 1fr);
    grid-template-areas: "header header header"
                         "hero hero hero"
                         "menu main aside"
                         "footer footer footer";
  }
}

@media screen and (width >= 1800px) {
  .layout.layout-holy-grail .layout-body {
    grid-template: minmax(auto, max-content) auto var(--atlas-contained-height) auto / auto 450px 900px 450px auto;
    grid-template-areas: "header header header header header"
                         "hero hero hero hero hero"
                         ". menu main aside ."
                         "footer footer footer footer footer";
  }
}

@media screen and (width >= 1088px) {
  .layout.layout-holy-grail.layout-flyout-active .layout-body {
    grid-template: auto auto var(--atlas-contained-height) auto / minmax(0, 1fr) minmax(0, 2fr) minmax(0, 1fr) var(--layout-flyout-width);
    grid-template-areas: "header header header header"
                         "hero hero hero flyout"
                         "menu main aside flyout"
                         "footer footer footer footer";
  }
}

@media screen and (width >= 1800px) {
  .layout.layout-holy-grail.layout-flyout-active .layout-body {
    grid-template: auto auto var(--atlas-contained-height) auto / minmax(0, 1fr) minmax(0, 2fr) minmax(0, 1fr) var(--layout-flyout-width);
    grid-template-areas: "header header header header"
                         "hero hero hero flyout"
                         "menu main aside flyout"
                         "footer footer footer footer";
  }
}

.layout.layout-sidecar-left .layout-body-aside {
  display: none;
}

.layout.layout-sidecar-left .layout-body {
  grid-template: "header" minmax(auto, max-content)
                 "hero"
                 "menu"
                 "main" 1fr
                 "footer"
                 / minmax(0, 1fr);
}

@media screen and (width >= 768px), print {
  .layout.layout-sidecar-left .layout-body {
    grid-template: minmax(auto, max-content) auto var(--atlas-contained-height) auto / minmax(0, 1fr) minmax(0, 2fr);
    grid-template-areas: "header header"
                         "hero hero"
                         "menu main"
                         "footer footer";
  }
}

@media screen and (width >= 1088px) {
  .layout.layout-sidecar-left .layout-body {
    grid-template: minmax(auto, max-content) auto var(--atlas-contained-height) auto / minmax(0, 1fr) minmax(0, 3fr);
    grid-template-areas: "header header"
                         "hero hero"
                         "menu main"
                         "footer footer";
  }
}

@media screen and (width >= 1800px) {
  .layout.layout-sidecar-left .layout-body {
    grid-template: minmax(auto, max-content) auto var(--atlas-contained-height) auto / auto 450px 1350px auto;
    grid-template-areas: "header header header header"
                         "hero hero hero hero"
                         ". menu main ."
                         "footer footer footer footer";
  }
}

@media screen and (width >= 1088px) {
  .layout.layout-sidecar-left.layout-flyout-active .layout-body {
    grid-template: minmax(auto, max-content) auto var(--atlas-contained-height) auto / minmax(0, 1fr) minmax(0, 3fr) var(--layout-flyout-width);
    grid-template-areas: "header header header"
                         "hero hero flyout"
                         "menu main flyout"
                         "footer footer footer";
  }
}

@media screen and (width >= 1800px) {
  .layout.layout-sidecar-left.layout-flyout-active .layout-body {
    grid-template: minmax(auto, max-content) auto var(--atlas-contained-height) auto / minmax(0, 1fr) minmax(0, 3fr) var(--layout-flyout-width);
    grid-template-areas: "header header header"
                         "hero hero flyout"
                         "menu main flyout"
                         "footer footer footer";
  }
}

.layout.layout-sidecar-right .layout-body-menu {
  display: none;
}

.layout.layout-sidecar-right .layout-body {
  grid-template: "header" minmax(auto, max-content)
                 "hero"
                 "main"
                 "aside" 1fr
                 "footer"
                 / minmax(0, 1fr);
}

@media screen and (width >= 768px), print {
  .layout.layout-sidecar-right .layout-body {
    grid-template: minmax(auto, max-content) auto var(--atlas-contained-height) auto / minmax(0, 2fr) minmax(0, 1fr);
    grid-template-areas: "header header"
                         "hero hero"
                         "main aside"
                         "footer footer";
  }
}

@media screen and (width >= 1088px) {
  .layout.layout-sidecar-right .layout-body {
    grid-template: minmax(auto, max-content) auto var(--atlas-contained-height) auto / minmax(0, 3fr) minmax(0, 1fr);
    grid-template-areas: "header header"
                         "hero hero"
                         "main aside"
                         "footer footer";
  }
}

@media screen and (width >= 1800px) {
  .layout.layout-sidecar-right .layout-body {
    grid-template: minmax(auto, max-content) auto var(--atlas-contained-height) auto / auto 1350px 450px auto;
    grid-template-areas: "header header header header"
                         "hero hero hero hero"
                         ". main aside ."
                         "footer footer footer footer";
  }
}

@media screen and (width >= 1088px) {
  .layout.layout-sidecar-right.layout-flyout-active .layout-body {
    grid-template: minmax(auto, max-content) auto var(--atlas-contained-height) auto / minmax(0, 3fr) minmax(0, 1fr) var(--layout-flyout-width);
    grid-template-areas: "header header header"
                         "hero hero flyout"
                         "main aside flyout"
                         "footer footer footer";
  }
}

@media screen and (width >= 1800px) {
  .layout.layout-sidecar-right.layout-flyout-active .layout-body {
    grid-template: minmax(auto, max-content) auto var(--atlas-contained-height) auto / minmax(0, 3fr) minmax(0, 1fr) var(--layout-flyout-width);
    grid-template-areas: "header header header"
                         "hero hero flyout"
                         "main aside flyout"
                         "footer footer footer";
  }
}

.layout.layout-twin .layout-body-menu {
  display: none;
}

.layout.layout-twin .layout-body {
  grid-template: "header" minmax(auto, max-content)
                 "hero"
                 "main"
                 "aside" 1fr
                 "footer"
                 / minmax(0, 1fr);
}

@media screen and (width >= 768px), print {
  .layout.layout-twin .layout-body {
    grid-template: minmax(auto, max-content) auto var(--atlas-contained-height) auto / minmax(0, 1fr) minmax(0, 1fr);
    grid-template-areas: "header header"
                         "hero hero"
                         "main aside"
                         "footer footer";
  }
}

@media screen and (width >= 1088px) {
  .layout.layout-twin.layout-flyout-active .layout-body {
    grid-template: minmax(auto, max-content) auto var(--atlas-contained-height) auto / minmax(0, 1fr) minmax(0, 1fr) var(--layout-flyout-width);
    grid-template-areas: "header header header"
                         "hero hero flyout"
                         "main aside flyout"
                         "footer footer footer";
  }

  .layout.layout-flyout-active .layout-body-flyout {
    display: block;
  }
}

@media screen and (width >= 768px), print {
  .layout.layout-constrained.layout-twin, .layout.layout-constrained.layout-sidecar-left, .layout.layout-constrained.layout-sidecar-right {
    --atlas-contained-height: calc(var(--window-inner-height)  - var(--atlas-header-height)  - var(--atlas-footer-height)  - 1px);
  }

  .layout.layout-constrained.layout-twin .layout-body-main, .layout.layout-constrained.layout-twin .layout-body-aside, .layout.layout-constrained.layout-sidecar-right .layout-body-main, .layout.layout-constrained.layout-sidecar-right .layout-body-aside, .layout.layout-constrained.layout-sidecar-left .layout-body-menu, .layout.layout-constrained.layout-sidecar-left .layout-body-main {
    -webkit-overflow-scrolling: touch;
    position: sticky;
    inset-block-start: 0;
    overflow: hidden auto;
  }
}

@media screen and (width >= 1088px) {
  .layout.layout-constrained.layout-holy-grail {
    --atlas-contained-height: calc(var(--window-inner-height)  - var(--atlas-header-height)  - var(--atlas-footer-height)  - 1px);
  }

  .layout.layout-constrained.layout-holy-grail .layout-body-main, .layout.layout-constrained.layout-holy-grail .layout-body-menu, .layout.layout-constrained.layout-holy-grail .layout-body-aside, .layout.layout-constrained .layout-body-flyout {
    -webkit-overflow-scrolling: touch;
    position: sticky;
    inset-block-start: 0;
    overflow: hidden auto;
  }
}

.aspect-ratio-1-1 {
  aspect-ratio: 1 !important;
}

.aspect-ratio-2-1 {
  aspect-ratio: 2 !important;
}

.aspect-ratio-4-3 {
  aspect-ratio: 4 / 3 !important;
}

.aspect-ratio-9-16 {
  aspect-ratio: 9 / 16 !important;
}

.aspect-ratio-16-9 {
  aspect-ratio: 16 / 9 !important;
}

.font-family-monospace {
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: auto;
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace !important;
}

.font-family-quote {
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif !important;
}

.font-size-h0 {
  font-size: 3.375rem !important;
}

.font-size-h1 {
  font-size: 2.5rem !important;
}

.font-size-h2 {
  font-size: 2.125rem !important;
}

.font-size-h3 {
  font-size: 1.75rem !important;
}

.font-size-h4 {
  font-size: 1.5rem !important;
}

.font-size-h5 {
  font-size: 1.25rem !important;
}

.font-size-h6 {
  font-size: 1.125rem !important;
}

@media screen and (width >= 768px), print {
  .font-size-h0-tablet {
    font-size: 3.375rem !important;
  }

  .font-size-h1-tablet {
    font-size: 2.5rem !important;
  }

  .font-size-h2-tablet {
    font-size: 2.125rem !important;
  }

  .font-size-h3-tablet {
    font-size: 1.75rem !important;
  }

  .font-size-h4-tablet {
    font-size: 1.5rem !important;
  }

  .font-size-h5-tablet {
    font-size: 1.25rem !important;
  }

  .font-size-h6-tablet {
    font-size: 1.125rem !important;
  }
}

.font-size-xl {
  font-size: 1.25rem !important;
}

.font-size-lg {
  font-size: 1.125rem !important;
}

.font-size-md {
  font-size: 1rem !important;
}

.font-size-sm {
  font-size: .875rem !important;
}

.font-size-xs {
  font-size: .75rem !important;
}

@media screen and (width >= 768px), print {
  .font-size-xl-tablet {
    font-size: 1.25rem !important;
  }

  .font-size-lg-tablet {
    font-size: 1.125rem !important;
  }

  .font-size-md-tablet {
    font-size: 1rem !important;
  }

  .font-size-sm-tablet {
    font-size: .875rem !important;
  }

  .font-size-xs-tablet {
    font-size: .75rem !important;
  }
}

.font-weight-light {
  font-weight: 100 !important;
}

.font-weight-semilight {
  font-weight: 200 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-semibold {
  font-weight: 600 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-style-italic {
  font-style: italic !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.letter-spacing-wide {
  letter-spacing: .225rem !important;
}

.text-transform-uppercase {
  text-transform: uppercase !important;
}

.text-align-left {
  text-align: start !important;
}

.text-align-center {
  text-align: center !important;
}

.text-align-right {
  text-align: end !important;
}

@media screen and (width >= 768px), print {
  .text-align-left-tablet {
    text-align: start !important;
  }

  .text-align-center-tablet {
    text-align: center !important;
  }

  .text-align-right-tablet {
    text-align: end !important;
  }
}

.text-wrap-pretty {
  text-wrap: pretty !important;
}

.line-height-normal {
  line-height: 1.3 !important;
}

.white-space-normal {
  white-space: normal !important;
}

.white-space-nowrap {
  white-space: nowrap !important;
}

.white-space-pre {
  white-space: pre !important;
}

.white-space-pre-wrap {
  white-space: pre-wrap !important;
}

.border {
  border: 1px solid var(--theme-border) !important;
}

.border-md {
  border: .125rem solid var(--theme-border) !important;
}

.border-lg {
  border: .25rem solid var(--theme-border) !important;
}

.border-none {
  border: none !important;
}

.border-top {
  border-block-start: 1px solid var(--theme-border) !important;
}

.border-top-md {
  border-block-start: .125rem solid var(--theme-border) !important;
}

.border-top-lg {
  border-block-start: .25rem solid var(--theme-border) !important;
}

.border-top-none {
  border-block-start: 0 !important;
}

.border-right {
  border-inline-end: 1px solid var(--theme-border) !important;
}

.border-right-md {
  border-inline-end: .125rem solid var(--theme-border) !important;
}

.border-right-lg {
  border-inline-end: .25rem solid var(--theme-border) !important;
}

.border-right-none {
  border-inline-end: 0 !important;
}

.border-bottom {
  border-block-end: 1px solid var(--theme-border) !important;
}

.border-bottom-md {
  border-block-end: .125rem solid var(--theme-border) !important;
}

.border-bottom-lg {
  border-block-end: .25rem solid var(--theme-border) !important;
}

.border-bottom-none {
  border-block-end: 0 !important;
}

.border-left {
  border-inline-start: 1px solid var(--theme-border) !important;
}

.border-left-md {
  border-inline-start: .125rem solid var(--theme-border) !important;
}

.border-left-lg {
  border-inline-start: .25rem solid var(--theme-border) !important;
}

.border-left-none {
  border-inline-start: 0 !important;
}

@media screen and (width >= 768px), print {
  .border-tablet {
    border: 1px solid var(--theme-border) !important;
  }

  .border-md-tablet {
    border: .125rem solid var(--theme-border) !important;
  }

  .border-lg-tablet {
    border: .25rem solid var(--theme-border) !important;
  }

  .border-none-tablet {
    border: none !important;
  }

  .border-top-tablet {
    border-block-start: 1px solid var(--theme-border) !important;
  }

  .border-top-md-tablet {
    border-block-start: .125rem solid var(--theme-border) !important;
  }

  .border-top-lg-tablet {
    border-block-start: .25rem solid var(--theme-border) !important;
  }

  .border-top-none-tablet {
    border-block-start: 0 !important;
  }

  .border-right-tablet {
    border-inline-end: 1px solid var(--theme-border) !important;
  }

  .border-right-md-tablet {
    border-inline-end: .125rem solid var(--theme-border) !important;
  }

  .border-right-lg-tablet {
    border-inline-end: .25rem solid var(--theme-border) !important;
  }

  .border-right-none-tablet {
    border-inline-end: 0 !important;
  }

  .border-bottom-tablet {
    border-block-end: 1px solid var(--theme-border) !important;
  }

  .border-bottom-md-tablet {
    border-block-end: .125rem solid var(--theme-border) !important;
  }

  .border-bottom-lg-tablet {
    border-block-end: .25rem solid var(--theme-border) !important;
  }

  .border-bottom-none-tablet {
    border-block-end: 0 !important;
  }

  .border-left-tablet {
    border-inline-start: 1px solid var(--theme-border) !important;
  }

  .border-left-md-tablet {
    border-inline-start: .125rem solid var(--theme-border) !important;
  }

  .border-left-lg-tablet {
    border-inline-start: .25rem solid var(--theme-border) !important;
  }

  .border-left-none-tablet {
    border-inline-start: 0 !important;
  }
}

.border-radius-sm {
  border-radius: .125rem !important;
}

.border-radius {
  border-radius: .25rem !important;
}

.border-radius-lg {
  border-radius: .375rem !important;
}

.border-radius-rounded {
  border-radius: 290486px !important;
}

.border-radius-none {
  border-radius: 0 !important;
}

.border-color-primary {
  border-color: var(--theme-primary-base) !important;
}

.border-color-secondary {
  border-color: var(--theme-secondary-base) !important;
}

.border-color-tertiary {
  border-color: var(--theme-tertiary-base) !important;
}

.border-color-success {
  border-color: var(--theme-success-base) !important;
}

.border-color-info {
  border-color: var(--theme-info-base) !important;
}

.border-color-warning {
  border-color: var(--theme-warning-base) !important;
}

.border-color-danger {
  border-color: var(--theme-danger-base) !important;
}

.border-color-accent {
  border-color: var(--theme-border-accent) !important;
}

.border-high-contrast {
  border: 1px solid var(--theme-border-white-high-contrast) !important;
}

.border-high-contrast-hover:hover {
  border: 1px solid var(--theme-border-yellow-high-contrast) !important;
}

.background-size-100 {
  background-size: 100px;
}

.background-size-200 {
  background-size: 200px;
}

.background-image-pattern {
  background-image: var(--background-image-pattern);
}

.color-primary {
  color: var(--theme-primary-base) !important;
}

a.color-primary:hover, a.color-primary:focus-visible {
  color: var(--theme-primary-hover) !important;
}

a.color-primary:active {
  color: var(--theme-primary-active) !important;
}

.color-primary-invert {
  color: var(--theme-primary-invert) !important;
}

.background-color-primary {
  outline-color: var(--theme-primary-invert);
  background-color: var(--theme-primary-base) !important;
}

.background-color-primary-invert {
  outline-color: var(--theme-primary-base);
  background-color: var(--theme-primary-invert) !important;
}

.background-color-primary-light {
  outline-color: var(--theme-primary-dark);
  background-color: var(--theme-primary-background) !important;
}

.background-color-primary-dark {
  outline-color: var(--theme-primary-background);
  background-color: var(--theme-primary-dark) !important;
}

.color-secondary {
  color: var(--theme-secondary-base) !important;
}

a.color-secondary:hover, a.color-secondary:focus-visible {
  color: var(--theme-secondary-hover) !important;
}

a.color-secondary:active {
  color: var(--theme-secondary-active) !important;
}

.color-secondary-invert {
  color: var(--theme-secondary-invert) !important;
}

.background-color-secondary {
  outline-color: var(--theme-secondary-invert);
  background-color: var(--theme-secondary-base) !important;
}

.background-color-secondary-invert {
  outline-color: var(--theme-secondary-base);
  background-color: var(--theme-secondary-invert) !important;
}

.background-color-secondary-light {
  outline-color: var(--theme-secondary-dark);
  background-color: var(--theme-secondary-background) !important;
}

.background-color-secondary-dark {
  outline-color: var(--theme-secondary-background);
  background-color: var(--theme-secondary-dark) !important;
}

.color-tertiary {
  color: var(--theme-tertiary-base) !important;
}

a.color-tertiary:hover, a.color-tertiary:focus-visible {
  color: var(--theme-tertiary-hover) !important;
}

a.color-tertiary:active {
  color: var(--theme-tertiary-active) !important;
}

.color-tertiary-invert {
  color: var(--theme-tertiary-invert) !important;
}

.background-color-tertiary {
  outline-color: var(--theme-tertiary-invert);
  background-color: var(--theme-tertiary-base) !important;
}

.background-color-tertiary-invert {
  outline-color: var(--theme-tertiary-base);
  background-color: var(--theme-tertiary-invert) !important;
}

.background-color-tertiary-light {
  outline-color: var(--theme-tertiary-dark);
  background-color: var(--theme-tertiary-background) !important;
}

.background-color-tertiary-dark {
  outline-color: var(--theme-tertiary-background);
  background-color: var(--theme-tertiary-dark) !important;
}

.color-success {
  color: var(--theme-success-base) !important;
}

a.color-success:hover, a.color-success:focus-visible {
  color: var(--theme-success-hover) !important;
}

a.color-success:active {
  color: var(--theme-success-active) !important;
}

.color-success-invert {
  color: var(--theme-success-invert) !important;
}

.background-color-success {
  outline-color: var(--theme-success-invert);
  background-color: var(--theme-success-base) !important;
}

.background-color-success-invert {
  outline-color: var(--theme-success-base);
  background-color: var(--theme-success-invert) !important;
}

.background-color-success-light {
  outline-color: var(--theme-success-dark);
  background-color: var(--theme-success-background) !important;
}

.background-color-success-dark {
  outline-color: var(--theme-success-background);
  background-color: var(--theme-success-dark) !important;
}

.color-info {
  color: var(--theme-info-base) !important;
}

a.color-info:hover, a.color-info:focus-visible {
  color: var(--theme-info-hover) !important;
}

a.color-info:active {
  color: var(--theme-info-active) !important;
}

.color-info-invert {
  color: var(--theme-info-invert) !important;
}

.background-color-info {
  outline-color: var(--theme-info-invert);
  background-color: var(--theme-info-base) !important;
}

.background-color-info-invert {
  outline-color: var(--theme-info-base);
  background-color: var(--theme-info-invert) !important;
}

.background-color-info-light {
  outline-color: var(--theme-info-dark);
  background-color: var(--theme-info-background) !important;
}

.background-color-info-dark {
  outline-color: var(--theme-info-background);
  background-color: var(--theme-info-dark) !important;
}

.color-warning, a.color-warning:hover {
  color: var(--theme-warning-active) !important;
}

a.color-warning:focus-visible {
  color: var(--theme-warning-hover) !important;
}

a.color-warning:active {
  color: var(--theme-warning-active) !important;
}

.color-warning-invert {
  color: var(--theme-warning-invert) !important;
}

.background-color-warning {
  outline-color: var(--theme-warning-invert);
  background-color: var(--theme-warning-base) !important;
}

.background-color-warning-invert {
  outline-color: var(--theme-warning-base);
  background-color: var(--theme-warning-invert) !important;
}

.background-color-warning-light {
  outline-color: var(--theme-warning-dark);
  background-color: var(--theme-warning-background) !important;
}

.background-color-warning-dark {
  outline-color: var(--theme-warning-background);
  background-color: var(--theme-warning-dark) !important;
}

.color-danger {
  color: var(--theme-danger-base) !important;
}

a.color-danger:hover, a.color-danger:focus-visible {
  color: var(--theme-danger-hover) !important;
}

a.color-danger:active {
  color: var(--theme-danger-active) !important;
}

.color-danger-invert {
  color: var(--theme-danger-invert) !important;
}

.background-color-danger {
  outline-color: var(--theme-danger-invert);
  background-color: var(--theme-danger-base) !important;
}

.background-color-danger-invert {
  outline-color: var(--theme-danger-base);
  background-color: var(--theme-danger-invert) !important;
}

.background-color-danger-light {
  outline-color: var(--theme-danger-dark);
  background-color: var(--theme-danger-background) !important;
}

.background-color-danger-dark {
  outline-color: var(--theme-danger-background);
  background-color: var(--theme-danger-dark) !important;
}

@media screen and (width >= 768px), print {
  .background-color-primary-tablet {
    outline-color: var(--theme-primary-invert);
    background-color: var(--theme-primary-base) !important;
  }

  .background-color-secondary-tablet {
    outline-color: var(--theme-secondary-invert);
    background-color: var(--theme-secondary-base) !important;
  }

  .background-color-tertiary-tablet {
    outline-color: var(--theme-tertiary-invert);
    background-color: var(--theme-tertiary-base) !important;
  }

  .background-color-success-tablet {
    outline-color: var(--theme-success-invert);
    background-color: var(--theme-success-base) !important;
  }

  .background-color-info-tablet {
    outline-color: var(--theme-info-invert);
    background-color: var(--theme-info-base) !important;
  }

  .background-color-warning-tablet {
    outline-color: var(--theme-warning-invert);
    background-color: var(--theme-warning-base) !important;
  }

  .background-color-danger-tablet {
    outline-color: var(--theme-danger-invert);
    background-color: var(--theme-danger-base) !important;
  }
}

.color-text {
  color: var(--theme-text) !important;
}

.color-text-invert {
  color: var(--theme-text-invert) !important;
}

.color-text-subtle {
  color: var(--theme-text-subtle) !important;
}

.color-text-overlay-invert {
  color: var(--theme-overlay-invert);
}

.color-current-color {
  color: currentColor !important;
}

.color-hyperlink {
  color: var(--theme-hyperlink) !important;
}

.fill-current-color {
  forced-color-adjust: auto;
  fill: currentColor !important;
}

.outline-color-text {
  outline-color: var(--theme-text) !important;
}

.stroke-current-color {
  stroke: currentColor !important;
}

.background-color-body {
  outline-color: var(--theme-text) !important;
  background-color: var(--theme-body-background) !important;
}

.background-color-body-medium {
  outline-color: var(--theme-text) !important;
  background-color: var(--theme-body-background-medium) !important;
}

.background-color-alternate {
  outline-color: var(--theme-text-invert) !important;
  background-color: var(--theme-alternate-background) !important;
}

.background-color-alternate-medium {
  outline-color: var(--theme-text-invert) !important;
  background-color: var(--theme-alternate-background-medium) !important;
}

.background-color-overlay {
  outline-color: var(--theme-overlay-invert);
  background-color: var(--theme-overlay) !important;
}

.background-color-alternating-grey {
  border-block-start: 1px solid var(--theme-border-white-high-contrast);
  outline-color: var(--theme-text) !important;
}

.background-color-alternating-grey:nth-of-type(2n) {
  outline-color: var(--theme-text);
  background-color: var(--theme-body-background);
}

.background-color-alternating-grey:nth-of-type(odd) {
  outline-color: var(--theme-text);
  background-color: var(--theme-body-background-medium);
}

.background-color-alternating-grey-reversed {
  border-block-start: 1px solid var(--theme-border-white-high-contrast);
  outline-color: var(--theme-text) !important;
}

.background-color-alternating-grey-reversed:nth-of-type(2n) {
  outline-color: var(--theme-text);
  background-color: var(--theme-body-background-medium);
}

.background-color-alternating-grey-reversed:nth-of-type(odd) {
  outline-color: var(--theme-text);
  background-color: var(--theme-body-background);
}

.background-color-body-accent, .background-color-body-accent-onhover:hover, .background-color-body-accent-onhover:focus-within {
  outline-color: var(--theme-text) !important;
  background-color: var(--theme-body-background-accent) !important;
}

.background-color-transparent {
  background-color: #0000 !important;
}

.color-white-static {
  color: #fff !important;
}

.color-black-static {
  color: #000 !important;
}

.background-color-white-static {
  background-color: #fff !important;
  outline-color: #000 !important;
}

.background-color-black-static {
  background-color: #000 !important;
  outline-color: #fff !important;
}

.display-block {
  display: block !important;
}

.display-flex {
  display: flex !important;
}

.display-inline {
  display: inline !important;
}

.display-inline-block {
  display: inline-block !important;
}

.display-inline-flex {
  display: inline-flex !important;
}

.display-grid {
  display: grid !important;
}

.display-none {
  display: none !important;
}

@media screen and (width >= 768px), print {
  .display-block-tablet {
    display: block !important;
  }

  .display-flex-tablet {
    display: flex !important;
  }

  .display-inline-tablet {
    display: inline !important;
  }

  .display-inline-block-tablet {
    display: inline-block !important;
  }

  .display-inline-flex-tablet {
    display: inline-flex !important;
  }

  .display-grid-tablet {
    display: grid !important;
  }

  .display-none-tablet {
    display: none !important;
  }
}

@media screen and (width >= 1088px) {
  .display-block-desktop {
    display: block !important;
  }

  .display-flex-desktop {
    display: flex !important;
  }

  .display-inline-desktop {
    display: inline !important;
  }

  .display-inline-block-desktop {
    display: inline-block !important;
  }

  .display-inline-flex-desktop {
    display: inline-flex !important;
  }

  .display-grid-desktop {
    display: grid !important;
  }

  .display-none-desktop {
    display: none !important;
  }
}

@media screen and (width >= 1800px) {
  .display-block-widescreen {
    display: block !important;
  }

  .display-flex-widescreen {
    display: flex !important;
  }

  .display-inline-widescreen {
    display: inline !important;
  }

  .display-inline-block-widescreen {
    display: inline-block !important;
  }

  .display-inline-flex-widescreen {
    display: inline-flex !important;
  }

  .display-grid-widescreen {
    display: grid !important;
  }

  .display-none-widescreen {
    display: none !important;
  }
}

[hidden] {
  display: none !important;
}

.gap-xxs {
  gap: .5rem !important;
}

.gap-xs {
  gap: 1rem !important;
}

.gap-sm {
  gap: 1.5rem !important;
}

.gap-md {
  gap: 2rem !important;
}

.gap-lg {
  gap: 3rem !important;
}

.gap-none {
  gap: 0 !important;
}

@media screen and (width >= 768px), print {
  .gap-xxs-tablet {
    gap: .5rem !important;
  }

  .gap-xs-tablet {
    gap: 1rem !important;
  }

  .gap-sm-tablet {
    gap: 1.5rem !important;
  }

  .gap-md-tablet {
    gap: 2rem !important;
  }

  .gap-lg-tablet {
    gap: 3rem !important;
  }

  .gap-none-tablet {
    gap: 0 !important;
  }
}

@media screen and (width >= 1088px) {
  .gap-xxs-desktop {
    gap: .5rem !important;
  }

  .gap-xs-desktop {
    gap: 1rem !important;
  }

  .gap-sm-desktop {
    gap: 1.5rem !important;
  }

  .gap-md-desktop {
    gap: 2rem !important;
  }

  .gap-lg-desktop {
    gap: 3rem !important;
  }

  .gap-none-desktop {
    gap: 0 !important;
  }
}

@media screen and (width >= 1800px) {
  .gap-xxs-widescreen {
    gap: .5rem !important;
  }

  .gap-xs-widescreen {
    gap: 1rem !important;
  }

  .gap-sm-widescreen {
    gap: 1.5rem !important;
  }

  .gap-md-widescreen {
    gap: 2rem !important;
  }

  .gap-lg-widescreen {
    gap: 3rem !important;
  }

  .gap-none-widescreen {
    gap: 0 !important;
  }
}

.height-full {
  height: 100% !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

@media screen and (width >= 768px), print {
  .overflow-hidden-tablet {
    overflow: hidden !important;
  }

  .overflow-x-hidden-tablet {
    overflow-x: hidden !important;
  }
}

.scrollbar-gutter-stable {
  scrollbar-gutter: stable !important;
}

.scrollbar-gutter-stable-both-edges {
  scrollbar-gutter: stable both-edges !important;
}

.box-shadow-none {
  box-shadow: none !important;
}

.box-shadow-light {
  box-shadow: 0 1.6px 3.6px 0 var(--theme-box-shadow-medium), 0 .3px .9px 0 var(--theme-box-shadow-light) !important;
}

.box-shadow-medium {
  box-shadow: 0 3.2px 7.2px 0 var(--theme-box-shadow-medium), 0 .6px 1.8px 0 var(--theme-box-shadow-light) !important;
}

.box-shadow-heavy {
  box-shadow: 0 6.4px 14.4px 0 var(--theme-box-shadow-medium), 0 1.2px 3.6px 0 var(--theme-box-shadow-light) !important;
}

.box-shadow-extra-heavy {
  box-shadow: 0 25.6px 57.6px 0 var(--theme-box-shadow-extra-heavy), 0 4.8px 14.4px 0 var(--theme-box-shadow-heavy) !important;
}

.margin-inline-auto {
  margin-inline: auto !important;
}

.margin-left-auto {
  margin-inline-start: auto !important;
}

.margin-right-auto {
  margin-inline-end: auto !important;
}

.margin-top-auto {
  margin-block-start: auto !important;
}

@media screen and (width >= 768px), print {
  .margin-inline-auto-tablet {
    margin-inline: auto !important;
  }

  .margin-left-auto-tablet {
    margin-inline-start: auto !important;
  }

  .margin-right-auto-tablet {
    margin-inline-end: auto !important;
  }

  .margin-top-auto-tablet {
    margin-block-start: auto !important;
  }
}

@media screen and (width >= 1088px) {
  .margin-inline-auto-desktop {
    margin-inline: auto !important;
  }

  .margin-left-auto-desktop {
    margin-inline-start: auto !important;
  }

  .margin-right-auto-desktop {
    margin-inline-end: auto !important;
  }

  .margin-top-auto-desktop {
    margin-block-start: auto !important;
  }
}

@media screen and (width >= 1800px) {
  .margin-inline-auto-widescreen {
    margin-inline: auto !important;
  }

  .margin-left-auto-widescreen {
    margin-inline-start: auto !important;
  }

  .margin-right-auto-widescreen {
    margin-inline-end: auto !important;
  }

  .margin-top-auto-widescreen {
    margin-block-start: auto !important;
  }
}

.margin-xxs {
  margin: .5rem !important;
}

.margin-xs {
  margin: 1rem !important;
}

.margin-sm {
  margin: 1.5rem !important;
}

.margin-md {
  margin: 2rem !important;
}

.margin-lg {
  margin: 3rem !important;
}

.margin-xl {
  margin: 4rem !important;
}

.margin-xxl {
  margin: 6rem !important;
}

.margin-xxxl {
  margin: 8rem !important;
}

.margin-none {
  margin: 0 !important;
}

.margin-inline-xxs {
  margin-inline: .5rem !important;
}

.margin-inline-xs {
  margin-inline: 1rem !important;
}

.margin-inline-sm {
  margin-inline: 1.5rem !important;
}

.margin-inline-md {
  margin-inline: 2rem !important;
}

.margin-inline-lg {
  margin-inline: 3rem !important;
}

.margin-inline-xl {
  margin-inline: 4rem !important;
}

.margin-inline-xxl {
  margin-inline: 6rem !important;
}

.margin-inline-xxxl {
  margin-inline: 8rem !important;
}

.margin-inline-none {
  margin-inline: 0 !important;
}

.margin-block-xxs {
  margin-block: .5rem !important;
}

.margin-block-xs {
  margin-block: 1rem !important;
}

.margin-block-sm {
  margin-block: 1.5rem !important;
}

.margin-block-md {
  margin-block: 2rem !important;
}

.margin-block-lg {
  margin-block: 3rem !important;
}

.margin-block-xl {
  margin-block: 4rem !important;
}

.margin-block-xxl {
  margin-block: 6rem !important;
}

.margin-block-xxxl {
  margin-block: 8rem !important;
}

.margin-block-none {
  margin-block: 0 !important;
}

.margin-top-xxs {
  margin-block-start: .5rem !important;
}

.margin-top-xs {
  margin-block-start: 1rem !important;
}

.margin-top-sm {
  margin-block-start: 1.5rem !important;
}

.margin-top-md {
  margin-block-start: 2rem !important;
}

.margin-top-lg {
  margin-block-start: 3rem !important;
}

.margin-top-xl {
  margin-block-start: 4rem !important;
}

.margin-top-xxl {
  margin-block-start: 6rem !important;
}

.margin-top-xxxl {
  margin-block-start: 8rem !important;
}

.margin-top-none {
  margin-block-start: 0 !important;
}

.margin-bottom-xxs {
  margin-block-end: .5rem !important;
}

.margin-bottom-xs {
  margin-block-end: 1rem !important;
}

.margin-bottom-sm {
  margin-block-end: 1.5rem !important;
}

.margin-bottom-md {
  margin-block-end: 2rem !important;
}

.margin-bottom-lg {
  margin-block-end: 3rem !important;
}

.margin-bottom-xl {
  margin-block-end: 4rem !important;
}

.margin-bottom-xxl {
  margin-block-end: 6rem !important;
}

.margin-bottom-xxxl {
  margin-block-end: 8rem !important;
}

.margin-bottom-none {
  margin-block-end: 0 !important;
}

.margin-left-xxs {
  margin-inline-start: .5rem !important;
}

.margin-left-xs {
  margin-inline-start: 1rem !important;
}

.margin-left-sm {
  margin-inline-start: 1.5rem !important;
}

.margin-left-md {
  margin-inline-start: 2rem !important;
}

.margin-left-lg {
  margin-inline-start: 3rem !important;
}

.margin-left-xl {
  margin-inline-start: 4rem !important;
}

.margin-left-xxl {
  margin-inline-start: 6rem !important;
}

.margin-left-xxxl {
  margin-inline-start: 8rem !important;
}

.margin-left-none {
  margin-inline-start: 0 !important;
}

.margin-right-xxs {
  margin-inline-end: .5rem !important;
}

.margin-right-xs {
  margin-inline-end: 1rem !important;
}

.margin-right-sm {
  margin-inline-end: 1.5rem !important;
}

.margin-right-md {
  margin-inline-end: 2rem !important;
}

.margin-right-lg {
  margin-inline-end: 3rem !important;
}

.margin-right-xl {
  margin-inline-end: 4rem !important;
}

.margin-right-xxl {
  margin-inline-end: 6rem !important;
}

.margin-right-xxxl {
  margin-inline-end: 8rem !important;
}

.margin-right-none {
  margin-inline-end: 0 !important;
}

.padding-xxs {
  padding: .5rem !important;
}

.padding-xs {
  padding: 1rem !important;
}

.padding-sm {
  padding: 1.5rem !important;
}

.padding-md {
  padding: 2rem !important;
}

.padding-lg {
  padding: 3rem !important;
}

.padding-xl {
  padding: 4rem !important;
}

.padding-xxl {
  padding: 6rem !important;
}

.padding-xxxl {
  padding: 8rem !important;
}

.padding-none {
  padding: 0 !important;
}

.padding-inline-xxs {
  padding-inline: .5rem !important;
}

.padding-inline-xs {
  padding-inline: 1rem !important;
}

.padding-inline-sm {
  padding-inline: 1.5rem !important;
}

.padding-inline-md {
  padding-inline: 2rem !important;
}

.padding-inline-lg {
  padding-inline: 3rem !important;
}

.padding-inline-xl {
  padding-inline: 4rem !important;
}

.padding-inline-xxl {
  padding-inline: 6rem !important;
}

.padding-inline-xxxl {
  padding-inline: 8rem !important;
}

.padding-inline-none {
  padding-inline: 0 !important;
}

.padding-block-xxs {
  padding-block: .5rem !important;
}

.padding-block-xs {
  padding-block: 1rem !important;
}

.padding-block-sm {
  padding-block: 1.5rem !important;
}

.padding-block-md {
  padding-block: 2rem !important;
}

.padding-block-lg {
  padding-block: 3rem !important;
}

.padding-block-xl {
  padding-block: 4rem !important;
}

.padding-block-xxl {
  padding-block: 6rem !important;
}

.padding-block-xxxl {
  padding-block: 8rem !important;
}

.padding-block-none {
  padding-block: 0 !important;
}

.padding-top-xxs {
  padding-block-start: .5rem !important;
}

.padding-top-xs {
  padding-block-start: 1rem !important;
}

.padding-top-sm {
  padding-block-start: 1.5rem !important;
}

.padding-top-md {
  padding-block-start: 2rem !important;
}

.padding-top-lg {
  padding-block-start: 3rem !important;
}

.padding-top-xl {
  padding-block-start: 4rem !important;
}

.padding-top-xxl {
  padding-block-start: 6rem !important;
}

.padding-top-xxxl {
  padding-block-start: 8rem !important;
}

.padding-top-none {
  padding-block-start: 0 !important;
}

.padding-bottom-xxs {
  padding-block-end: .5rem !important;
}

.padding-bottom-xs {
  padding-block-end: 1rem !important;
}

.padding-bottom-sm {
  padding-block-end: 1.5rem !important;
}

.padding-bottom-md {
  padding-block-end: 2rem !important;
}

.padding-bottom-lg {
  padding-block-end: 3rem !important;
}

.padding-bottom-xl {
  padding-block-end: 4rem !important;
}

.padding-bottom-xxl {
  padding-block-end: 6rem !important;
}

.padding-bottom-xxxl {
  padding-block-end: 8rem !important;
}

.padding-bottom-none {
  padding-block-end: 0 !important;
}

.padding-left-xxs {
  padding-inline-start: .5rem !important;
}

.padding-left-xs {
  padding-inline-start: 1rem !important;
}

.padding-left-sm {
  padding-inline-start: 1.5rem !important;
}

.padding-left-md {
  padding-inline-start: 2rem !important;
}

.padding-left-lg {
  padding-inline-start: 3rem !important;
}

.padding-left-xl {
  padding-inline-start: 4rem !important;
}

.padding-left-xxl {
  padding-inline-start: 6rem !important;
}

.padding-left-xxxl {
  padding-inline-start: 8rem !important;
}

.padding-left-none {
  padding-inline-start: 0 !important;
}

.padding-right-xxs {
  padding-inline-end: .5rem !important;
}

.padding-right-xs {
  padding-inline-end: 1rem !important;
}

.padding-right-sm {
  padding-inline-end: 1.5rem !important;
}

.padding-right-md {
  padding-inline-end: 2rem !important;
}

.padding-right-lg {
  padding-inline-end: 3rem !important;
}

.padding-right-xl {
  padding-inline-end: 4rem !important;
}

.padding-right-xxl {
  padding-inline-end: 6rem !important;
}

.padding-right-xxxl {
  padding-inline-end: 8rem !important;
}

.padding-right-none {
  padding-inline-end: 0 !important;
}

@media screen and (width >= 768px), print {
  .margin-xxs-tablet {
    margin: .5rem !important;
  }

  .margin-xs-tablet {
    margin: 1rem !important;
  }

  .margin-sm-tablet {
    margin: 1.5rem !important;
  }

  .margin-md-tablet {
    margin: 2rem !important;
  }

  .margin-lg-tablet {
    margin: 3rem !important;
  }

  .margin-xl-tablet {
    margin: 4rem !important;
  }

  .margin-xxl-tablet {
    margin: 6rem !important;
  }

  .margin-xxxl-tablet {
    margin: 8rem !important;
  }

  .margin-none-tablet {
    margin: 0 !important;
  }

  .margin-inline-xxs-tablet {
    margin-inline: .5rem !important;
  }

  .margin-inline-xs-tablet {
    margin-inline: 1rem !important;
  }

  .margin-inline-sm-tablet {
    margin-inline: 1.5rem !important;
  }

  .margin-inline-md-tablet {
    margin-inline: 2rem !important;
  }

  .margin-inline-lg-tablet {
    margin-inline: 3rem !important;
  }

  .margin-inline-xl-tablet {
    margin-inline: 4rem !important;
  }

  .margin-inline-xxl-tablet {
    margin-inline: 6rem !important;
  }

  .margin-inline-xxxl-tablet {
    margin-inline: 8rem !important;
  }

  .margin-inline-none-tablet {
    margin-inline: 0 !important;
  }

  .margin-block-xxs-tablet {
    margin-block: .5rem !important;
  }

  .margin-block-xs-tablet {
    margin-block: 1rem !important;
  }

  .margin-block-sm-tablet {
    margin-block: 1.5rem !important;
  }

  .margin-block-md-tablet {
    margin-block: 2rem !important;
  }

  .margin-block-lg-tablet {
    margin-block: 3rem !important;
  }

  .margin-block-xl-tablet {
    margin-block: 4rem !important;
  }

  .margin-block-xxl-tablet {
    margin-block: 6rem !important;
  }

  .margin-block-xxxl-tablet {
    margin-block: 8rem !important;
  }

  .margin-block-none-tablet {
    margin-block: 0 !important;
  }

  .margin-top-xxs-tablet {
    margin-block-start: .5rem !important;
  }

  .margin-top-xs-tablet {
    margin-block-start: 1rem !important;
  }

  .margin-top-sm-tablet {
    margin-block-start: 1.5rem !important;
  }

  .margin-top-md-tablet {
    margin-block-start: 2rem !important;
  }

  .margin-top-lg-tablet {
    margin-block-start: 3rem !important;
  }

  .margin-top-xl-tablet {
    margin-block-start: 4rem !important;
  }

  .margin-top-xxl-tablet {
    margin-block-start: 6rem !important;
  }

  .margin-top-xxxl-tablet {
    margin-block-start: 8rem !important;
  }

  .margin-top-none-tablet {
    margin-block-start: 0 !important;
  }

  .margin-bottom-xxs-tablet {
    margin-block-end: .5rem !important;
  }

  .margin-bottom-xs-tablet {
    margin-block-end: 1rem !important;
  }

  .margin-bottom-sm-tablet {
    margin-block-end: 1.5rem !important;
  }

  .margin-bottom-md-tablet {
    margin-block-end: 2rem !important;
  }

  .margin-bottom-lg-tablet {
    margin-block-end: 3rem !important;
  }

  .margin-bottom-xl-tablet {
    margin-block-end: 4rem !important;
  }

  .margin-bottom-xxl-tablet {
    margin-block-end: 6rem !important;
  }

  .margin-bottom-xxxl-tablet {
    margin-block-end: 8rem !important;
  }

  .margin-bottom-none-tablet {
    margin-block-end: 0 !important;
  }

  .margin-left-xxs-tablet {
    margin-inline-start: .5rem !important;
  }

  .margin-left-xs-tablet {
    margin-inline-start: 1rem !important;
  }

  .margin-left-sm-tablet {
    margin-inline-start: 1.5rem !important;
  }

  .margin-left-md-tablet {
    margin-inline-start: 2rem !important;
  }

  .margin-left-lg-tablet {
    margin-inline-start: 3rem !important;
  }

  .margin-left-xl-tablet {
    margin-inline-start: 4rem !important;
  }

  .margin-left-xxl-tablet {
    margin-inline-start: 6rem !important;
  }

  .margin-left-xxxl-tablet {
    margin-inline-start: 8rem !important;
  }

  .margin-left-none-tablet {
    margin-inline-start: 0 !important;
  }

  .margin-right-xxs-tablet {
    margin-inline-end: .5rem !important;
  }

  .margin-right-xs-tablet {
    margin-inline-end: 1rem !important;
  }

  .margin-right-sm-tablet {
    margin-inline-end: 1.5rem !important;
  }

  .margin-right-md-tablet {
    margin-inline-end: 2rem !important;
  }

  .margin-right-lg-tablet {
    margin-inline-end: 3rem !important;
  }

  .margin-right-xl-tablet {
    margin-inline-end: 4rem !important;
  }

  .margin-right-xxl-tablet {
    margin-inline-end: 6rem !important;
  }

  .margin-right-xxxl-tablet {
    margin-inline-end: 8rem !important;
  }

  .margin-right-none-tablet {
    margin-inline-end: 0 !important;
  }

  .padding-xxs-tablet {
    padding: .5rem !important;
  }

  .padding-xs-tablet {
    padding: 1rem !important;
  }

  .padding-sm-tablet {
    padding: 1.5rem !important;
  }

  .padding-md-tablet {
    padding: 2rem !important;
  }

  .padding-lg-tablet {
    padding: 3rem !important;
  }

  .padding-xl-tablet {
    padding: 4rem !important;
  }

  .padding-xxl-tablet {
    padding: 6rem !important;
  }

  .padding-xxxl-tablet {
    padding: 8rem !important;
  }

  .padding-none-tablet {
    padding: 0 !important;
  }

  .padding-inline-xxs-tablet {
    padding-inline: .5rem !important;
  }

  .padding-inline-xs-tablet {
    padding-inline: 1rem !important;
  }

  .padding-inline-sm-tablet {
    padding-inline: 1.5rem !important;
  }

  .padding-inline-md-tablet {
    padding-inline: 2rem !important;
  }

  .padding-inline-lg-tablet {
    padding-inline: 3rem !important;
  }

  .padding-inline-xl-tablet {
    padding-inline: 4rem !important;
  }

  .padding-inline-xxl-tablet {
    padding-inline: 6rem !important;
  }

  .padding-inline-xxxl-tablet {
    padding-inline: 8rem !important;
  }

  .padding-inline-none-tablet {
    padding-inline: 0 !important;
  }

  .padding-block-xxs-tablet {
    padding-block: .5rem !important;
  }

  .padding-block-xs-tablet {
    padding-block: 1rem !important;
  }

  .padding-block-sm-tablet {
    padding-block: 1.5rem !important;
  }

  .padding-block-md-tablet {
    padding-block: 2rem !important;
  }

  .padding-block-lg-tablet {
    padding-block: 3rem !important;
  }

  .padding-block-xl-tablet {
    padding-block: 4rem !important;
  }

  .padding-block-xxl-tablet {
    padding-block: 6rem !important;
  }

  .padding-block-xxxl-tablet {
    padding-block: 8rem !important;
  }

  .padding-block-none-tablet {
    padding-block: 0 !important;
  }

  .padding-top-xxs-tablet {
    padding-block-start: .5rem !important;
  }

  .padding-top-xs-tablet {
    padding-block-start: 1rem !important;
  }

  .padding-top-sm-tablet {
    padding-block-start: 1.5rem !important;
  }

  .padding-top-md-tablet {
    padding-block-start: 2rem !important;
  }

  .padding-top-lg-tablet {
    padding-block-start: 3rem !important;
  }

  .padding-top-xl-tablet {
    padding-block-start: 4rem !important;
  }

  .padding-top-xxl-tablet {
    padding-block-start: 6rem !important;
  }

  .padding-top-xxxl-tablet {
    padding-block-start: 8rem !important;
  }

  .padding-top-none-tablet {
    padding-block-start: 0 !important;
  }

  .padding-bottom-xxs-tablet {
    padding-block-end: .5rem !important;
  }

  .padding-bottom-xs-tablet {
    padding-block-end: 1rem !important;
  }

  .padding-bottom-sm-tablet {
    padding-block-end: 1.5rem !important;
  }

  .padding-bottom-md-tablet {
    padding-block-end: 2rem !important;
  }

  .padding-bottom-lg-tablet {
    padding-block-end: 3rem !important;
  }

  .padding-bottom-xl-tablet {
    padding-block-end: 4rem !important;
  }

  .padding-bottom-xxl-tablet {
    padding-block-end: 6rem !important;
  }

  .padding-bottom-xxxl-tablet {
    padding-block-end: 8rem !important;
  }

  .padding-bottom-none-tablet {
    padding-block-end: 0 !important;
  }

  .padding-left-xxs-tablet {
    padding-inline-start: .5rem !important;
  }

  .padding-left-xs-tablet {
    padding-inline-start: 1rem !important;
  }

  .padding-left-sm-tablet {
    padding-inline-start: 1.5rem !important;
  }

  .padding-left-md-tablet {
    padding-inline-start: 2rem !important;
  }

  .padding-left-lg-tablet {
    padding-inline-start: 3rem !important;
  }

  .padding-left-xl-tablet {
    padding-inline-start: 4rem !important;
  }

  .padding-left-xxl-tablet {
    padding-inline-start: 6rem !important;
  }

  .padding-left-xxxl-tablet {
    padding-inline-start: 8rem !important;
  }

  .padding-left-none-tablet {
    padding-inline-start: 0 !important;
  }

  .padding-right-xxs-tablet {
    padding-inline-end: .5rem !important;
  }

  .padding-right-xs-tablet {
    padding-inline-end: 1rem !important;
  }

  .padding-right-sm-tablet {
    padding-inline-end: 1.5rem !important;
  }

  .padding-right-md-tablet {
    padding-inline-end: 2rem !important;
  }

  .padding-right-lg-tablet {
    padding-inline-end: 3rem !important;
  }

  .padding-right-xl-tablet {
    padding-inline-end: 4rem !important;
  }

  .padding-right-xxl-tablet {
    padding-inline-end: 6rem !important;
  }

  .padding-right-xxxl-tablet {
    padding-inline-end: 8rem !important;
  }

  .padding-right-none-tablet {
    padding-inline-end: 0 !important;
  }
}

@media screen and (width >= 1088px) {
  .margin-xxs-desktop {
    margin: .5rem !important;
  }

  .margin-xs-desktop {
    margin: 1rem !important;
  }

  .margin-sm-desktop {
    margin: 1.5rem !important;
  }

  .margin-md-desktop {
    margin: 2rem !important;
  }

  .margin-lg-desktop {
    margin: 3rem !important;
  }

  .margin-xl-desktop {
    margin: 4rem !important;
  }

  .margin-xxl-desktop {
    margin: 6rem !important;
  }

  .margin-xxxl-desktop {
    margin: 8rem !important;
  }

  .margin-none-desktop {
    margin: 0 !important;
  }

  .margin-inline-xxs-desktop {
    margin-inline: .5rem !important;
  }

  .margin-inline-xs-desktop {
    margin-inline: 1rem !important;
  }

  .margin-inline-sm-desktop {
    margin-inline: 1.5rem !important;
  }

  .margin-inline-md-desktop {
    margin-inline: 2rem !important;
  }

  .margin-inline-lg-desktop {
    margin-inline: 3rem !important;
  }

  .margin-inline-xl-desktop {
    margin-inline: 4rem !important;
  }

  .margin-inline-xxl-desktop {
    margin-inline: 6rem !important;
  }

  .margin-inline-xxxl-desktop {
    margin-inline: 8rem !important;
  }

  .margin-inline-none-desktop {
    margin-inline: 0 !important;
  }

  .margin-block-xxs-desktop {
    margin-block: .5rem !important;
  }

  .margin-block-xs-desktop {
    margin-block: 1rem !important;
  }

  .margin-block-sm-desktop {
    margin-block: 1.5rem !important;
  }

  .margin-block-md-desktop {
    margin-block: 2rem !important;
  }

  .margin-block-lg-desktop {
    margin-block: 3rem !important;
  }

  .margin-block-xl-desktop {
    margin-block: 4rem !important;
  }

  .margin-block-xxl-desktop {
    margin-block: 6rem !important;
  }

  .margin-block-xxxl-desktop {
    margin-block: 8rem !important;
  }

  .margin-block-none-desktop {
    margin-block: 0 !important;
  }

  .margin-top-xxs-desktop {
    margin-block-start: .5rem !important;
  }

  .margin-top-xs-desktop {
    margin-block-start: 1rem !important;
  }

  .margin-top-sm-desktop {
    margin-block-start: 1.5rem !important;
  }

  .margin-top-md-desktop {
    margin-block-start: 2rem !important;
  }

  .margin-top-lg-desktop {
    margin-block-start: 3rem !important;
  }

  .margin-top-xl-desktop {
    margin-block-start: 4rem !important;
  }

  .margin-top-xxl-desktop {
    margin-block-start: 6rem !important;
  }

  .margin-top-xxxl-desktop {
    margin-block-start: 8rem !important;
  }

  .margin-top-none-desktop {
    margin-block-start: 0 !important;
  }

  .margin-bottom-xxs-desktop {
    margin-block-end: .5rem !important;
  }

  .margin-bottom-xs-desktop {
    margin-block-end: 1rem !important;
  }

  .margin-bottom-sm-desktop {
    margin-block-end: 1.5rem !important;
  }

  .margin-bottom-md-desktop {
    margin-block-end: 2rem !important;
  }

  .margin-bottom-lg-desktop {
    margin-block-end: 3rem !important;
  }

  .margin-bottom-xl-desktop {
    margin-block-end: 4rem !important;
  }

  .margin-bottom-xxl-desktop {
    margin-block-end: 6rem !important;
  }

  .margin-bottom-xxxl-desktop {
    margin-block-end: 8rem !important;
  }

  .margin-bottom-none-desktop {
    margin-block-end: 0 !important;
  }

  .margin-left-xxs-desktop {
    margin-inline-start: .5rem !important;
  }

  .margin-left-xs-desktop {
    margin-inline-start: 1rem !important;
  }

  .margin-left-sm-desktop {
    margin-inline-start: 1.5rem !important;
  }

  .margin-left-md-desktop {
    margin-inline-start: 2rem !important;
  }

  .margin-left-lg-desktop {
    margin-inline-start: 3rem !important;
  }

  .margin-left-xl-desktop {
    margin-inline-start: 4rem !important;
  }

  .margin-left-xxl-desktop {
    margin-inline-start: 6rem !important;
  }

  .margin-left-xxxl-desktop {
    margin-inline-start: 8rem !important;
  }

  .margin-left-none-desktop {
    margin-inline-start: 0 !important;
  }

  .margin-right-xxs-desktop {
    margin-inline-end: .5rem !important;
  }

  .margin-right-xs-desktop {
    margin-inline-end: 1rem !important;
  }

  .margin-right-sm-desktop {
    margin-inline-end: 1.5rem !important;
  }

  .margin-right-md-desktop {
    margin-inline-end: 2rem !important;
  }

  .margin-right-lg-desktop {
    margin-inline-end: 3rem !important;
  }

  .margin-right-xl-desktop {
    margin-inline-end: 4rem !important;
  }

  .margin-right-xxl-desktop {
    margin-inline-end: 6rem !important;
  }

  .margin-right-xxxl-desktop {
    margin-inline-end: 8rem !important;
  }

  .margin-right-none-desktop {
    margin-inline-end: 0 !important;
  }

  .padding-xxs-desktop {
    padding: .5rem !important;
  }

  .padding-xs-desktop {
    padding: 1rem !important;
  }

  .padding-sm-desktop {
    padding: 1.5rem !important;
  }

  .padding-md-desktop {
    padding: 2rem !important;
  }

  .padding-lg-desktop {
    padding: 3rem !important;
  }

  .padding-xl-desktop {
    padding: 4rem !important;
  }

  .padding-xxl-desktop {
    padding: 6rem !important;
  }

  .padding-xxxl-desktop {
    padding: 8rem !important;
  }

  .padding-none-desktop {
    padding: 0 !important;
  }

  .padding-inline-xxs-desktop {
    padding-inline: .5rem !important;
  }

  .padding-inline-xs-desktop {
    padding-inline: 1rem !important;
  }

  .padding-inline-sm-desktop {
    padding-inline: 1.5rem !important;
  }

  .padding-inline-md-desktop {
    padding-inline: 2rem !important;
  }

  .padding-inline-lg-desktop {
    padding-inline: 3rem !important;
  }

  .padding-inline-xl-desktop {
    padding-inline: 4rem !important;
  }

  .padding-inline-xxl-desktop {
    padding-inline: 6rem !important;
  }

  .padding-inline-xxxl-desktop {
    padding-inline: 8rem !important;
  }

  .padding-inline-none-desktop {
    padding-inline: 0 !important;
  }

  .padding-block-xxs-desktop {
    padding-block: .5rem !important;
  }

  .padding-block-xs-desktop {
    padding-block: 1rem !important;
  }

  .padding-block-sm-desktop {
    padding-block: 1.5rem !important;
  }

  .padding-block-md-desktop {
    padding-block: 2rem !important;
  }

  .padding-block-lg-desktop {
    padding-block: 3rem !important;
  }

  .padding-block-xl-desktop {
    padding-block: 4rem !important;
  }

  .padding-block-xxl-desktop {
    padding-block: 6rem !important;
  }

  .padding-block-xxxl-desktop {
    padding-block: 8rem !important;
  }

  .padding-block-none-desktop {
    padding-block: 0 !important;
  }

  .padding-top-xxs-desktop {
    padding-block-start: .5rem !important;
  }

  .padding-top-xs-desktop {
    padding-block-start: 1rem !important;
  }

  .padding-top-sm-desktop {
    padding-block-start: 1.5rem !important;
  }

  .padding-top-md-desktop {
    padding-block-start: 2rem !important;
  }

  .padding-top-lg-desktop {
    padding-block-start: 3rem !important;
  }

  .padding-top-xl-desktop {
    padding-block-start: 4rem !important;
  }

  .padding-top-xxl-desktop {
    padding-block-start: 6rem !important;
  }

  .padding-top-xxxl-desktop {
    padding-block-start: 8rem !important;
  }

  .padding-top-none-desktop {
    padding-block-start: 0 !important;
  }

  .padding-bottom-xxs-desktop {
    padding-block-end: .5rem !important;
  }

  .padding-bottom-xs-desktop {
    padding-block-end: 1rem !important;
  }

  .padding-bottom-sm-desktop {
    padding-block-end: 1.5rem !important;
  }

  .padding-bottom-md-desktop {
    padding-block-end: 2rem !important;
  }

  .padding-bottom-lg-desktop {
    padding-block-end: 3rem !important;
  }

  .padding-bottom-xl-desktop {
    padding-block-end: 4rem !important;
  }

  .padding-bottom-xxl-desktop {
    padding-block-end: 6rem !important;
  }

  .padding-bottom-xxxl-desktop {
    padding-block-end: 8rem !important;
  }

  .padding-bottom-none-desktop {
    padding-block-end: 0 !important;
  }

  .padding-left-xxs-desktop {
    padding-inline-start: .5rem !important;
  }

  .padding-left-xs-desktop {
    padding-inline-start: 1rem !important;
  }

  .padding-left-sm-desktop {
    padding-inline-start: 1.5rem !important;
  }

  .padding-left-md-desktop {
    padding-inline-start: 2rem !important;
  }

  .padding-left-lg-desktop {
    padding-inline-start: 3rem !important;
  }

  .padding-left-xl-desktop {
    padding-inline-start: 4rem !important;
  }

  .padding-left-xxl-desktop {
    padding-inline-start: 6rem !important;
  }

  .padding-left-xxxl-desktop {
    padding-inline-start: 8rem !important;
  }

  .padding-left-none-desktop {
    padding-inline-start: 0 !important;
  }

  .padding-right-xxs-desktop {
    padding-inline-end: .5rem !important;
  }

  .padding-right-xs-desktop {
    padding-inline-end: 1rem !important;
  }

  .padding-right-sm-desktop {
    padding-inline-end: 1.5rem !important;
  }

  .padding-right-md-desktop {
    padding-inline-end: 2rem !important;
  }

  .padding-right-lg-desktop {
    padding-inline-end: 3rem !important;
  }

  .padding-right-xl-desktop {
    padding-inline-end: 4rem !important;
  }

  .padding-right-xxl-desktop {
    padding-inline-end: 6rem !important;
  }

  .padding-right-xxxl-desktop {
    padding-inline-end: 8rem !important;
  }

  .padding-right-none-desktop {
    padding-inline-end: 0 !important;
  }
}

@media screen and (width >= 1800px) {
  .margin-xxs-widescreen {
    margin: .5rem !important;
  }

  .margin-xs-widescreen {
    margin: 1rem !important;
  }

  .margin-sm-widescreen {
    margin: 1.5rem !important;
  }

  .margin-md-widescreen {
    margin: 2rem !important;
  }

  .margin-lg-widescreen {
    margin: 3rem !important;
  }

  .margin-xl-widescreen {
    margin: 4rem !important;
  }

  .margin-xxl-widescreen {
    margin: 6rem !important;
  }

  .margin-xxxl-widescreen {
    margin: 8rem !important;
  }

  .margin-none-widescreen {
    margin: 0 !important;
  }

  .margin-inline-xxs-widescreen {
    margin-inline: .5rem !important;
  }

  .margin-inline-xs-widescreen {
    margin-inline: 1rem !important;
  }

  .margin-inline-sm-widescreen {
    margin-inline: 1.5rem !important;
  }

  .margin-inline-md-widescreen {
    margin-inline: 2rem !important;
  }

  .margin-inline-lg-widescreen {
    margin-inline: 3rem !important;
  }

  .margin-inline-xl-widescreen {
    margin-inline: 4rem !important;
  }

  .margin-inline-xxl-widescreen {
    margin-inline: 6rem !important;
  }

  .margin-inline-xxxl-widescreen {
    margin-inline: 8rem !important;
  }

  .margin-inline-none-widescreen {
    margin-inline: 0 !important;
  }

  .margin-block-xxs-widescreen {
    margin-block: .5rem !important;
  }

  .margin-block-xs-widescreen {
    margin-block: 1rem !important;
  }

  .margin-block-sm-widescreen {
    margin-block: 1.5rem !important;
  }

  .margin-block-md-widescreen {
    margin-block: 2rem !important;
  }

  .margin-block-lg-widescreen {
    margin-block: 3rem !important;
  }

  .margin-block-xl-widescreen {
    margin-block: 4rem !important;
  }

  .margin-block-xxl-widescreen {
    margin-block: 6rem !important;
  }

  .margin-block-xxxl-widescreen {
    margin-block: 8rem !important;
  }

  .margin-block-none-widescreen {
    margin-block: 0 !important;
  }

  .margin-top-xxs-widescreen {
    margin-block-start: .5rem !important;
  }

  .margin-top-xs-widescreen {
    margin-block-start: 1rem !important;
  }

  .margin-top-sm-widescreen {
    margin-block-start: 1.5rem !important;
  }

  .margin-top-md-widescreen {
    margin-block-start: 2rem !important;
  }

  .margin-top-lg-widescreen {
    margin-block-start: 3rem !important;
  }

  .margin-top-xl-widescreen {
    margin-block-start: 4rem !important;
  }

  .margin-top-xxl-widescreen {
    margin-block-start: 6rem !important;
  }

  .margin-top-xxxl-widescreen {
    margin-block-start: 8rem !important;
  }

  .margin-top-none-widescreen {
    margin-block-start: 0 !important;
  }

  .margin-bottom-xxs-widescreen {
    margin-block-end: .5rem !important;
  }

  .margin-bottom-xs-widescreen {
    margin-block-end: 1rem !important;
  }

  .margin-bottom-sm-widescreen {
    margin-block-end: 1.5rem !important;
  }

  .margin-bottom-md-widescreen {
    margin-block-end: 2rem !important;
  }

  .margin-bottom-lg-widescreen {
    margin-block-end: 3rem !important;
  }

  .margin-bottom-xl-widescreen {
    margin-block-end: 4rem !important;
  }

  .margin-bottom-xxl-widescreen {
    margin-block-end: 6rem !important;
  }

  .margin-bottom-xxxl-widescreen {
    margin-block-end: 8rem !important;
  }

  .margin-bottom-none-widescreen {
    margin-block-end: 0 !important;
  }

  .margin-left-xxs-widescreen {
    margin-inline-start: .5rem !important;
  }

  .margin-left-xs-widescreen {
    margin-inline-start: 1rem !important;
  }

  .margin-left-sm-widescreen {
    margin-inline-start: 1.5rem !important;
  }

  .margin-left-md-widescreen {
    margin-inline-start: 2rem !important;
  }

  .margin-left-lg-widescreen {
    margin-inline-start: 3rem !important;
  }

  .margin-left-xl-widescreen {
    margin-inline-start: 4rem !important;
  }

  .margin-left-xxl-widescreen {
    margin-inline-start: 6rem !important;
  }

  .margin-left-xxxl-widescreen {
    margin-inline-start: 8rem !important;
  }

  .margin-left-none-widescreen {
    margin-inline-start: 0 !important;
  }

  .margin-right-xxs-widescreen {
    margin-inline-end: .5rem !important;
  }

  .margin-right-xs-widescreen {
    margin-inline-end: 1rem !important;
  }

  .margin-right-sm-widescreen {
    margin-inline-end: 1.5rem !important;
  }

  .margin-right-md-widescreen {
    margin-inline-end: 2rem !important;
  }

  .margin-right-lg-widescreen {
    margin-inline-end: 3rem !important;
  }

  .margin-right-xl-widescreen {
    margin-inline-end: 4rem !important;
  }

  .margin-right-xxl-widescreen {
    margin-inline-end: 6rem !important;
  }

  .margin-right-xxxl-widescreen {
    margin-inline-end: 8rem !important;
  }

  .margin-right-none-widescreen {
    margin-inline-end: 0 !important;
  }

  .padding-xxs-widescreen {
    padding: .5rem !important;
  }

  .padding-xs-widescreen {
    padding: 1rem !important;
  }

  .padding-sm-widescreen {
    padding: 1.5rem !important;
  }

  .padding-md-widescreen {
    padding: 2rem !important;
  }

  .padding-lg-widescreen {
    padding: 3rem !important;
  }

  .padding-xl-widescreen {
    padding: 4rem !important;
  }

  .padding-xxl-widescreen {
    padding: 6rem !important;
  }

  .padding-xxxl-widescreen {
    padding: 8rem !important;
  }

  .padding-none-widescreen {
    padding: 0 !important;
  }

  .padding-inline-xxs-widescreen {
    padding-inline: .5rem !important;
  }

  .padding-inline-xs-widescreen {
    padding-inline: 1rem !important;
  }

  .padding-inline-sm-widescreen {
    padding-inline: 1.5rem !important;
  }

  .padding-inline-md-widescreen {
    padding-inline: 2rem !important;
  }

  .padding-inline-lg-widescreen {
    padding-inline: 3rem !important;
  }

  .padding-inline-xl-widescreen {
    padding-inline: 4rem !important;
  }

  .padding-inline-xxl-widescreen {
    padding-inline: 6rem !important;
  }

  .padding-inline-xxxl-widescreen {
    padding-inline: 8rem !important;
  }

  .padding-inline-none-widescreen {
    padding-inline: 0 !important;
  }

  .padding-block-xxs-widescreen {
    padding-block: .5rem !important;
  }

  .padding-block-xs-widescreen {
    padding-block: 1rem !important;
  }

  .padding-block-sm-widescreen {
    padding-block: 1.5rem !important;
  }

  .padding-block-md-widescreen {
    padding-block: 2rem !important;
  }

  .padding-block-lg-widescreen {
    padding-block: 3rem !important;
  }

  .padding-block-xl-widescreen {
    padding-block: 4rem !important;
  }

  .padding-block-xxl-widescreen {
    padding-block: 6rem !important;
  }

  .padding-block-xxxl-widescreen {
    padding-block: 8rem !important;
  }

  .padding-block-none-widescreen {
    padding-block: 0 !important;
  }

  .padding-top-xxs-widescreen {
    padding-block-start: .5rem !important;
  }

  .padding-top-xs-widescreen {
    padding-block-start: 1rem !important;
  }

  .padding-top-sm-widescreen {
    padding-block-start: 1.5rem !important;
  }

  .padding-top-md-widescreen {
    padding-block-start: 2rem !important;
  }

  .padding-top-lg-widescreen {
    padding-block-start: 3rem !important;
  }

  .padding-top-xl-widescreen {
    padding-block-start: 4rem !important;
  }

  .padding-top-xxl-widescreen {
    padding-block-start: 6rem !important;
  }

  .padding-top-xxxl-widescreen {
    padding-block-start: 8rem !important;
  }

  .padding-top-none-widescreen {
    padding-block-start: 0 !important;
  }

  .padding-bottom-xxs-widescreen {
    padding-block-end: .5rem !important;
  }

  .padding-bottom-xs-widescreen {
    padding-block-end: 1rem !important;
  }

  .padding-bottom-sm-widescreen {
    padding-block-end: 1.5rem !important;
  }

  .padding-bottom-md-widescreen {
    padding-block-end: 2rem !important;
  }

  .padding-bottom-lg-widescreen {
    padding-block-end: 3rem !important;
  }

  .padding-bottom-xl-widescreen {
    padding-block-end: 4rem !important;
  }

  .padding-bottom-xxl-widescreen {
    padding-block-end: 6rem !important;
  }

  .padding-bottom-xxxl-widescreen {
    padding-block-end: 8rem !important;
  }

  .padding-bottom-none-widescreen {
    padding-block-end: 0 !important;
  }

  .padding-left-xxs-widescreen {
    padding-inline-start: .5rem !important;
  }

  .padding-left-xs-widescreen {
    padding-inline-start: 1rem !important;
  }

  .padding-left-sm-widescreen {
    padding-inline-start: 1.5rem !important;
  }

  .padding-left-md-widescreen {
    padding-inline-start: 2rem !important;
  }

  .padding-left-lg-widescreen {
    padding-inline-start: 3rem !important;
  }

  .padding-left-xl-widescreen {
    padding-inline-start: 4rem !important;
  }

  .padding-left-xxl-widescreen {
    padding-inline-start: 6rem !important;
  }

  .padding-left-xxxl-widescreen {
    padding-inline-start: 8rem !important;
  }

  .padding-left-none-widescreen {
    padding-inline-start: 0 !important;
  }

  .padding-right-xxs-widescreen {
    padding-inline-end: .5rem !important;
  }

  .padding-right-xs-widescreen {
    padding-inline-end: 1rem !important;
  }

  .padding-right-sm-widescreen {
    padding-inline-end: 1.5rem !important;
  }

  .padding-right-md-widescreen {
    padding-inline-end: 2rem !important;
  }

  .padding-right-lg-widescreen {
    padding-inline-end: 3rem !important;
  }

  .padding-right-xl-widescreen {
    padding-inline-end: 4rem !important;
  }

  .padding-right-xxl-widescreen {
    padding-inline-end: 6rem !important;
  }

  .padding-right-xxxl-widescreen {
    padding-inline-end: 8rem !important;
  }

  .padding-right-none-widescreen {
    padding-inline-end: 0 !important;
  }
}

.justify-content-flex-start {
  justify-content: flex-start !important;
}

.justify-content-flex-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-space-around {
  justify-content: space-around !important;
}

.justify-content-space-between {
  justify-content: space-between !important;
}

.justify-content-space-evenly {
  justify-content: space-evenly !important;
}

.align-items-flex-start {
  align-items: flex-start !important;
}

.align-items-flex-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-flex-start {
  align-content: flex-start !important;
}

.align-content-flex-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-space-around {
  align-content: space-around !important;
}

.align-content-space-between {
  align-content: space-between !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-flex-start {
  align-self: flex-start !important;
}

.align-self-flex-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.flex-direction-row {
  flex-direction: row !important;
}

.flex-direction-column {
  flex-direction: column !important;
}

.flex-direction-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-direction-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-wrap-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-wrap {
  flex-wrap: wrap !important;
}

.flex-wrap-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-basis-0 {
  flex-basis: 0 !important;
}

@media screen and (width >= 768px), print {
  .justify-content-flex-start-tablet {
    justify-content: flex-start !important;
  }

  .justify-content-flex-end-tablet {
    justify-content: flex-end !important;
  }

  .justify-content-center-tablet {
    justify-content: center !important;
  }

  .justify-content-space-around-tablet {
    justify-content: space-around !important;
  }

  .justify-content-space-between-tablet {
    justify-content: space-between !important;
  }

  .justify-content-space-evenly-tablet {
    justify-content: space-evenly !important;
  }

  .align-items-flex-start-tablet {
    align-items: flex-start !important;
  }

  .align-items-flex-end-tablet {
    align-items: flex-end !important;
  }

  .align-items-center-tablet {
    align-items: center !important;
  }

  .align-items-baseline-tablet {
    align-items: baseline !important;
  }

  .align-items-stretch-tablet {
    align-items: stretch !important;
  }

  .align-content-flex-start-tablet {
    align-content: flex-start !important;
  }

  .align-content-flex-end-tablet {
    align-content: flex-end !important;
  }

  .align-content-center-tablet {
    align-content: center !important;
  }

  .align-content-space-around-tablet {
    align-content: space-around !important;
  }

  .align-content-space-between-tablet {
    align-content: space-between !important;
  }

  .align-content-stretch-tablet {
    align-content: stretch !important;
  }

  .align-self-flex-start-tablet {
    align-self: flex-start !important;
  }

  .align-self-flex-end-tablet {
    align-self: flex-end !important;
  }

  .align-self-center-tablet {
    align-self: center !important;
  }

  .align-self-baseline-tablet {
    align-self: baseline !important;
  }

  .align-self-stretch-tablet {
    align-self: stretch !important;
  }

  .flex-direction-row-tablet {
    flex-direction: row !important;
  }

  .flex-direction-column-tablet {
    flex-direction: column !important;
  }

  .flex-direction-column-reverse-tablet {
    flex-direction: column-reverse !important;
  }

  .flex-direction-row-reverse-tablet {
    flex-direction: row-reverse !important;
  }

  .flex-wrap-nowrap-tablet {
    flex-wrap: nowrap !important;
  }

  .flex-wrap-wrap-tablet {
    flex-wrap: wrap !important;
  }

  .flex-wrap-wrap-reverse-tablet {
    flex-wrap: wrap-reverse !important;
  }

  .flex-shrink-0-tablet {
    flex-shrink: 0 !important;
  }

  .flex-shrink-1-tablet {
    flex-shrink: 1 !important;
  }

  .flex-grow-0-tablet {
    flex-grow: 0 !important;
  }

  .flex-grow-1-tablet {
    flex-grow: 1 !important;
  }

  .flex-basis-0-tablet {
    flex-basis: 0 !important;
  }
}

.position-fixed {
  position: fixed !important;
}

.position-absolute {
  position: absolute !important;
}

.position-relative {
  position: relative !important;
}

.position-sticky {
  position: sticky !important;
}

@media screen and (width >= 768px), print {
  .position-fixed-tablet {
    position: fixed !important;
  }

  .position-absolute-tablet {
    position: absolute !important;
  }

  .position-relative-tablet {
    position: relative !important;
  }

  .position-sticky-tablet {
    position: sticky !important;
  }
}

.top-0 {
  inset-block-start: 0 !important;
}

.right-0 {
  inset-inline-end: 0 !important;
}

.bottom-0 {
  inset-block-end: 0 !important;
}

.left-0 {
  inset-inline-start: 0 !important;
}

.line-clamp-1 {
  -webkit-line-clamp: 1 !important;
}

.line-clamp-2 {
  -webkit-line-clamp: 2 !important;
}

.line-clamp-3 {
  -webkit-line-clamp: 3 !important;
}

.line-clamp-4 {
  -webkit-line-clamp: 4 !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-position-top {
  object-position: top !important;
}

.list-style-none, .list-style-none > li {
  list-style: none !important;
}

.list-style-none::marker {
  display: none;
}

.list-style-none::-webkit-details-marker {
  display: none;
}

.width-full {
  width: 100% !important;
}

.width-auto {
  width: auto !important;
}

.width-fit-content {
  width: fit-content !important;
}

.width-100 {
  width: 100px !important;
}

.width-150 {
  width: 150px !important;
}

.width-200 {
  width: 200px !important;
}

.width-250 {
  width: 250px !important;
}

.width-300 {
  width: 300px !important;
}

.width-350 {
  width: 350px !important;
}

@media screen and (width >= 768px), print {
  .width-full-tablet {
    width: 100% !important;
  }

  .width-auto-tablet {
    width: auto !important;
  }

  .width-fit-content-tablet {
    width: fit-content !important;
  }

  .width-100-tablet {
    width: 100px !important;
  }

  .width-150-tablet {
    width: 150px !important;
  }

  .width-200-tablet {
    width: 200px !important;
  }

  .width-250-tablet {
    width: 250px !important;
  }

  .width-300-tablet {
    width: 300px !important;
  }

  .width-350-tablet {
    width: 350px !important;
  }

  .width-400-tablet {
    width: 400px !important;
  }

  .width-450-tablet {
    width: 450px !important;
  }

  .width-500-tablet {
    width: 500px !important;
  }

  .width-unset-tablet {
    width: unset !important;
  }
}

@media screen and (width >= 1088px) {
  .width-full-desktop {
    width: 100% !important;
  }

  .width-auto-desktop {
    width: auto !important;
  }

  .width-fit-content-desktop {
    width: fit-content !important;
  }

  .width-100-desktop {
    width: 100px !important;
  }

  .width-150-desktop {
    width: 150px !important;
  }

  .width-200-desktop {
    width: 200px !important;
  }

  .width-250-desktop {
    width: 250px !important;
  }

  .width-300-desktop {
    width: 300px !important;
  }

  .width-350-desktop {
    width: 350px !important;
  }

  .width-400-desktop {
    width: 400px !important;
  }

  .width-450-desktop {
    width: 450px !important;
  }

  .width-500-desktop {
    width: 500px !important;
  }

  .width-unset-desktop {
    width: unset !important;
  }
}

.visually-hidden, ::part(visually-hidden) {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  word-wrap: normal;
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.tag-input-holder {
  border: 1px solid var(--theme-control-border);
  height: 2.25rem;
}

.warning-icon-container {
  margin-inline-end: .5rem;
}

.warning-icon-container svg {
  forced-color-adjust: auto;
  width: 1rem;
  height: auto;
  fill: currentColor !important;
}

.max-height-30vh {
  max-height: 30vh !important;
}

.min-height-30vh {
  min-height: 30vh !important;
}

html {
  font-size: 16px;
}

#header-body {
  padding-block: 1rem;
}

#main {
  background-color: var(--theme-body-background);
  padding-block: 1.5rem;
  grid-template: "breadcrumbs actions"
                 "article article" 1fr
                 / 9fr 1fr;
  grid-area: main;
  gap: 1.5rem;
  padding: 24px;
  display: grid;
}

@media screen and (width >= 1088px) {
  #main {
    grid-template: "breadcrumbs actions"
                   "article article" 1fr
                   / 7fr 3fr;
  }
}

#breadcrumbs-holder {
  align-items: center;
  display: flex;
}

#actions-holder {
  justify-content: flex-end;
  display: flex;
}

.h1-inverse-spacer {
  margin-block-end: -2rem;
}

#article {
  grid-area: article;
}

.example {
  padding: 1rem;
}

.example.full-width {
  padding-inline: 0;
}

[data-is-full-screened="true"] .example.full-width {
  margin-inline: -1.5rem;
}

html.debug .layout-body {
  position: relative;
}

html.debug .layout-body:after {
  background-color: var(--theme-success-base);
  color: var(--theme-success-invert);
  content: ".layout-body";
  border-radius: 1px;
  padding: 4px 8px;
  font-family: monospace;
  font-size: 18px;
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 0;
}

html.debug .layout-body-main {
  position: relative;
}

html.debug .layout-body-main:after {
  background-color: var(--theme-success-base);
  color: var(--theme-success-invert);
  content: ".layout-body-main";
  border-radius: 1px;
  padding: 4px 8px;
  font-family: monospace;
  font-size: 18px;
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
}

html.debug .layout-body-menu {
  position: relative;
}

html.debug .layout-body-menu:after {
  background-color: var(--theme-success-base);
  color: var(--theme-success-invert);
  content: ".layout-body-menu";
  border-radius: 1px;
  padding: 4px 8px;
  font-family: monospace;
  font-size: 18px;
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
}

html.debug .layout-body-aside {
  position: relative;
}

html.debug .layout-body-aside:after {
  background-color: var(--theme-success-base);
  color: var(--theme-success-invert);
  content: ".layout-body-aside";
  border-radius: 1px;
  padding: 4px 8px;
  font-family: monospace;
  font-size: 18px;
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
}

html.debug .layout-body-header {
  position: relative;
}

html.debug .layout-body-header:after {
  background-color: var(--theme-success-base);
  color: var(--theme-success-invert);
  content: ".layout-body-header";
  border-radius: 1px;
  padding: 4px 8px;
  font-family: monospace;
  font-size: 18px;
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
}

html.debug .layout-body-footer {
  position: relative;
}

html.debug .layout-body-footer:after {
  background-color: var(--theme-success-base);
  color: var(--theme-success-invert);
  content: ".layout-body-footer";
  border-radius: 1px;
  padding: 4px 8px;
  font-family: monospace;
  font-size: 18px;
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
}

html.debug .layout-body-hero {
  position: relative;
}

html.debug .layout-body-hero:after {
  background-color: var(--theme-success-base);
  color: var(--theme-success-invert);
  content: ".layout-body-hero";
  border-radius: 1px;
  padding: 4px 8px;
  font-family: monospace;
  font-size: 18px;
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
}

html.debug .layout-body-flyout {
  position: relative;
}

html.debug .layout-body-flyout:after {
  background-color: var(--theme-success-base);
  color: var(--theme-success-invert);
  content: ".layout-body-flyout";
  border-radius: 1px;
  padding: 4px 8px;
  font-family: monospace;
  font-size: 18px;
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
}

.header-spacer {
  border-left: 2px solid var(--theme-text-subtle);
  width: 2px;
  height: 1.5rem;
  margin-left: .5rem;
  margin-right: 1rem;
  display: inline-block;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.code-block {
  position: relative;
  overflow-x: auto;
}

.code-block .code-block-header-filter {
  background: var(--theme-body-background-medium);
  border: none;
  flex-shrink: 1;
  max-width: 12ch;
  margin-right: .5rem;
  padding-block: .125rem;
  padding-inline: .25rem;
  font-size: .75rem;
}

.code-block .code-block-header-filter::placeholder {
  color: var(--theme-text-subtle);
  font-size: .75rem;
}

.code-block .code-block-header {
  border: 1px solid var(--theme-border);
  background-color: var(--theme-code-header);
  color: var(--theme-text);
  border-bottom: 0;
  align-items: center;
  min-height: 2em;
  font-size: .8em;
  display: flex;
}

.code-block .code-block-header .code-block-header-language {
  flex-grow: 1;
  padding: .125em 1em;
}

.code-block .code-block-body pre {
  box-sizing: border-box;
  border: 1px solid var(--theme-border);
  background-color: var(--theme-code-block);
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: auto;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  tab-size: 4;
  hyphens: none;
  -webkit-overflow-scrolling: touch;
  max-width: 100%;
  margin: 0;
  padding: 1em;
  font-size: .875rem;
  line-height: 1.3571;
  overflow: auto;
}

.code-block .code-block-body pre > code {
  border: 0;
  padding: 0;
  line-height: 1.3571;
  display: block;
  position: relative;
}

.code-block .code-block-body pre > code mark {
  color: var(--theme-text);
  min-width: calc(100% + 2em);
  min-height: 18.9px;
  margin: 0 -1em;
  padding: 0 1em;
  display: inline-block;
}

.burger {
  appearance: none;
  outline-color: var(--theme-text);
  cursor: pointer;
  background: none;
  border: none;
  border-radius: .25rem;
  width: 48px;
  height: 48px;
  display: block;
}

.burger[aria-expanded="true"] {
  outline-color: var(--theme-text-invert);
}

.burger:not(.burger-expanded):hover {
  background-color: var(--theme-body-background-medium);
}

.burger .burger-top, .burger .burger-middle, .burger .burger-bottom {
  border-top: 4px solid var(--theme-text);
  border-radius: 4px;
  transition: transform 3s cubic-bezier(.175, .885, .32, 1.175);
  display: block;
}

.burger .burger-middle, .burger .burger-bottom {
  margin-top: 4px;
}

.burger.burger-expanded .burger-top {
  transform: rotate(45deg)translate(5px, 5px);
}

.burger.burger-expanded .burger-middle {
  opacity: 0;
}

.burger.burger-expanded .burger-bottom {
  transform: rotate(135deg)translate(-6px, 7px);
}

.display-none-rtl:dir(rtl), .display-none-ltr:dir(ltr) {
  display: none !important;
}

main img[src^="/atlas-light."] {
  width: 64px;
  height: auto;
  margin-bottom: 18px;
}
/*# sourceMappingURL=index.562adb7f.css.map */

.tag-input-holder {
	border: 1px solid $control-border;
	height: 2.25rem;
}

.warning-icon-container {
	margin-inline-end: $spacer-3;

	svg {
		@include fill-current-color();

		width: 1rem;
		height: auto;
	}
}

$table-cell-vertical-padding: $spacer-5 !default;
$table-cell-horizontal-padding: $spacer-6 !default;
$table-cell-padding-compact: $spacer-3 !default;
$table-cell-padding-loose: $spacer-8 !default;

$table-caption-padding: $spacer-6 !default;
$table-caption-size: $font-size-9 !default;
$table-caption-spacing: $letter-spacing-medium !default;

.table-wrapper {
	overflow-x: auto;
	overflow-y: hidden;
	-webkit-overflow-scrolling: touch;
}

.table {
	width: 100%;
	font-size: $font-size-8;
	border-collapse: collapse;
	table-layout: auto;

	&:not(.table-simple) {
		border: 1px solid $table-border-dark;
	}

	caption {
		padding: $table-caption-padding;
		font-size: $table-caption-size;
		font-weight: $weight-semibold;
		letter-spacing: $table-caption-spacing;
		text-align: $user-right;
		text-transform: uppercase;
	}

	th,
	td {
		display: table-cell;
		word-wrap: break-word;
		padding: $table-cell-vertical-padding $table-cell-horizontal-padding;
		border-block-start: 1px solid $table-border-dark;
		line-height: 1.5;
		vertical-align: top;
	}

	th:not([align]) {
		text-align: $user-left;
	}

	&.table-stacked-mobile {
		@include discouraged-mobile {
			th,
			td {
				display: block;
				width: 100%;
			}

			th:not(:nth-child(1)),
			td:not(:nth-child(1)) {
				padding-block-start: 0;
				border-width: 0;
			}

			thead tr:nth-child(1) th,
			tbody tr:nth-child(1) td {
				border-block-start: none;
			}

			thead tr > th:last-child {
				border-block-end: 1px solid $table-border-dark;
			}
		}
	}

	&.table-borderless {
		border: none;

		th,
		td {
			border: none;
		}
	}

	&.table-complex {
		thead tr,
		tfoot tr {
			background-color: $table-header;
		}

		th,
		td {
			border: 1px solid $table-border-dark;
		}
	}

	&.table-striped {
		tbody tr:nth-of-type(even) {
			background-color: $table-row;
		}
	}

	&.table-sm {
		th,
		td {
			padding: $table-cell-padding-compact;
		}
	}

	&.table-lg {
		th,
		td {
			padding: $table-cell-padding-loose;
		}
	}
}

$radio-circle-size: 1.25em !default;
$radio-dot-size: 0.625em !default;
$radio-shadow-radius: 0.15em !default;
$radio-border-color: $control-border !default;
$radio-border-width: $control-border-width !default;
$radio-dot-checked-color: $primary !default;
$radio-dot-color: $text-subtle !default;
$radio-dot-hover-unchecked-color: $control-border !default;
$radio-timing-function: $input-timing-function !default;
$radio-duration: $input-transition-duration !default;
$radio-animation: boop !default;
$radio-spacing: 0.5em !default;

@mixin radio-dot {
	position: absolute;
	inset: 0;
	width: $radio-dot-size;
	height: $radio-dot-size;
	margin: auto;
	border-radius: $border-radius-rounded;
	content: '';
	overflow: hidden;
}

@mixin radio-dot-checked {
	background: $radio-dot-checked-color;
	box-shadow: inset 0 0 0 $radio-shadow-radius $body-background;
}

.radio {
	display: inline-flex;
	line-height: 1.25;
	cursor: pointer;

	.radio-dot {
		display: inline-block;
		position: relative;
		inset-block-start: 0.0625em;
		flex-shrink: 0;
		width: $radio-circle-size;
		height: $radio-circle-size;
		border: $radio-border-width solid $radio-border-color;
		border-radius: $border-radius-rounded;
		background-color: $body-background;
		color: $radio-dot-color;
		cursor: pointer;
		appearance: none;

		@include forced-colors {
			border-color: CanvasText !important;
			background-color: unset !important;

			&::before {
				@include radio-dot;
			}
		}
	}

	.radio-label-text {
		margin: 0 $radio-spacing;
	}

	/* stylelint-disable selector-max-specificity, selector-no-qualifying-type, max-nesting-depth */

	input {
		&.is-focused {
			@extend %focus;
		}

		@include focus-visible {
			@extend %focus;
		}
	}

	input.is-checked,
	input:checked,
	.radio-dot.is-checked {
		@include radio-dot-checked;

		animation: $radio-animation $radio-duration $radio-timing-function 1;
		border-color: $radio-dot-checked-color;

		@include forced-colors {
			border-color: CanvasText !important;
			background-color: unset !important;
			box-shadow: none !important;

			&::before {
				background-color: CanvasText !important;
			}
		}
	}

	&.is-hovered,
	&:hover {
		.radio-dot:not(:checked):not([disabled]) {
			background: $radio-dot-hover-unchecked-color;
			box-shadow: inset 0 0 0 $radio-shadow-radius $body-background;

			@include forced-colors {
				background-color: unset !important;
				box-shadow: none !important;

				&::before {
					background-color: CanvasText !important;
				}
			}
		}

		.radio-dot.is-checked {
			@include radio-dot-checked;
		}
	}

	input[disabled],
	input[disabled] ~ .radio-label-text {
		opacity: 0.5;
		cursor: not-allowed;
	}

	/* stylelint-enable */
}

// stylelint-disable declaration-block-no-redundant-longhand-properties
@mixin focus() {
	outline-color: inherit;
	outline-offset: $focus-outline-offset;
	outline-style: $focus-style;
	outline-width: $focus-width;
}

%focus {
	@include focus();
}

@mixin focus-visible {
	@if ($focus-visible-use-polyfill) {
		&.focus-visible {
			@content;
		}
	} @else {
		&:focus-visible {
			@content;
		}
	}
}

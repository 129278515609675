// Font family

.font-family-monospace {
	font-family: $monospace-font-stack !important;
	-moz-osx-font-smoothing: auto;
	-webkit-font-smoothing: auto;
}

.font-family-quote {
	font-family: $quote-font-stack !important;
}

// Heading sizes

.font-size-h0 {
	font-size: $font-size-0 !important;
}

.font-size-h1 {
	font-size: $font-size-1 !important;
}

.font-size-h2 {
	font-size: $font-size-2 !important;
}

.font-size-h3 {
	font-size: $font-size-3 !important;
}

.font-size-h4 {
	font-size: $font-size-4 !important;
}

.font-size-h5 {
	font-size: $font-size-5 !important;
}

.font-size-h6 {
	font-size: $font-size-6 !important;
}

@include tablet {
	.font-size-h0-tablet {
		font-size: $font-size-0 !important;
	}

	.font-size-h1-tablet {
		font-size: $font-size-1 !important;
	}

	.font-size-h2-tablet {
		font-size: $font-size-2 !important;
	}

	.font-size-h3-tablet {
		font-size: $font-size-3 !important;
	}

	.font-size-h4-tablet {
		font-size: $font-size-4 !important;
	}

	.font-size-h5-tablet {
		font-size: $font-size-5 !important;
	}

	.font-size-h6-tablet {
		font-size: $font-size-6 !important;
	}
}

// Non-heading sizes

.font-size-xl {
	font-size: $font-size-5 !important;
}

.font-size-lg {
	font-size: $font-size-6 !important;
}

.font-size-md {
	font-size: $font-size-7 !important;
}

.font-size-sm {
	font-size: $font-size-8 !important;
}

.font-size-xs {
	font-size: $font-size-9 !important;
}

// Non-heading sizes

@include tablet {
	.font-size-xl-tablet {
		font-size: $font-size-5 !important;
	}

	.font-size-lg-tablet {
		font-size: $font-size-6 !important;
	}

	.font-size-md-tablet {
		font-size: $font-size-7 !important;
	}

	.font-size-sm-tablet {
		font-size: $font-size-8 !important;
	}

	.font-size-xs-tablet {
		font-size: $font-size-9 !important;
	}
}

// Weight

.font-weight-light {
	font-weight: $weight-light !important;
}

.font-weight-semilight {
	font-weight: $weight-semilight !important;
}

.font-weight-normal {
	font-weight: $weight-normal !important;
}

.font-weight-semibold {
	font-weight: $weight-semibold !important;
}

.font-weight-bold {
	font-weight: $weight-bold !important;
}

// Case

.font-style-italic {
	font-style: italic !important;
}

.text-decoration-underline {
	text-decoration: underline !important;
}

.text-decoration-none {
	text-decoration: none !important;
}

// Letter Spacing

.letter-spacing-wide {
	letter-spacing: $letter-spacing-wide !important;
}

.text-transform-uppercase {
	text-transform: uppercase !important;
}

// Alignment

.text-align-left {
	text-align: start !important;
}

.text-align-center {
	text-align: center !important;
}

.text-align-right {
	text-align: end !important;
}

@include tablet {
	.text-align-left-tablet {
		text-align: start !important;
	}

	.text-align-center-tablet {
		text-align: center !important;
	}

	.text-align-right-tablet {
		text-align: end !important;
	}
}

// Text wrapping behavior

.text-wrap-pretty {
	text-wrap: pretty !important;
}

// Line height

.line-height-normal {
	line-height: $line-height-normal !important;
}

// White space

.white-space-normal {
	white-space: normal !important;
}

.white-space-nowrap {
	white-space: nowrap !important;
}

.white-space-pre {
	white-space: pre !important;
}

.white-space-pre-wrap {
	white-space: pre-wrap !important;
}

.overflow-hidden {
	overflow: hidden !important;
}

.overflow-x-hidden {
	overflow-x: hidden !important;
}

@include tablet {
	.overflow-hidden-tablet {
		overflow: hidden !important;
	}

	.overflow-x-hidden-tablet {
		overflow-x: hidden !important;
	}
}

.scrollbar-gutter-stable {
	scrollbar-gutter: stable !important;
}

.scrollbar-gutter-stable-both-edges {
	scrollbar-gutter: stable both-edges !important;
}

/* stylelint-disable selector-max-specificity, max-nesting-depth */

.buttons {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-start;

	&.buttons-centered {
		justify-content: center;
	}

	&.buttons-right {
		justify-content: flex-end;
	}

	.button {
		margin-block-end: 0.5rem;
		margin-inline-end: 0.5rem;

		&:only-child,
		&.is-fullwidth,
		&.is-full-width {
			margin-inline-end: 0;
		}

		&.is-full-width-mobile,
		&.is-fullwidth-mobile {
			margin-inline-end: 0;

			@include tablet {
				margin-inline-end: 0.5rem;
			}
		}
	}

	&:last-child {
		margin-block-end: -0.5rem;
	}

	&:not(:last-child) {
		margin-block-end: 1rem;
	}

	&.buttons-addons {
		.button {
			&:not(:first-child) {
				border-bottom-#{$user-left}-radius: 0;
				border-top-#{$user-left}-radius: 0;
			}

			&:not(:last-child) {
				border-bottom-#{$user-right}-radius: 0;
				border-top-#{$user-right}-radius: 0;
				margin-inline-end: -1px;
			}

			&:last-child {
				margin-inline-end: 0;
			}

			&:hover,
			&.is-hovered {
				z-index: $zindex-hover;
			}

			&.is-focused,
			&:active,
			&.is-active,
			&.is-selected {
				z-index: $zindex-focus;

				&:hover {
					z-index: $zindex-multi;
				}
			}

			@include focus-visible {
				z-index: $zindex-focus;

				&:hover {
					z-index: $zindex-multi;
				}
			}

			&.is-expanded {
				flex-grow: 1;
			}
		}
	}
}

/* stylelint-enable selector-max-specificity, max-nesting-depth */

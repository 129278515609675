%unselectable {
	/* stylelint-disable */
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	/* stylelint-enable */
	user-select: none;
}

@mixin unselectable {
	@extend %unselectable;
}

// stylelint-disable-next-line keyframes-name-pattern
@keyframes spinAround {
	// make it stop

	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(359deg);
	}
}

@keyframes boop {
	0% {
		transform: scale(1);
	}

	50% {
		transform: scale(1.1);
	}

	100% {
		transform: scale(1);
	}
}

@keyframes slide-up-fade-out {
	100% {
		transform: translateY(-100%);
		opacity: 0;
	}
}

@keyframes fade-out {
	100% {
		opacity: 0;
	}
}

@keyframes slide-background {
	0% {
		background-position: 200% 0;
	}

	100% {
		background-position: -200% 0;
	}
}

.animation-fade {
	animation: fade-out 0.4s ease-in forwards;

	@include prefers-reduced-motion {
		animation-duration: 0s;
	}
}

.animation-slide-up {
	animation: slide-up-fade-out 0.4s ease-in forwards;

	@include prefers-reduced-motion {
		animation-duration: 0s;
	}
}

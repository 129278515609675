.list-style-none {
	list-style: none !important;

	> li {
		list-style: none !important;
	}

	&::marker,
	&::-webkit-details-marker {
		display: none;
	}
}

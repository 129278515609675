@import '@microsoft/atlas-css/src/index.scss';
@import './styles/form.scss';
@import './styles/height.scss';
@import './styles/layout.scss';
@import './styles/overflow.scss';
@import './styles/code-block.scss';
@import './styles/burger.scss';
@import './styles/layout-buttons.scss';
@import './styles/direction-change.scss';

// hack to mark markdown logo on '/' look a tad different, excludes header logo
main img[src^='/atlas-light.'] {
	width: 64px;
	height: auto;
	margin-bottom: 18px;
}

.object-fit-contain {
	object-fit: contain !important;
}

.object-fit-cover {
	object-fit: cover !important;
}

.object-fit-fill {
	object-fit: fill !important;
}

.object-position-top {
	object-position: top !important;
}

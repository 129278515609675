$breadcrumb-font-size: $font-size-8 !default;
$breadcrumb-spacing-horizontal: 0.25em !default;
$breadcrumb-spacing-vertical: 0.375em !default;
$breadcrumb-separator: '\00200B/' !default;

%breadcrumbs-slash {
	display: inline-block;
	color: $text-subtle;
	content: $breadcrumb-separator;
}

.breadcrumbs {
	font-size: $breadcrumb-font-size;

	.breadcrumbs-item {
		display: inline-block;
		padding-block: $breadcrumb-spacing-vertical;

		&:not(.breadcrumbs-item-slashless)::after {
			@extend %breadcrumbs-slash;

			margin-inline: $breadcrumb-spacing-horizontal;
		}
	}

	// stylelint-disable selector-max-specificity

	&.breadcrumbs-initial-slash {
		.breadcrumbs-item:first-child::before,
		.breadcrumbs-item[hidden] + .breadcrumbs-item::before {
			@extend %breadcrumbs-slash;

			margin-inline: 0.0625em $breadcrumb-spacing-horizontal;
		}
	}
	// stylelint-enable
}

// https://bugs.chromium.org/p/chromium/issues/detail?id=589475&q=details%20box-sizing&can=2

details,
details > * {
	box-sizing: border-box !important;
}

:where(html *),
:where(html a),
:where(html li) {
	// include a,li {} to override css reset/normalize; this is causing .focus-visible color issues
	outline-color: inherit;
}

:where(a) {
	color: $hyperlink;
	text-decoration: none;
	cursor: pointer;
	word-wrap: break-word;
}

:where(a:hover) {
	color: $primary-hover;
	text-decoration: underline;
}

:where(a:visited) {
	color: $visited;
}

fieldset {
	min-width: 0; // as a replaced element min-width:0 is required to enable it to work in responsive layouts
	border: none;
}

::target-text {
	background-color: $code-highlight-background;
}

.media {
	display: flex;
	align-items: flex-start;

	+ .media,
	.media {
		border-block-start: 1px solid $border;
		margin-block-start: 1rem;
		padding-block-start: 1rem;
	}
}

.media-left {
	flex: 0 0 auto;
	margin-inline-end: 1rem;
}

.media-content {
	flex: 1 1 auto;
	width: 100%;
	word-break: break-word;
}

/* stylelint-disable max-nesting-depth, selector-max-specificity  */

$field-line-height: 1.5 !default;
$field-error-color: $danger !default;

.field {
	&:not(:last-child) {
		margin-block-end: $spacer-4;
	}

	.field-description {
		margin-block-end: $spacer-3;
		font-size: $font-size-8;
	}

	.field-label {
		@include label;

		margin-block-end: $spacer-3;

		&.field-label-inline {
			display: inline-flex;
			margin-block-end: unset;
			align-self: flex-end;
		}

		@include tablet {
			flex: 1 0 0;
		}

		&.field-label-sm {
			font-size: $font-size-8;
		}

		&.field-label-lg {
			font-size: $font-size-6;
		}
	}

	.field-body {
		> * {
			scroll-margin-top: 3rem;
		}

		&:not(:first-child) {
			margin-block-start: $spacer-3;

			&.field-body-inline {
				display: inline-flex;
				vertical-align: text-bottom;
			}
		}
	}

	.field-error {
		color: $field-error-color;
		font-size: $font-size-8;
		line-height: $field-line-height;
	}
}

.required-indicator::after,
::part(required-indicator)::after {
	color: $danger;
	content: '*';
	vertical-align: top;
}

.box-shadow-none {
	box-shadow: none !important;
}

.box-shadow-light {
	box-shadow: $box-shadow-light !important;
}

.box-shadow-medium {
	box-shadow: $box-shadow-medium !important;
}

.box-shadow-heavy {
	box-shadow: $box-shadow-heavy !important;
}

.box-shadow-extra-heavy {
	box-shadow: $box-shadow-extra-heavy !important;
}

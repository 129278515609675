$input-color: $text !default;
$input-background-color: $body-background !default;
$input-border-color: $control-border !default;
$input-border-bottom-color: $control-border-bottom !default;
$input-placeholder-color: $text-subtle !default;

$input-hover-color: $text !default;
$input-hover-border-color: $default-hover !default;
$input-focus-border-color: $primary !default;

$input-disabled-color: $text-subtle !default;
$input-disabled-background-color: $body-background-medium !default;
$input-disabled-border-color: $table-border-dark !default;

$input-danger-border-color: $danger !default;
$input-success-border-color: $success !default;

$input-focus-box-shadow-size: 0 0.0625rem 0 0 !default;

$input-icon-size: 2.25em !default;
$input-icon-color: $text-subtle !default;

.input {
	@include control;

	width: 100%;
	max-width: 100%;
	border-color: $input-border-color;
	border-block-end-color: $input-border-bottom-color;
	background-color: $input-background-color;
	color: $input-color !important;

	&::placeholder {
		color: $input-placeholder-color;
	}

	&:hover {
		border-color: $input-hover-border-color;
		border-block-end-color: $input-border-bottom-color;
	}

	&[disabled] {
		border-color: $input-disabled-border-color;
		background-color: $input-disabled-background-color;
		color: $input-disabled-color;
	}

	@include focus-visible {
		@extend %focus;

		border-block-end-color: $input-focus-border-color;
		outline-color: transparent;
		outline-offset: 0;
		outline-style: solid;
		box-shadow: $input-focus-box-shadow-size $input-focus-border-color;
	}

	&.input-sm {
		@include control-sm;
	}

	&.input-lg {
		@include control-lg;
	}

	&.input-danger {
		border-color: $input-danger-border-color;

		@include focus-visible() {
			border-color: $input-border-color;
			border-block-end-color: $input-danger-border-color;
			box-shadow: $input-focus-box-shadow-size $input-danger-border-color;
		}
	}

	&.input-success {
		border-color: $input-success-border-color;

		@include focus-visible() {
			border-color: $input-border-color;
			border-block-end-color: $input-success-border-color;
			box-shadow: $input-focus-box-shadow-size $input-success-border-color;
		}
	}

	&.input-icon-right {
		padding-inline-end: $input-icon-size;

		~ .icon {
			inset-inline-start: unset !important;
			inset-inline-end: 0;
		}

		.input[type='date'] {
			position: relative;

			&::-webkit-calendar-picker-indicator {
				position: absolute;
				width: 1.5em;
				background: none;
				color: transparent;
				inset-inline-end: 0;
				z-index: $zindex-dropdown;
			}
		}
	}

	&.input-icon,
	&.input-icon-right {
		padding-inline-start: $input-icon-size;

		&.input-icon-right {
			padding-inline-start: $control-padding-horizontal;
		}

		+ .icon {
			@include fill-current-color();

			position: absolute;
			inset-block-start: 0;
			inset-inline-start: 0;
			width: $input-icon-size;
			height: 100%;
			color: $input-icon-color;
			pointer-events: none;
			z-index: $zindex-multi;
		}

		&.input-sm {
			~ .icon svg {
				width: 0.875em;
				height: 0.875em;
			}
		}

		&.input-lg {
			~ .icon svg {
				width: 1.125em;
				height: 1.125em;
			}
		}
	}
}

@each $item in $positions {
	.position-#{$item} {
		position: #{$item} !important;
	}
}

@include tablet {
	@each $item in $positions {
		.position-#{$item}-tablet {
			position: #{$item} !important;
		}
	}
}

@each $side, $direction in $logical-directions {
	.#{$side}-0 {
		inset-#{$direction}: 0 !important;
	}
}

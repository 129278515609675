$popover-background-color: $body-background !default;
$popover-border: $border-width solid $border !default;
$popover-border-radius: $border-radius !default;
$popover-shadow: $box-shadow-heavy !default;
$popover-width: 224px !default;

.popover {
	display: inline-block;
	position: relative;

	summary {
		list-style: none;

		&::-webkit-details-marker {
			display: none;
		}
	}

	.popover-content {
		position: absolute;
		width: $popover-width;
		margin-block-start: $spacer-3;
		padding: $spacer-5;
		border: $popover-border;
		border-radius: $popover-border-radius;
		background-color: $popover-background-color;
		box-shadow: $popover-shadow;
		z-index: $zindex-popover;
	}

	&.popover-right {
		.popover-content {
			inset-inline-end: 0;
		}
	}

	&.popover-center {
		.popover-content {
			inset-inline-start: -25%;
		}
	}

	&.popover-top {
		.popover-content {
			inset-block-end: 100%;
			margin-block-end: $spacer-3;
		}
	}
}

$auto-spacing-properties: (
	('margin-inline', 'margin-inline'),
	('margin-left', 'margin-inline-start'),
	('margin-right', 'margin-inline-end'),
	('margin-top', 'margin-block-start')
) !default;

$separator: '-';

@each $property in $auto-spacing-properties {
	$classicProp: nth($property, 1);
	$logicalProp: nth($property, 2);

	// .<property>-auto
	.#{$classicProp}#{$separator}auto {
		#{$logicalProp}: auto !important;
	}
}

// .<property>-auto-<screensize>
@each $property in $auto-spacing-properties {
	$classicProp: nth($property, 1);
	$logicalProp: nth($property, 2);

	@include tablet {
		.#{$classicProp}#{$separator}auto#{$separator}tablet {
			#{$logicalProp}: auto !important;
		}
	}
}

@each $property in $auto-spacing-properties {
	$classicProp: nth($property, 1);
	$logicalProp: nth($property, 2);

	@include desktop {
		.#{$classicProp}#{$separator}auto#{$separator}desktop {
			#{$logicalProp}: auto !important;
		}
	}
}

@each $property in $auto-spacing-properties {
	$classicProp: nth($property, 1);
	$logicalProp: nth($property, 2);

	@include widescreen {
		.#{$classicProp}#{$separator}auto#{$separator}widescreen {
			#{$logicalProp}: auto !important;
		}
	}
}

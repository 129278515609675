/*
 * For use on <button> elements to emulate the appearance of standard <a> tags.
*/

.link-button {
	@include transparent-effects;

	display: inline-block;
	padding: 0;
	border: none;
	color: $hyperlink;
	cursor: pointer;

	&:active,
	&.is-active {
		text-decoration: underline !important;
	}

	&.is-focused,
	&:hover,
	&.is-hovered {
		color: $primary-hover;
		text-decoration: underline !important;
	}

	@include focus-visible {
		color: $primary-hover;
		text-decoration: underline !important;
	}

	&:visited {
		color: $visited;
	}
}

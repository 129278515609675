$separator: '-' !default;
$logical-spacing-properties: (
	('margin', 'margin'),
	('margin-inline', 'margin-inline'),
	('margin-block', 'margin-block'),
	('margin-top', 'margin-block-start'),
	('margin-bottom', 'margin-block-end'),
	('margin-left', 'margin-inline-start'),
	('margin-right', 'margin-inline-end'),
	('padding', 'padding'),
	('padding-inline', 'padding-inline'),
	('padding-block', 'padding-block'),
	('padding-top', 'padding-block-start'),
	('padding-bottom', 'padding-block-end'),
	('padding-left', 'padding-inline-start'),
	('padding-right', 'padding-inline-end')
) !default;

$layout-sizes: (
	('xxs', $layout-1),
	('xs', $layout-2),
	('sm', $layout-3),
	('md', $layout-4),
	('lg', $layout-5),
	('xl', $layout-6),
	('xxl', $layout-7),
	('xxxl', $layout-8),
	('none', $layout-0)
);

@function sizeValue($key, $value) {
	@return if($key == 'none', 0, $value);
}

// Pattern: <cssproperty>-<value>-<screen>

@each $property in $logical-spacing-properties {
	$classicProp: nth($property, 1);
	$logicalProp: nth($property, 2);

	@each $size in $layout-sizes {
		$sizeKey: nth($size, 1);
		$sizeValue: nth($size, 2);

		// .<property>-<value>
		.#{$classicProp}#{$separator}#{$sizeKey} {
			#{$logicalProp}: sizeValue($sizeKey, $sizeValue) !important;
		}
	}
}

@include tablet {
	@each $property in $logical-spacing-properties {
		$classicProp: nth($property, 1);
		$logicalProp: nth($property, 2);

		@each $size in $layout-sizes {
			$sizeKey: nth($size, 1);
			$sizeValue: nth($size, 2);

			.#{$classicProp}#{$separator}#{$sizeKey}#{$separator}tablet {
				#{$logicalProp}: $sizeValue !important;
			}
		}
	}
}

@include desktop {
	@each $property in $logical-spacing-properties {
		$classicProp: nth($property, 1);
		$logicalProp: nth($property, 2);

		@each $size in $layout-sizes {
			$sizeKey: nth($size, 1);
			$sizeValue: nth($size, 2);

			.#{$classicProp}#{$separator}#{$sizeKey}#{$separator}desktop {
				#{$logicalProp}: $sizeValue !important;
			}
		}
	}
}

@include widescreen {
	@each $property in $logical-spacing-properties {
		$classicProp: nth($property, 1);
		$logicalProp: nth($property, 2);

		@each $size in $layout-sizes {
			$sizeKey: nth($size, 1);
			$sizeValue: nth($size, 2);

			.#{$classicProp}#{$separator}#{$sizeKey}#{$separator}widescreen {
				#{$logicalProp}: $sizeValue !important;
			}
		}
	}
}

@if ($focus-visible-use-polyfill) {
	.js-focus-visible .has-default-focus *:focus:not(.focus-visible),
	.js-focus-visible .default-focus *:focus:not(.focus-visible) {
		outline: none;
	}

	.focus-visible.has-inner-focus,
	.focus-visible.inner-focus {
		outline-offset: -$focus-width;
	}

	.has-default-focus,
	.default-focus {
		.is-focused,
		.focus-visible {
			@extend %focus;

			&.has-inner-focus,
			&.inner-focus {
				outline-color: currentColor;
			}
		}
	}
} @else {
	.has-default-focus *:focus-visible,
	.default-focus *:focus-visible {
		@extend %focus;

		&.has-inner-focus,
		&.inner-focus {
			outline-color: currentColor;
			outline-offset: -$focus-width;
		}
	}
}

:where(.is-focused) {
	@extend %focus;

	&.has-inner-focus,
	&.inner-focus {
		outline-color: currentColor;
		outline-offset: -$focus-width;
	}
}

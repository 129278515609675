$code-font-size: $font-size-8 !default;
$code-block-spacing: 1em !default;
$code-header-body-line-height: 1.3571 !default;

@mixin code-block {
	pre {
		box-sizing: border-box;
		max-width: 100%;
		margin: 0;
		padding: $code-block-spacing;
		border: 1px solid $border;
		background-color: $code-block;
		font-size: $code-font-size;
		-moz-osx-font-smoothing: auto;
		-webkit-font-smoothing: auto;
		line-height: 1.3571;
		white-space: pre;
		word-spacing: normal;
		overflow: auto;
		word-break: normal;
		word-wrap: normal;
		tab-size: 4;
		hyphens: none;
		-webkit-overflow-scrolling: touch;

		> code {
			display: block;
			position: relative;
			padding: 0;
			border: 0;
			line-height: $code-header-body-line-height;

			mark {
				display: inline-block;
				min-width: calc(100% + #{$code-block-spacing} + #{$code-block-spacing});
				min-height: 18.9px; // Allow for highlighting of blank lines.
				margin: 0 $code-block-spacing * -1;
				padding: 0 $code-block-spacing;
				color: $text;
			}
		}
	}
}
